import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import { viewShopbyCategory } from "../../redux/homeAction";

const ViewShopbycategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home.ViewShopByCategorydetails);

  useEffect(() => {
    if (id) {
      dispatch(viewShopbyCategory(id));
    }
  }, [id]);

  return (
    <>
      <Header />
      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">Shop By category Details</div>

            <div className="userinfodiv">
              <div className="infobox_wrapper">
                <div className="infobox">
                  <h3>Title</h3>
                  <p>{data?.title || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>product name</h3>
                  <p>{`${data?.product_title || "--"}`}</p>
                </div>
                <div className="infobox">
                  <h3>Product image</h3>
                  <img src={data?.product_image} alt="product img" />
                </div>
                <div className="infobox">
                  <h3>product actual price</h3>
                  <p>{data?.product_actual_price || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>product discount price</h3>
                  <p>{data?.product_discount_price || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Product Title</h3>
                  <p>{data?.product_title || "--"}</p>
                </div>

                <div className="infobox w-100 overflow-auto">
                  <h3>description</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data?.description || "--",
                    }}
                  ></p>
                </div>
              </div>

              <Link to={"/shop-and-category"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewShopbycategory;
