import React from "react";
import Header from "../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ViewCouponDetailsList } from "../../redux/homeAction";
import moment from "moment/moment";

const ViewCouponDetails = () => {
  const viewCouponsDetails = useSelector(
    (state) => state.home.viewCouponsDetails
  );

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ViewCouponDetailsList(id));
  }, []);

  return (
    <>
      <Header />

      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">Coupon Catefgory Details</div>

            <div className="userinfodiv">
              {/* <h4>basic information</h4> */}

              <div className="infobox_wrapper">
                <div className="infobox">
                  <h3>Code</h3>
                  <p>{viewCouponsDetails?.code || "--"}</p>
                </div>

                <div className="infobox">
                  <h3>Discount Type</h3>
                  <p>{viewCouponsDetails?.discountType || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Discount Value</h3>
                  <p>{viewCouponsDetails?.discountValue || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Min Purchase</h3>
                  <p>{viewCouponsDetails?.minPurchase || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Max Uses</h3>
                  <p>{viewCouponsDetails?.maxUses || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Valid From</h3>
                  <p>
                    {" "}
                    {viewCouponsDetails?.startDate
                      ? moment(viewCouponsDetails?.startDate).format(
                          "DD MMMM YYYY"
                        )
                      : "--"}
                  </p>
                </div>
                <div className="infobox">
                  <h3>Valid Upto</h3>
                  <p>
                    {" "}
                    {viewCouponsDetails?.endDate
                      ? moment(viewCouponsDetails?.endDate).format(
                          "DD MMMM YYYY"
                        )
                      : "--"}
                  </p>
                </div>
                <div className="infobox">
                  <h3>Usage Count</h3>
                  <p>{viewCouponsDetails?.usageCount || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Created At</h3>
                  <p>
                    {viewCouponsDetails?.createdAt
                      ? moment(viewCouponsDetails?.createdAt).format(
                          "DD MMMM YYYY"
                        )
                      : "--"}
                  </p>
                </div>
                <div className="infobox">
                  <h3>Updated At</h3>
                  <p>
                    {viewCouponsDetails?.updatedAt
                      ? moment(viewCouponsDetails?.updatedAt).format(
                          "DD MMMM YYYY"
                        )
                      : "--"}
                  </p>
                </div>
                <div className="infobox">
                  <h3>Active</h3>
                  <p>
                    {viewCouponsDetails?.isActive === true
                      ? "true"
                      : "false" || "--"}
                  </p>
                </div>
                <div className="infobox w-50">
                  <h3>Description</h3>
                  <p>{viewCouponsDetails?.description || "--"}</p>
                </div>
              </div>

              <Link to={"/coupon"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCouponDetails;
