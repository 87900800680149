import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ViewEmailTemplateDetailsList } from "../../redux/homeAction";

const ViewEmailTemplate = () => {
  const data = useSelector(
    (state) => state.home.viewEmailTemplateSingeldetails
  );
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ViewEmailTemplateDetailsList(id));
  }, []);

  return (
    <>
      <Header />
      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">Email Template Details</div>

            <div className="userinfodiv">
              {/* <h4>basic information</h4> */}

              <div className="infobox_wrapper">
                <div className="infobox">
                  <h3>Form Name</h3>
                  <p>{data?.from_name || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Form Email</h3>
                  <p>{data?.from_email || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Form Category</h3>
                  <p>{data?.email_category || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Form Subject</h3>
                  <p>{data?.email_subject || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Form status</h3>
                  <p>{data?.status || "--"}</p>
                </div>

                <div className="infobox w-50">
                  <h3>Description</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${data?.email_content}`,
                    }}
                  ></p>
                </div>
              </div>

              <Link to={"/email-template"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEmailTemplate;
