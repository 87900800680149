import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/header/Header";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProductsList,
  GetShopByCategory,
  UpdateShopbycategorydetailsdata,
} from "../../redux/homeAction";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const ShopBycategoryUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    selectKey: Date.now(),
    productsList: [],
  });
  const getProductsData = useSelector(
    (state) => state.home.getProductsData?.data
  );
  const getShopByCategoryData = useSelector(
    (state) => state.home.getShopByCategoryData?.data
  );
  const animatedComponents = makeAnimated();

  useEffect(() => {
    dispatch(GetProductsList());
    dispatch(GetShopByCategory());
  }, [id]);

  useEffect(() => {
    if (getShopByCategoryData) {
      for (let i = 0; i < getShopByCategoryData.length; i++) {
        if (getShopByCategoryData[i]._id === id) {
          setValues({
            ...getShopByCategoryData[i],
            productsList: getShopByCategoryData[i]?.product_id?.map((d) => ({
              label: d.product_name,
              value: d._id,
            })),
            selectKey: Date.now(),
          });
        }
      }
    }
  }, [getShopByCategoryData]);

  const optionList = getProductsData?.map((item, key) => ({
    value: item?._id,
    label: item?.product_name,
  }));

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    if (e.target.name === "main_image") {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
        mainImageShow: URL.createObjectURL(e.target.files[0]),
      });
    }
    if (e.target.name === "productImage") {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
        productImageShow: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSelectProduct = (value) => {
    setValues({ ...values, productsList: value });
  };

  const HandelUpdateShopByCategory = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("title", values?.title);
    formData.append("description", values?.description);
    formData.append(
      "product_id",
      JSON.stringify(values?.productsList?.map((item) => item?.value))
    );
    formData.append("product_title", values?.product_title);
    if (values?.main_image) {
      formData.append("main_image", values?.main_image);
    }
    if (values?.productImage) {
      formData.append("product_image", values?.productImage);
    }
    formData.append("product_actual_price", values?.product_actual_price);
    formData.append("product_discount_price", values?.product_discount_price);

    dispatch(UpdateShopbycategorydetailsdata(id, formData, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <>
      <>
        <Header />

        <div className="adduser_main_wrapper">
          <form onSubmit={HandelUpdateShopByCategory}>
            <div className="form_wrapper_adduser">
              <div className="header_from_user">Update Shop By Category</div>
              <div className="">
                <div className="form_div_add_user">
                  <div className="input_fields_wrapper_adduser  w-100">
                    <div className="input_fields_labels_adduser  w-100">
                      <label>Title *</label>
                      <input
                        className="input_2"
                        name="title"
                        type="text"
                        placeholder="Title"
                        onChange={onInputChange}
                        value={values?.title}
                      />
                    </div>
                  </div>
                </div>

                <div className="form_div_add_user mt-3">
                  <div className="input_fields_wrapper_adduser">
                    <div className="input_fields_labels">
                      <label htmlFor="formFileLg">
                        Image{" "}
                        <span className="text-danger">
                          (Only jpeg, png, jpg files allowed){" "}
                        </span>{" "}
                      </label>
                      <input
                        name="main_image"
                        className="form-control form-control"
                        id="formFileLg"
                        type="file"
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="w-25">
                    <img
                      src={
                        values?.mainImageShow
                          ? values?.mainImageShow
                          : values?.image
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="form_div_add_user mb-4">
                <div className="input_fields_labels w-100 mb-5">
                  <label>Description *</label>
                  <ReactQuill
                    style={{ height: "200px" }}
                    modules={modules}
                    theme="snow"
                    onChange={(value) =>
                      setValues({ ...values, description: value })
                    }
                    value={values?.description}
                    placeholder="The description starts here..."
                  />
                </div>
              </div>
              <div className="form_div_add_user">
                <div className="input_fields_labels">
                  <label>Products *</label>
                  <Select
                    key={values?.selectKey}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={values?.productsList}
                    onChange={handleSelectProduct}
                    isMulti
                    options={optionList}
                  />
                </div>
              </div>

              <hr />
              <h4 className="form_basicInfo ">Bundle</h4>
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser ">
                  <div className="input_fields_labels_adduser ">
                    <label>Product Title *</label>
                    <input
                      className="input_2"
                      name="product_title"
                      type="text"
                      placeholder="Product Title "
                      onChange={onInputChange}
                      value={values?.product_title}
                    />
                  </div>

                  <div className="input_fields_labels_adduser ">
                    <label>Product Actual Price *</label>
                    <input
                      className="input_2"
                      name="product_actual_price"
                      type="number"
                      placeholder="Product Actual Price"
                      onChange={onInputChange}
                      value={values?.product_actual_price}
                    />
                  </div>
                </div>
              </div>
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser ">
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Product Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>

                    <input
                      name="productImage"
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      onChange={onInputChange}
                    />
                    <div className="w-25">
                      <img
                        src={
                          values?.productImageShow
                            ? values?.productImageShow
                            : values?.product_image
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="input_fields_labels_adduser ">
                    <label>product discount price *</label>
                    <input
                      className="input_2"
                      name="product_discount_price"
                      type="number"
                      placeholder="Product Actual Price"
                      onChange={onInputChange}
                      value={values?.product_discount_price}
                    />
                  </div>
                </div>
              </div>

              <button className="save_btn mt-3" type="submit">
                save
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </>
    </>
  );
};

export default ShopBycategoryUpdate;
