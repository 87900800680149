import React, { useState } from "react";
import "../../form.css";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/header/Header";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const AddDocCategory = () => {
  const getUsersList = useSelector((state) => state.home.getAllusersListData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [isRadio, setIsRadio] = useState("");
  const [logErrors, setLogErrors] = useState({});

  const [inputList, setInputList] = useState([
    {
      streetAddress: "",
      aptSuitUnit: "",
      city: "",
      state: "",
      postal_code: "",
      address_phone: "",
      delivery_instruction: "",
    },
  ]);

  const onInputChange = (e) => {
    let newValue = e.target.value;
    setValues({ ...values, [e.target.name]: newValue });

    setLogErrors({ ...logErrors, [e.target.name]: false });

    if (e.target.name === "Pnumber") {
      newValue = newValue.replace(/\D/g, "");
    }
  };

  const deleteItem = (index) => {
    let updatedInputList = [...inputList];
    updatedInputList.splice(index, 1);
    setInputList(updatedInputList);
  };

  const addItem = () => {
    setInputList([
      ...inputList,
      {
        streetAddress: "",
        aptSuitUnit: "",
        city: "",
        state: "",
        postal_code: "",
        address_phone: "",
        delivery_instruction: "",
      },
    ]);
  };

  const handleonInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index] = { ...list[index], [name]: value };
    setInputList(list);
  };

  let address1 = inputList?.map((item) => item);

  const handleAddUser = async (e) => {
    e.preventDefault();
    let errorExist = false;
    let errorsObject = {};

    if (
      values?.fName === "" ||
      values?.fName === null ||
      values?.fName === undefined
    ) {
      errorsObject.fName = "Please enter your  first name";
      errorExist = true;
    }
    if (
      values?.lName === "" ||
      values?.lName === null ||
      values?.lName === undefined
    ) {
      errorsObject.lName = "Please enter your  last name";
      errorExist = true;
    }
    if (
      values?.email === "" ||
      values?.email === null ||
      values?.email === undefined
    ) {
      errorsObject.email = "Please enter your  email";
      errorExist = true;
    }
    if (
      values?.password === "" ||
      values?.password === null ||
      values?.password === undefined
    ) {
      errorsObject.password = "Please enter your  password";
      errorExist = true;
    }
    if (
      values?.Pnumber === "" ||
      values?.Pnumber === null ||
      values?.Pnumber === undefined
    ) {
      errorsObject.Pnumber = "Please enter your  Phone number";
      errorExist = true;
    }
    if (
      values?.ProfilePic === "" ||
      values?.ProfilePic === null ||
      values?.ProfilePic === undefined
    ) {
      errorsObject.ProfilePic = "Please enter your  ProfilePic";
      errorExist = true;
    }
    if (
      values?.location === "" ||
      values?.location === null ||
      values?.location === undefined
    ) {
      errorsObject.location = "Please enter your  location";
      errorExist = true;
    }
    if (
      values?.latitude === "" ||
      values?.latitude === null ||
      values?.latitude === undefined
    ) {
      errorsObject.latitude = "Please enter your  latitude";
      errorExist = true;
    }
    if (
      values?.longitutde === "" ||
      values?.longitutde === null ||
      values?.longitutde === undefined
    ) {
      errorsObject.longitutde = "Please enter your  longitutde";
      errorExist = true;
    }
    if (
      values?.status === "" ||
      values?.status === null ||
      values?.status === undefined
    ) {
      errorsObject.status = "Please enter your  status";
      errorExist = true;
    }
    if (errorExist) {
      setLogErrors(errorsObject);
      return false;
    }

    const data = {
      first_name: values.fName,
      last_name: values.lName,
      email: values.email,
      password: values.password,
      phone: values.Pnumber,
      profile_image: values.ProfilePic,
      location: values.location,
      latitude: values.latitude,
      longitude: values.longitutde,
      role: values.role,
      address: [...address1],
      status: values.status,
    };

    //   dispatch(AddUsers(data, navigate));
  };
  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <div className="form_wrapper_adduser">
          <div className="header_from_user">Add Doctor Category</div>
          <div className="">
            <h4 className="form_basicInfo">Basic Information</h4>
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser">
                <div className="input_fields_labels_adduser">
                  <label>Your Name*</label>
                  <input
                    className={` ${
                      logErrors.fName ? "errorinput_2" : "input_2"
                    }`}
                    name="fName"
                    type="text"
                    placeholder={` ${
                      logErrors.fName
                        ? "First Name invalid"
                        : "Enter First Name"
                    }`}
                    onChange={onInputChange}
                    value={values?.fName}
                  />
                </div>
                <div className="input_fields_labels">
                  <label>email*</label>
                  <input
                    name="email"
                    className={` ${
                      logErrors.email ? "errorinput_2" : "input_2"
                    }`}
                    type="email"
                    placeholder={` ${
                      logErrors.email ? "email invalid" : "Enter Email"
                    }`}
                    onChange={onInputChange}
                    value={values?.email}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>Phone number*</label>
                  <input
                    name="Pnumber"
                    className={` ${
                      logErrors.Pnumber ? "errorinput_2" : "input_2"
                    }`}
                    type="text"
                    maxLength="10"
                    placeholder={` ${
                      logErrors.email
                        ? "Phone number invalid"
                        : "enter Phone Number"
                    }`}
                    onChange={onInputChange}
                    value={values?.Pnumber}
                  />
                </div>
                <div className="input_fields_labels">
                  <label>Date of Birth*</label>
                  <input
                    type="datetime-local"
                    onChange={onInputChange}
                    value={values?.datetime}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>Address*</label>
                  <input
                    name="location"
                    type="text"
                    placeholder="Adarsh Nagar Raja Park"
                    onChange={onInputChange}
                    value={values?.location}
                  />
                </div>
                <div className="input_fields_labels">
                  <label>State*</label>
                  <input
                    type="text"
                    placeholder="Rajasthan"
                    name="state"
                    onChange={onInputChange}
                    value={values?.state}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>City*</label>
                  <input
                    name="city"
                    type="text"
                    onChange={onInputChange}
                    value={values?.city}
                    placeholder="0000000000"
                  />
                </div>
                <div className="input_fields_labels">
                  <label>Zip Code*</label>
                  <input
                    name="zip_code"
                    type="number"
                    onChange={onInputChange}
                    value={values?.zip_code}
                    placeholder="302004"
                  />
                </div>
              </div>
              <div className="bunddle_section">
                <h4 className="form_basicInfo">Category</h4>
              </div>{" "}
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>Select Category*</label>
                  <select
                    placeholder="status"
                    name="doctor"
                    onChange={onInputChange}
                    value={values?.doctor}
                  >
                    <option value="">Doctor A Consult</option>
                    <option value="dietitian">dietitian</option>
                    <option value="physician ">physician </option>
                    <option value="neurologist ">neurologist </option>
                  </select>
                </div>
                <div className="input_fields_labels">
                  <label>Select Sub Category*</label>
                  <select
                    name="subcategory"
                    onChange={onInputChange}
                    value={values?.subcategory}
                  >
                    <option value="">Bones & Joints</option>
                    <option value="diet">Diet</option>
                    <option value="physician ">physician </option>
                    <option value="neurologist ">neurologist </option>
                  </select>
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label htmlFor="formFileLg">Uploaded Documents*</label>
                  <input
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                    multiple
                    name="uploaded_documents"
                    // onChange={onInputChange}
                    // value={values?.uploaded_documents}
                  />
                </div>
                <div className="input_fields_labels">
                  <label htmlFor="formFileLg">Upload New Documents*</label>
                  <input
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                    name="uploaded_documents"
                    // onChange={onInputChange}
                    // value={values?.uploaded_documents}
                  />
                </div>
              </div>
            </div>

            <button className="add_userBtn" onClick={handleAddUser}>
              Add
            </button>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default AddDocCategory;
