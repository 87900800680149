import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import "../../form/form.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  CreateProducts,
  GetAttribtuteListData,
  GetAttribtuteValueListData,
  GetProductsList,
  GettagsList,
  UpdateProduct,
  getProductCategory,
} from "../../redux/homeAction";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import DynamicFields from "../createForms/addproduct/DynamicFields";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer } from "react-toastify";
import Header from "../../components/header/Header";
import UpdateDynamicField from "./updateDynamicFields/UpdateDynamicField";
import { MdDelete } from "react-icons/md";

const ProductUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectKey, setSelectKey] = useState(Date.now());
  const [description, setDescription] = useState({});
  const [variants, setVariants] = useState(null);
  const [variantList, setVariantList] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAttriOption, setSelectedAttriOption] = useState(null);
  const [selectedAttriValOption, setSelectedAttriValOption] = useState([]);
  const [productImage, setProductImage] = useState({});
  const [inputList, setInputList] = useState([
    {
      attributeValue: "",
      varient: "",
    },
  ]);
  const [values, setValues] = useState({});
  const getProductsData = useSelector((state) => state.home.getProductsData);

  const getattributelist = useSelector((state) => state.home.getattributelist);
  const getProductCategorydata = useSelector(
    (state) => state.home.getProductCategorydata
  );
  const getattributeValueList = useSelector(
    (state) => state.home.getattributeValueList
  );
  const getegsDetails = useSelector((state) => state.home.getegsDetails);

  useEffect(() => {
    if (getProductsData) {
      for (let i = 0; i < getProductsData.length; i++) {
        if (getProductsData[i]._id === id) {
          setValues(getProductsData[i]);
          setDescription(getProductsData[i]);
          setVariantList(getProductsData[i].object);
          setSelectKey(Date.now());
        }
      }
    }
  }, [getProductsData]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...variantList];
    list[index][name] = value;
    setVariantList(list);
  };

  const deleteVariantItem = (index) => {
    let updatedVariantList = [...variantList];
    updatedVariantList.splice(index, 1);
    setVariantList(updatedVariantList);
  };

  const visibleSet = (e) => {
    e.preventDefault();
    setVisible(!visible);
  };

  const handleImageChange = (e) => {
    const fname = e.target.name;

    let fieldName;
    let fileName;
    let file;
    if (fname === "product_image") {
      file = e.target.files[0];
      fieldName = e.target.name;
      fileName = `${fieldName}.${file.name.split(".").pop()}`;
    } else if (fname === "gellery_image") {
      file = e.target.files;
      fieldName = e.target.name;
      const galleryImages = Array.from(file).map((image, index) => ({
        file: image,
        name: `${fieldName}.${image.name.split(".").pop()}`,
      }));

      setProductImage((prevImages) => ({
        ...prevImages,
        [fieldName]: galleryImages,
      }));
      return;
    }

    setProductImage((prevImages) => ({
      ...prevImages,
      [fieldName]: {
        file,
        name: fileName,
      },
    }));
  };

  const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return { ...styles, color: data.color };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
        color: "white",
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: "white",
        backgroundColor: "black",
      };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "white",
        cursor: "pointer",
        backgroundColor: "black",
        ":hover": {
          color: "red",
          backgroundColor: "#eb7d7d",
        },
      };
    },
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOption(selectedOptions);
  };

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(GettagsList());
    dispatch(GetProductsList());
  }, []);

  const handleUpdateProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("product_name", values?.product_name);

    let tagsArray = selectedOption?.map((item) => item?.value);

    formData.append("tags", JSON.stringify(tagsArray));

    const attribute = selectedAttriOption?.map((_) => _.label);
    const attributeString = JSON.stringify(attribute);
    formData.append("attribute", attributeString);

    const categoryIdParts = values?.category_id.split(" ");

    if (categoryIdParts && categoryIdParts.length >= 2) {
      const categoryId = categoryIdParts[0];
      const categoryName = values?.category_id.substring(categoryId.length + 1);

      formData.append("category_id", JSON.stringify(categoryId));
      formData.append("category_name", JSON.stringify(categoryName));
    }
    formData.append("sku_code", values?.sku_code);
    formData.append("market_price", values?.market_price);
    formData.append("selling_price", values?.selling_price);
    formData.append("product_description", description.product_description);
    formData.append("product_type", values?.product_type);

    const flattenArray =
      variants &&
      variants?.map((nestedObject) => {
        const { ...rest } = nestedObject;
        return {
          ...rest,
        };
      });
    const flattenArrayImage =
      flattenArray && flattenArray.map((_) => _.variant_image);

    flattenArrayImage &&
      flattenArrayImage.forEach((image, index) => {
        formData.append("files", image);
      });
    formData.append("object", JSON.stringify(flattenArray));
    Object.keys(productImage).forEach((fieldName) => {
      const fileObject = productImage[fieldName];
      if (Array.isArray(fileObject)) {
        fileObject.forEach((image) => {
          formData.append("files", image.file, image.name);
        });
      } else {
        formData.append("files", fileObject.file, fileObject.name);
      }
    });

    const rawData = {};
    formData.forEach((value, key) => {
      // Check if the value is a File object
      if (value instanceof File) {
        rawData[key] = { file: value, name: value.name };
      } else {
        rawData[key] = value;
      }
    });

    dispatch(UpdateProduct(id, rawData, navigate));
  };

  useEffect(() => {
    dispatch(getProductCategory());
    dispatch(GetAttribtuteListData());
    dispatch(GetAttribtuteValueListData());
  }, []);

  const animatedComponents = makeAnimated();

  const optionList = getegsDetails?.map((item, key) => ({
    value: item?._id,
    label: item?.title,
  }));

  const defaultTagOptions = values?.tags?.map((item, key) => ({
    value: item?._id,
    label: item?.title,
  }));

  const optionAttriList = getattributelist?.map((item, key) => ({
    value: item?._id,
    label: item?.title,
  }));

  const optionAttriValList = getattributeValueList?.map((item, key) => ({
    value: item?._id,
    label: item?.atributte_value,
    name: item?.atributte_id?.title,
  }));

  const handleSelectAttribute = (value) => {
    const uniqueValuesSet = new Set(
      selectedAttriValOption.map((ele) => ele.value)
    );

    selectedAttriValOption.forEach((item) => {
      if (!value.find((v) => v.value === item.value.value)) {
        if (value.find((v) => v.name === item.value.name)) {
          uniqueValuesSet.delete(item.value);
        }
      }
    });

    value.forEach((item) => {
      if (!uniqueValuesSet.has(item.value)) {
        uniqueValuesSet.add(item);
      }
    });

    const uniqueValuesArray = Array.from(uniqueValuesSet).map((value) => ({
      value,
    }));

    setSelectedAttriValOption(uniqueValuesArray);
  };

  const totals = selectedAttriValOption.map((_) => _.value);

  const groupedItems = totals.reduce((acc, item) => {
    acc[item.name] = acc[item.name] || [];
    acc[item.name].push(item);
    return acc;
  }, {});

  function generateCombinations(
    data,
    currentIndex,
    currentCombination,
    result
  ) {
    if (currentIndex === Object.keys(data).length) {
      result.push(Object.assign({}, currentCombination));
      return;
    }

    const currentKey = Object.keys(data)[currentIndex];
    const currentArray = data[currentKey];

    for (let i = 0; i < currentArray.length; i++) {
      currentCombination[currentKey] = currentArray[i];
      generateCombinations(data, currentIndex + 1, currentCombination, result);
    }
  }

  const result = [];
  generateCombinations(groupedItems, 0, {}, result);

  const handleUpdateVariants = (updatedVariants) => {
    setVariants(updatedVariants);
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const list = [
    {
      color: {
        value: "656073eb3cca077516c56253",
        label: "black",
        name: "color",
      },
      id: "0454ad8a-180c-4dc6-abbe-97d83f38f168",
      image:
        "C:\\Users\\EOXYS\\Desktop\\eCommerce bioveda\\uploads\\1703854720234-83631774.png_655df76846f3087918f43f28",
      varient_maximum_quantity: "4",
      varient_minimum_quantity: "20",
      varient_price: "1",
      weight: {
        value: "655df76846f3087918f43f28",
        label: "10kg",
        name: "weight",
      },
    },
    {
      color: { value: "656073f73cca077516c56258", label: "red", name: "color" },
      id: "fe8c9a7d-2cc3-4cdf-b9f6-1f9f50347060",
      image:
        "C:\\Users\\EOXYS\\Desktop\\eCommerce bioveda\\uploads\\1703854720234-83631774.png_655df76846f3087918f43f28",
      varient_maximum_quantity: "10",
      varient_minimum_quantity: "10",
      varient_price: "1",
      weight: {
        value: "655df76846f3087918f43f28",
        label: "10kg",
        name: "weight",
      },
    },
    {
      color: {
        value: "656073eb3cca077516c56253",
        label: "black",
        name: "color",
      },
      id: "4e0fbb21-668f-447a-8d3e-d4e88287c169",
      image:
        "C:\\Users\\EOXYS\\Desktop\\eCommerce bioveda\\uploads\\1703854720234-83631774.png_6572b5b72e82974c7ca241c8",
      varient_maximum_quantity: "11",
      varient_minimum_quantity: "1",
      varient_price: "12",
      weight: {
        value: "6572b5b72e82974c7ca241c8",
        label: "1kg",
        name: "weight",
      },
    },
    {
      color: { value: "656073f73cca077516c56258", label: "red", name: "color" },
      id: "4e40c05d-6da6-4949-9d8f-7b1a72b429a1",
      image:
        "C:\\Users\\EOXYS\\Desktop\\eCommerce bioveda\\uploads\\1703854720234-83631774.png_6572b5b72e82974c7ca241c8",
      varient_maximum_quantity: "20",
      varient_minimum_quantity: "10",
      varient_price: "10",
      weight: {
        value: "6572b5b72e82974c7ca241c8",
        label: "1kg",
        name: "weight",
      },
    },
  ];

  return (
    <>
      <Header />
      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handleUpdateProduct}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Update Product</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4>  */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels_adduser">
                    <label>Product Name *</label>
                    <input
                      name="product_name"
                      type="text"
                      placeholder="Product Name"
                      onChange={onInputChange}
                      value={values?.product_name}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>SKU *</label>
                    <input
                      name="sku_code"
                      type="text"
                      placeholder="SKU"
                      onChange={onInputChange}
                      value={values?.sku_code}
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper">
                  <div className="input_fields_labels">
                    <label>Category *</label>
                    <select
                      // className={` ${logErrors.Day ? "errorinput_2" : "input_2"}`}
                      placeholder="discount type"
                      name="category_id"
                      onChange={onInputChange}
                      value={values?.category_id}
                    >
                      {getProductCategorydata?.map((item, key) => (
                        <option
                          key={key}
                          value={`${item?._id} ${item?.category_name}`}
                        >
                          {item?.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="input_fields_wrapper">
                  <div className="input_fields_labels_adduser  ">
                    <label>Tags*</label>

                    <Select
                      key={selectKey}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={defaultTagOptions}
                      onChange={handleSelectChange}
                      isMulti
                      options={optionList}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>product type*</label>
                    <select
                      name="product_type"
                      onChange={onInputChange}
                      value={values?.product_type}
                    >
                      <option value="" hidden>
                        product type
                      </option>
                      <option value="Simple">Simple</option>
                      <option value="Variable">Variable</option>
                    </select>
                  </div>
                </div>

                {values?.product_type === "Simple" ? (
                  <div>
                    <div className="input_fields_wrapper">
                      <div className="input_fields_labels">
                        <label>Market Price *</label>
                        <input
                          name="market_price"
                          type="number"
                          placeholder="market Price "
                          onChange={onInputChange}
                          value={values?.market_price}
                        />
                      </div>
                      <div className="input_fields_labels">
                        <label>Selling Price *</label>
                        <input
                          name="selling_price"
                          type="number"
                          placeholder="reguler Price "
                          onChange={onInputChange}
                          value={values?.selling_price}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="input_fields_wrapper flex-wrap">
                      <div className="input_fields_labels_adduser  ">
                        <label>Attribute *</label>

                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={selectedAttriOption}
                          onChange={setSelectedAttriOption}
                          isMulti
                          options={optionAttriList}
                        />
                      </div>
                    </div>

                    <div className="input_fields_wrapper  align-items-center">
                      <div className="input_fields_labels_adduser  ">
                        {selectedAttriOption?.map((item, idx) => {
                          const filteredOptions = optionAttriValList?.filter(
                            (items) => {
                              const itemLabel = items.name
                                ? items.name.toLowerCase()
                                : "";
                              const innerItemLabel = item.label
                                ? item.label.toLowerCase()
                                : "";
                              return itemLabel === innerItemLabel;
                            }
                          );
                          return (
                            <>
                              <label htmlFor={`attributeValue`}></label>
                              <Select
                                name="attributeValue"
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                defaultValue={selectedAttriValOption}
                                isMulti
                                options={filteredOptions}
                                styles={colorStyles}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>

                    <button
                      onClick={visibleSet}
                      style={{
                        width: "200px",
                        background: " #34b1aa",
                        fontSize: "0.812rem",
                        borderRadius: "0.3rem",
                        fontWeight: "300",
                        border: "none",
                      }}
                      className="btn btn-success btn-sm"
                    >
                      {visible ? "Hide Make Variations" : "Make Variations"}
                    </button>

                    {values?.object && (
                      <UpdateDynamicField
                        variantList={variantList}
                        handleInputChange={handleInputChange}
                        deleteVariantItem={deleteVariantItem}
                      />
                    )}

                    {/* <div>
                      <div className="mt-5">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th></th>
                              <th>SKU</th>
                              <th>Price</th>
                              <th>Min Quantity</th>
                              <th>Max Quantity</th>
                              <th>Image</th>
                              <th>Stock Amount</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr id="variant_row_1">
                              <td>
                                <b> </b>:{" "}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="variants[0][variant_sku]"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="variants[0][variant_price]"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="variants[0][variant_min_qty]"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="variants[0][variant_max_qty]"
                                />
                              </td>
                              <td>
                                <input
                                  type="file"
                                  class="form-control"
                                  name="variant_images[]"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="variants[0][variant_stock_amount]"
                                />
                              </td>
                              <td>
                                <select
                                  class="form-control"
                                  name="variants[0][variant_status]"
                                >
                                  <option value="1">In Stock</option>
                                  <option value="0">Out of stock</option>
                                </select>
                                <input
                                  type="hidden"
                                  name="variants[0][variant_value]"
                                  value='{"Colour":"Foldable Watercolor Paint Set (42 Assorted Colors)","Size":"1 mm Fine, 1-7 mm Broad"}'
                                />
                              </td>
                              <input
                                type="hidden"
                                name="variants[0][variant_id]"
                                value='{"1":14,"2":17}'
                              />
                              <td>
                                <button
                                  class="btn btn-sm btn-icon delete_variant"
                                  data-row="1"
                                >
                                  <MdDelete />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                  </>
                )}
                <div className="mt-5 input_fields_wrapper_description">
                  <div className="input_fields_labels_desription">
                    <label>Description*</label>
                    <ReactQuill
                      name="product_description"
                      style={{ height: "200px" }}
                      modules={modules}
                      theme="snow"
                      value={description?.product_description}
                      onChange={(value) =>
                        setDescription({
                          ...description,
                          product_description: value,
                        })
                      }
                      placeholder="The content starts here..."
                    />
                  </div>
                </div>
              </div>

              <div className="form_div_add_user mt-5">
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>
                    <input
                      accept="image/*"
                      name="product_image"
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      onChange={handleImageChange}
                    />

                    <img className="mt-3" src="" alt="product images" />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Gellery Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>
                    <input
                      accept="image/*"
                      name="gellery_image"
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      onChange={handleImageChange}
                      multiple
                    />

                    <img className="mt-3" src="" alt="gallery images" />
                  </div>
                </div>
              </div>
              <button className="add_userBtn" type="submit">
                Add Product
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProductUpdate;
