import React, { useEffect } from "react";

import "../../pages/css/User.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header";
import { getSingleProductCategoryDetails } from "../../redux/homeAction";
// import { getSingleUser } from "../redux/homeAction";

const ViewSingelProductCategory = () => {
  const productCategoryInfo = useSelector(
    (state) => state.home.getsingeleProductCategorydataList
  );
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleProductCategoryDetails(id));
  }, []);

  return (
    <>
      <Header />

      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">Product Category Details</div>

            <div className="userinfodiv">
              {/* <h4>basic information</h4> */}

              <div className="infobox_wrapper">
                <div className="infobox w-100">
                  <h3>Titel</h3>
                  <h6>{productCategoryInfo?.category_name || "--"}</h6>
                </div>

                <div className="infobox w-100">
                  <h3>Description</h3>
                  <h6>{productCategoryInfo?.category_description || "--"}</h6>
                </div>
                <div className="infobox">
                  <h3>Image</h3>
                  <img
                    src={productCategoryInfo?.category_image}
                    alt="category img"
                  />
                </div>
              </div>

              <Link to={"/products/product-categories"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSingelProductCategory;
