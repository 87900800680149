import React from "react";
import "./header.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SET_LOGOUT } from "../../redux/types";
import { onLogout } from "../../redux/homeAction";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch({ type: SET_LOGOUT });
    localStorage.clear();
    navigate("/");
    dispatch(onLogout(navigate));
  };

  return (
    <div className="main_header_bar">
      <div className="header_container " style={{ paddingRight: "90px" }}>
        <div className="d-flex gap-2">
          <h5>Admin</h5>

          <div className="dropdown">
            <div
              className="btn btn-secondary dropdown-toggle p-0 m-0 img_box"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              style={{
                background: "transparent",
                border: "none",

                fontSize: "0.812rem",
              }}
              aria-expanded="false"
            >
              <img style={{ width: "20px" }} src="/assets/avtar.jpg" alt="" />
            </div>
            <div
              className="dropdown-menu"
              style={{ left: "-55px", textAlign: "center", cursor: "pointer" }}
              aria-labelledby="dropdownMenuButton"
            >
              <Link to={"#"} className="dropdown-item">
                Profile
              </Link>
              <div onClick={handleLogout} className="dropdown-item">
                Logout
              </div>
            </div>
          </div>
        </div>

        {/* <div className="img_box">
          <img src="/assets/avtar.jpg" alt="" />

          <div
            className="d-flex align-items-center"
            style={{
              height: "400px",
              background: "black",
              color: "white",
            }}
          >
            <div>
              <div>profile</div>
              <div>sign out</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
