import React, { useEffect, useState } from "react";
import "../../form.css";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUsers,
  getSubCategoryData,
  getUserCategory,
} from "../../../redux/homeAction";
import Header from "../../../components/header/Header";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AddUser = () => {
  const getUsersList = useSelector((state) => state.home.getAllusersListData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    location: "",
    latitude: "",
    longitude: "",
    role: "",
    status: "",
    category: "",
    sub_category: "",
  });
  const [isRadio, setIsRadio] = useState("");
  const [logErrors, setLogErrors] = useState({});
  const [productImage, setProductImage] = useState({});
  const [inputList, setInputList] = useState([
    {
      street_address: "",
      apt_suite_unit: "",
      city: "",
      state: "",
      postal_code: "",
      address_phone: "",
      delivery_instruction: "",
    },
  ]);

  const [subcategoryList, setSubCategoryList] = useState({});
  const getAllUserCategoryDetailsdata = useSelector(
    (state) => state.home.getAllUserCategoryDetailsdata?.data
  );
  const getSubCategorydata = useSelector(
    (state) => state.home.getSubCategorydata?.data
  );

  useEffect(() => {
    if (getSubCategorydata) {
      setSubCategoryList(
        getSubCategorydata?.filter(
          (item) => item?.user_category_id?._id === values?.category
        )
      );
    }
  }, [getSubCategorydata, values?.category]);

  const onInputChange = (e) => {
    let newValue = e.target.value;
    setValues({ ...values, [e.target.name]: newValue });

    setLogErrors({ ...logErrors, [e.target.name]: false });

    if (e.target.name === "phone") {
      newValue = newValue?.replace(/\D/g, "");
    }

    if (newValue === "Admin" || newValue === "Customer") {
      setValues({
        ...values,
        [e.target.name]: newValue,
        category: "",
        sub_category: "",
      });
    }
  };

  const deleteItem = (index) => {
    let updatedInputList = [...inputList];
    updatedInputList?.splice(index, 1);
    setInputList(updatedInputList);
  };
  const addItem = () => {
    setInputList([
      ...inputList,
      {
        street_address: "",
        apt_suite_unit: "",
        city: "",
        state: "",
        postal_code: "",
        address_phone: "",
        delivery_instruction: "",
      },
    ]);
  };

  const handleonInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index] = { ...list[index], [name]: value };
    setInputList(list);
  };

  let address1 = inputList?.map((item) => item);

  const handleImageChange = (e) => {
    const fname = e.target.name;

    let fieldName;
    let fileName;
    let file;
    if (fname === "profile_pic") {
      file = e.target.files[0];
      fieldName = e.target.name;
      fileName = `${fieldName}.${file.name.split(".").pop()}`;
    } else if (fname === "uploaded_new_documents") {
      file = e.target.files;
      fieldName = e.target.name;
      const galleryImages = Array.from(file).map((image, index) => ({
        file: image,
        name: `${fieldName}.${image.name.split(".").pop()}`,
      }));

      setProductImage((prevImages) => ({
        ...prevImages,
        [fieldName]: galleryImages,
      }));
      return;
    }

    setProductImage((prevImages) => ({
      ...prevImages,
      [fieldName]: {
        file,
        name: fileName,
      },
    }));
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(productImage).forEach((fieldName) => {
      const fileObject = productImage[fieldName];
      if (Array.isArray(fileObject)) {
        fileObject.forEach((image) => {
          formData.append("files", image.file, image.name);
        });
      } else {
        formData.append("files", fileObject.file, fileObject.name);
      }
    });

    formData.append("last_name", values?.last_name);
    formData.append("email", values?.email);
    formData.append("password", values?.password);
    formData.append("phone", values?.phone);
    formData.append("location", values?.location);
    formData.append("latitude", values?.latitude);
    formData.append("longitude", values?.longitude);
    formData.append("role", values?.role);
    formData.append("status", values?.status);
    formData.append("verify", values?.verify);
    formData.append("category", values?.category);
    formData.append("sub_category", values?.sub_category);
    formData.append("address", JSON.stringify([...address1]));
    formData.append("first_name", values?.first_name);

    dispatch(AddUsers(formData, navigate));
  };

  useEffect(() => {
    dispatch(getUserCategory());
    dispatch(getSubCategoryData());
  }, []);

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <div className="form_wrapper_adduser">
          <div className="header_from_user">Add User</div>
          <div className="">
            <h4 className="form_basicInfo">Basic Information</h4>
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser">
                <div className="input_fields_labels_adduser">
                  <label htmlFor="">First Name*</label>
                  <input
                    className={` ${
                      logErrors.first_name ? "errorinput_2" : "input_2"
                    }`}
                    name="first_name"
                    type="text"
                    placeholder={` ${
                      logErrors.first_name
                        ? "First Name invalid"
                        : "Enter First Name"
                    }`}
                    onChange={onInputChange}
                    value={values?.first_name}
                  />
                </div>
                <div className="input_fields_labels">
                  <label htmlFor="">Last Name*</label>
                  <input
                    name="last_name"
                    className={` ${
                      logErrors.last_name ? "errorinput_2" : "input_2"
                    }`}
                    type="text"
                    placeholder={` ${
                      logErrors.last_name
                        ? "Last Name invalid"
                        : "Enter Last Name"
                    }`}
                    onChange={onInputChange}
                    value={values?.last_name}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label htmlFor="">email*</label>
                  <input
                    name="email"
                    className={` ${
                      logErrors.email ? "errorinput_2" : "input_2"
                    }`}
                    type="email"
                    placeholder={` ${
                      logErrors.email ? "email invalid" : "Enter Email"
                    }`}
                    onChange={onInputChange}
                    value={values?.email}
                  />
                </div>
                <div className="input_fields_labels">
                  <label htmlFor="">Password*</label>
                  <input
                    name="password"
                    className={` ${
                      logErrors.password ? "errorinput_2" : "input_2"
                    }`}
                    type="password"
                    placeholder={` ${
                      logErrors.email ? "password invalid" : "*******"
                    }`}
                    onChange={onInputChange}
                    value={values?.password}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label htmlFor="">Phone*</label>
                  <input
                    name="phone"
                    className={` ${
                      logErrors.phone ? "errorinput_2" : "input_2"
                    }`}
                    type="text"
                    maxLength="10"
                    placeholder={` ${
                      logErrors.email
                        ? "Phone number invalid"
                        : "enter Phone Number"
                    }`}
                    onChange={onInputChange}
                    value={values?.phone}
                  />
                </div>
                <div className="input_fields_labels">
                  <label htmlFor="formFileLg">Profile Image*</label>
                  <input
                    accept="image/*"
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                    name="profile_pic"
                    onChange={handleImageChange}
                    required
                  />
                </div>
              </div>

              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label htmlFor="">Location*</label>
                  <input
                    name="location"
                    type="text"
                    placeholder="location"
                    onChange={onInputChange}
                    value={values?.location}
                  />
                </div>
                <div className="input_fields_labels">
                  <label htmlFor="">Latitude*</label>
                  <input
                    type="text"
                    placeholder="latitude"
                    name="latitude"
                    onChange={onInputChange}
                    value={values?.latitude}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label htmlFor="">Longitude*</label>
                  <input
                    name="longitude"
                    type="text"
                    onChange={onInputChange}
                    value={values?.longitude}
                    placeholder="longitude"
                  />
                </div>
                <div className="input_fields_labels">
                  <label htmlFor="">role*</label>
                  <select
                    placeholder="role"
                    name="role"
                    onChange={onInputChange}
                    value={values?.role}
                  >
                    <option hidden>select role</option>
                    <option value="Customer">Customer</option>
                    <option value="Admin">admin</option>
                    <option value="HealthExpert">Health Expert</option>
                  </select>
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label htmlFor="">status*</label>
                  <select
                    placeholder="status"
                    name="status"
                    onChange={onInputChange}
                    value={values?.status}
                  >
                    <option value="" hidden>
                      select status
                    </option>
                    <option value="Active">Active</option>
                    <option value="In-Active">In-Active</option>
                  </select>
                </div>
                <div
                  className="input_fields_labels"
                  style={{ display: "inline" }}
                >
                  <label htmlFor="verify">is Verified</label>

                  <div className="d-flex align-items-center gap-5 ">
                    <div className="form-check d-flex align-items-center ">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="verify"
                        name="verify"
                        value="1"
                        checked={values?.verify === 1}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label" htmlFor="verify">
                        verify
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="non-verify"
                        name="verify"
                        value="0"
                        checked={values?.verify === 0}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label" htmlFor="non-verify">
                        non-verify
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {values?.role === "HealthExpert" ? (
                <>
                  <div className="input_fields_wrapper">
                    <div className="input_fields_labels">
                      <label htmlFor="">category*</label>
                      <select
                        name="category"
                        onChange={onInputChange}
                        value={values?.category}
                      >
                        <option value="" hidden>
                          select category
                        </option>

                        {getAllUserCategoryDetailsdata?.map((item, key) => (
                          <option key={key} value={item?._id}>
                            {item?.title}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className="input_fields_labels">
                      <label htmlFor="">sub category*</label>
                      <select
                        placeholder="sub category"
                        name="sub_category"
                        onChange={onInputChange}
                        value={values?.sub_category}
                      >
                        <option value="" hidden>
                          select sub category
                        </option>
                        {subcategoryList?.map((item, key) => (
                          <option key={key} value={item?._id}>
                            {item?.title}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                  </div>

                  <div className="input_fields_wrapper">
                    <div className="input_fields_labels">
                      <label htmlFor="formFileLg">Upload New Documents*</label>
                      <input
                        className="form-control form-control"
                        id="formFileLg"
                        type="file"
                        name="uploaded_new_documents"
                        onChange={handleImageChange}
                        multiple
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <hr />
            <div className="bunddle_section">
              <h4 className="form_basicInfo">Address</h4>
            </div>{" "}
            <div
              className=" bunddle_section btn_wrapper d-flex justify-content-end"
              style={{ padding: "0px 15px" }}
            >
              <button onClick={addItem} className="increase">
                Add address
              </button>
            </div>
            {inputList?.map((item, index) => (
              <div className="form_div_add_user" key={index}>
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label htmlFor="">Street Address*</label>
                    <input
                      name="street_address"
                      id={`street_address${index}`}
                      type="text"
                      placeholder="Street Address"
                      onChange={(e) => handleonInputChange(e, index)}
                      value={item?.street_address}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="">Apt Suit Unit*</label>
                    <input
                      name="apt_suite_unit"
                      id={`apt_suite_unit${index}`}
                      type="text"
                      placeholder="apt Suit Unit"
                      onChange={(e) => handleonInputChange(e, index)}
                      value={item?.apt_suite_unit}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="">city*</label>
                    <input
                      name="city"
                      id={`city${index}`}
                      type="text"
                      placeholder="jaipur"
                      onChange={(e) => handleonInputChange(e, index)}
                      value={item?.city}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="">State*</label>
                    <input
                      name="state"
                      id={`state${index}`}
                      type="text"
                      placeholder="state"
                      onChange={(e) => handleonInputChange(e, index)}
                      value={item?.state}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="">Postal Code*</label>
                    <input
                      name="postal_code"
                      id={`postal_code${index}`}
                      type="Number"
                      placeholder="Postal Code"
                      onChange={(e) => handleonInputChange(e, index)}
                      value={item?.postal_code}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="">Phone No.*</label>
                    <input
                      name="address_phone"
                      id={`address_phone${index}`}
                      type="Number"
                      placeholder="Phone Number"
                      onChange={(e) => handleonInputChange(e, index)}
                      value={item?.address_phone}
                    />
                  </div>

                  <div className="input_fields_labels">
                    <label htmlFor="">Delivery Instruction*</label>
                    <input
                      name="delivery_instruction"
                      id={`delivery_instruction${index}`}
                      type="text"
                      placeholder="Delivery Instructions"
                      onChange={(e) => handleonInputChange(e, index)}
                      value={item?.delivery_instruction}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>Address type*</label>
                    <select
                      name="address_type"
                      onChange={(e) => handleonInputChange(e, index)}
                      value={item?.address_type}
                    >
                      <option value="" hidden>
                        Select Address Type
                      </option>
                      <option value="Home">Home</option>
                      <option value="Billing">Billing</option>
                    </select>
                  </div>

                  {/* <div className="input_fields_labels w-25">
                    <label htmlFor="">Address type*</label>
                    <div className="d-flex align-items-center gap-5 ">
                      <div className="form-check d-flex align-items-center ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`address_type${index}`}
                          id={`address_type${index}`}
                          value="Home"
                          onChange={(e) => handleonInputChange(e, index)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Home
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center w-25 ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`address_type${index}`}
                          id={`address_type${index}`}
                          value="Billing"
                          onChange={(e) => handleonInputChange(e, index)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Billing
                        </label>
                      </div>
                    </div>
                  </div> */}
                  {inputList?.length > 1 && (
                    <div className="d-flex justify-content-end">
                      <button
                        className="fail_btnnn"
                        onClick={() => deleteItem(index)}
                        style={{ width: "30px" }}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <button className="add_userBtn" onClick={handleAddUser}>
              Add
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddUser;
