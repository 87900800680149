import React, { useState } from "react";
import Header from "../../../components/header/Header";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CreateAttribute } from "../../../redux/homeAction";

const AddAttribute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});

  const onInputChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handelAddAttribute = async (e) => {
    e.preventDefault();
    const data = {
      title: values.title,
    };
    dispatch(CreateAttribute(data, navigate));
  };

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handelAddAttribute}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Add Attribute</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser w-100 ">
                  <div className="input_fields_labels_adduser w-100 ">
                    <label> Title *</label>
                    <input
                      name="title"
                      type="text"
                      placeholder="title"
                      onChange={onInputChange}
                      value={values?.title}
                      required
                    />
                  </div>
                </div>
              </div>
              <button className="save_btn" type="submit">
                save
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddAttribute;
