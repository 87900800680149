import React, { useEffect } from "react";
import Header from "../../../components/header/Header";
import ReactQuill from "react-quill";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateAddShopbycategory,
  GetProductsList,
  getProductCategory,
} from "../../../redux/homeAction";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const AddShopCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getProductsData = useSelector(
    (state) => state.home.getProductsData?.data
  );
  const [values, setValues] = useState({ productsList: [] });
  const animatedComponents = makeAnimated();

  useEffect(() => {
    dispatch(getProductCategory());
    dispatch(GetProductsList());
  }, []);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    if (e.target.name === "main_image") {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
        mainImageShow: URL.createObjectURL(e.target.files[0]),
      });
    }
    if (e.target.name === "product_image") {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
        productImageShow: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSelectProduct = (value) => {
    setValues({ ...values, productsList: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("title", values?.title);
    formData.append("description", values?.description);
    formData.append(
      "product_id",
      JSON.stringify(values?.productsList?.map((item) => item?.value))
    );
    formData.append("product_title", values?.product_title);
    formData.append("main_image", values?.main_image);
    formData.append("product_image", values?.product_image);
    formData.append("product_actual_price", values?.product_actual_price);
    formData.append("product_discount_price", values?.product_discount_price);

    dispatch(CreateAddShopbycategory(formData, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const optionList = getProductsData?.map((item, key) => ({
    value: item?._id,
    label: item?.product_name,
  }));

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handleSubmit}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Create Shop Category</div>
            <div className="">
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser  w-100">
                  <div className="input_fields_labels_adduser  w-100">
                    <label>Title *</label>
                    <input
                      className="input_2"
                      name="title"
                      type="text"
                      placeholder="Title"
                      value={values?.title}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form_div_add_user mt-3">
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>
                    <input
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      accept="image/*"
                      onChange={onInputChange}
                      name="main_image"
                    />
                  </div>
                </div>
                <div className="w-25">
                  <img
                    src={values?.mainImageShow}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="form_div_add_user mb-4">
              <div className="input_fields_labels w-100 mb-5">
                <label>Description *</label>
                <ReactQuill
                  style={{ height: "200px" }}
                  modules={modules}
                  theme="snow"
                  value={values?.description}
                  onChange={(value) =>
                    setValues({ ...values, description: value })
                  }
                  placeholder="The content starts here..."
                />
              </div>
            </div>

            <div className="form_div_add_user">
              <div className="input_fields_labels">
                <label>Products *</label>

                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={values?.productsList}
                  onChange={handleSelectProduct}
                  isMulti
                  options={optionList}
                />
              </div>
            </div>

            <hr />
            <div className="bunddle_section">
              <h4 className="form_basicInfo ">Bundle</h4>
            </div>
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser ">
                <div className="input_fields_labels_adduser ">
                  <label>Product Title *</label>
                  <input
                    className="input_2"
                    name="product_title"
                    id="product_title"
                    type="text"
                    placeholder="Product Title "
                    value={values?.product_title}
                    onChange={onInputChange}
                  />
                </div>

                <div className="input_fields_labels_adduser ">
                  <label>Product Actual Price *</label>
                  <input
                    className="input_2"
                    name="product_actual_price"
                    id="product_actual_price"
                    type="number"
                    placeholder="Product Actual Price"
                    value={values?.product_actual_price}
                    onChange={onInputChange}
                  />
                </div>

                <div className="input_fields_labels">
                  <label htmlFor={`product_image`}>
                    Product Image{" "}
                    <span className="text-danger">
                      (Only jpeg, png, jpg files allowed){" "}
                    </span>{" "}
                  </label>

                  <input
                    className="form-control form-control"
                    id="product_image"
                    type="file"
                    accept="image/*"
                    onChange={onInputChange}
                    name="product_image"
                  />
                  <div className="w-25 mt-2">
                    <img
                      src={values?.productImageShow}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="input_fields_labels ">
                  <label>product discount price *</label>
                  <input
                    className="input_2"
                    name="product_discount_price"
                    id="product_discount_price"
                    type="number"
                    placeholder="Product Actual Price"
                    value={values?.product_discount_price}
                    onChange={onInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center px-5">
              <button className="save_btn mt-3" type="submit">
                save
              </button>
            </div>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddShopCategory;
