import React, { useRef, useState } from "react";
import Header from "../../components/header/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CreateBlog } from "../../redux/homeAction";
import { ToastContainer, toast } from "react-toastify";

const OurLatestEventUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [blog, setBlog] = useState({});
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [logErrors, setLogErrors] = useState({});

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setLogErrors({ ...logErrors, [e.target.name]: false });

    if (e.target.name === "blog_img") {
      setValues({ ...values, [e.target.name]: e.target.files[0] });
    }
  };

  const HandelCreateBlog = async (e) => {
    e.preventDefault();

    let errorExist = false;
    let errorsObject = {};

    if (
      values?.title === "" ||
      values?.title === null ||
      values?.title === undefined
    ) {
      errorsObject.title = "Please enter your title";
      toast.error("Please enter your Title", {
        position: "bottom-left",
        theme: "colored",
      });
      errorExist = true;
    }

    if (blog === "" || blog === null || blog === undefined) {
      errorsObject.title = "Please enter your Blog";
      toast.error("Please enter your blog", {
        position: "bottom-left",
        theme: "colored",
      });
      errorExist = true;
    }

    if (errorExist) {
      setLogErrors(errorsObject);
      return false;
    }

    const formData = new FormData();

    formData.append("title", values?.title);
    formData.append("files", values?.blog_img);
    formData.append("blog", blog);

    // dispatch(CreateBlog(formData, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <div className="form_wrapper_adduser">
          <form action="" onSubmit={HandelCreateBlog}>
            <div className="header_from_user">Update Latest Event</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Title *</label>
                    <input
                      className={` ${
                        logErrors.title ? "errorinput_2" : "input_2"
                      }`}
                      name="title"
                      type="text"
                      placeholder={` ${
                        logErrors.title ? "Enter Your Title" : "Title"
                      }`}
                      onChange={onInputChange}
                      value={values?.title}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>
                    <input
                      name="blog_img"
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      onChange={onInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form_div_add_user mb-4">
              <div className="input_fields_labels w-100 mb-5">
                <label>Description *</label>
                <ReactQuill
                  style={{ height: "200px" }}
                  modules={modules}
                  theme="snow"
                  value={blog}
                  onChange={setBlog}
                  placeholder="The content starts here..."
                />
              </div>
            </div>

            <button className="save_btn mt-3" type="submit">
              save
            </button>
          </form>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default OurLatestEventUpdate;
