import React, { useState } from "react";
import Header from "../../../components/header/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import "../../form.css";
import { useNavigate } from "react-router-dom";
import { AddEmailTemplateData } from "../../../redux/homeAction";

const AddEmailTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [contant, setContant] = useState("");
  const [logErrors, setLogErrors] = useState({});

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setLogErrors({ ...logErrors, [e.target.name]: false });
  };

  const HandelCreateEmailTemplate = async (e) => {
    e.preventDefault();

    let errorExist = false;
    let errorsObject = {};

    if (
      values?.from_name === "" ||
      values?.from_name === null ||
      values?.from_name === undefined
    ) {
      errorsObject.from_name = "Please enter your from name";
      errorExist = true;
    }

    if (contant === "" || contant === null || contant === undefined) {
      errorsObject.contant = "Please enter your from contant";
      errorExist = true;
    }

    if (
      values?.from_email === "" ||
      values?.from_email === null ||
      values?.from_email === undefined
    ) {
      errorsObject.from_email = "Please enter your from email";
      errorExist = true;
    }
    if (
      values?.email_category === "" ||
      values?.email_category === null ||
      values?.email_category === undefined
    ) {
      errorsObject.email_category = "Please enter your email category";
      errorExist = true;
    }
    if (
      values?.email_subject === "" ||
      values?.email_subject === null ||
      values?.email_subject === undefined
    ) {
      errorsObject.email_subject = "Please enter your email subject";
      errorExist = true;
    }

    if (errorExist) {
      setLogErrors(errorsObject);
      return false;
    }

    const data = {
      from_name: values.from_name,
      from_email: values.from_email,
      email_category: values.email_category,
      email_subject: values.email_subject,
      email_content: contant,
      status: values.SType,
    };
    dispatch(AddEmailTemplateData(data, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={HandelCreateEmailTemplate}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Create Email Template</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Form Name *</label>
                    <input
                      className={` ${
                        logErrors.from_name ? "errorinput_2" : "input_2"
                      }`}
                      name="from_name"
                      type="text"
                      placeholder={` ${
                        logErrors.from_name
                          ? "Form Name invalid"
                          : "Enter Your Form Name"
                      }`}
                      onChange={onInputChange}
                      value={values?.from_name}
                      required
                    />
                  </div>
                  <div className="input_fields_labels_adduser ">
                    <label>Form Email *</label>
                    <input
                      className={` ${
                        logErrors.from_email ? "errorinput_2" : "input_2"
                      }`}
                      name="from_email"
                      type="email"
                      placeholder={` ${
                        logErrors.from_email
                          ? "Enter Your Form Email"
                          : "Form Email"
                      }`}
                      onChange={onInputChange}
                      value={values?.from_email}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form_div_add_user mt-3">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Email Category *</label>
                    <input
                      name="email_category"
                      type="text"
                      placeholder="Email Key"
                      onChange={onInputChange}
                      value={values?.email_category}
                      required
                    />
                  </div>
                  <div className="input_fields_labels_adduser ">
                    <label>Email Subject *</label>
                    <input
                      name="email_subject"
                      type="text"
                      placeholder="Email Subject"
                      onChange={onInputChange}
                      value={values?.email_subject}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form_div_add_user mb-4">
              <div className="input_fields_labels w-100 mb-5">
                <label>Email Content *</label>
                <ReactQuill
                  style={{ height: "200px" }}
                  modules={modules}
                  theme="snow"
                  onChange={setContant}
                  placeholder="The content starts here..."
                />
              </div>
            </div>
            <div className="form_div_add_user">
              <div className="input_fields_labels">
                <label>Status *</label>
                <select
                  placeholder="discount type"
                  name="SType"
                  onChange={onInputChange}
                  value={values?.SType}
                  required
                >
                  <option value="Active">Active</option>
                  <option value="In-Active">In-Active</option>
                </select>
                {/* <label htmlFor="formFileLg">Profile Image*</label>
                  <input
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                  /> */}
              </div>
            </div>

            <button className="save_btn mt-3" type="submit">
              save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEmailTemplate;
