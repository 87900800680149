import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";

const UpdateDynamicField = ({
  variantList,
  handleInputChange,
  deleteVariantItem,
}) => {
  return (
    <div>
      <div className="mt-4">
        <div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Price</th>
                <th>Min Quantity</th>
                <th>Max Quantity</th>
                <th>Image</th>
                {/* {/ <th>Status</th> /} */}
                <th>action</th>
              </tr>
            </thead>
            <tbody>
              {variantList?.map((data, i) => (
                <tr id="variant_row_1">
                  <div>
                    <div style={{ display: "flex", paddingTop: "4px" }}>
                      <div
                        style={{
                          fontWeight: 500,
                          backgroundColor: "black",
                          color: "white",
                          borderRadius: "4px",
                          padding: "2px 5px",
                          fontSize: "14px",
                          marginBottom: "5px",
                          marginRight: "5px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>{data?.flavor?.name}</span>
                        <span>{data?.weight?.name}</span>
                        <span>{data?.color?.name}</span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>{data?.flavor?.label}</span>
                        <span>{data?.weight?.label}</span>
                        <span>{data?.color?.label}</span>
                      </div>
                    </div>
                  </div>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      name="varient_price"
                      id={`varient_price${i}`}
                      value={data?.varient_price}
                      onChange={(e) => handleInputChange(e, i)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`varient_minimum_quantity${i}`}
                      name="varient_minimum_quantity"
                      value={data?.varient_minimum_quantity}
                      onChange={(e) => handleInputChange(e, i)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`varient_maximum_quantity${i}`}
                      name="varient_maximum_quantity"
                      value={data?.varient_maximum_quantity}
                      onChange={(e) => handleInputChange(e, i)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      accept="image/*"
                      type="file"
                      className="form-control"
                      name="variant_images"
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-icon delete_variant"
                      onClick={() => deleteVariantItem(i)}
                    >
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UpdateDynamicField;
