import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ViewEmailTemplateDetailsList,
  ViewViewOrderDetailsdata,
  getOrderList,
  updateOrder,
} from "../../redux/homeAction";
import { ToastContainer } from "react-toastify";
import moment from "moment/moment";

const ViewOrderDetails = () => {
  const data = useSelector((state) => state.home.ViewOrderDetailsdata);
  const getorderList = useSelector((state) => state.home.getorderList);
  // add updating lists

  const { id } = useParams();
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (getorderList) {
      for (let i = 0; i < getorderList.length; i++) {
        if (getorderList[i]._id === id) {
          setValues(getorderList[i]);
        }
      }
    }
  }, []);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const hendleUpdateOrders = async (e) => {
    e.preventDefault();
    const data = {
      note: values.note,
      order_status: values.order_status,
    };
    dispatch(updateOrder(id, data, navigate));
  };

  useEffect(() => {
    if (id) {
      dispatch(ViewViewOrderDetailsdata(id));
    }
    dispatch(getOrderList());
  }, [id]);

  return (
    <>
      <Header />
      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="userinfodiv">
              {/* <h4>basic information</h4> */}
              <div className="topone">
                <h2 className="">Order: {data?.order_id} </h2>
                <h2 className="">
                  Date:
                  {data?.createdAt
                    ? moment(data?.createdAt).format("DD MMMM YYYY")
                    : "--"}
                </h2>
              </div>

              <div className="orderdetails_wrapper mt-2">
                <h4 className="view_order_heading mb-0">Order detail</h4>

                <>
                  <p className="mb-0 details_order">
                    Name : {data?.user_id?.first_name} {""}
                    {data?.user_id?.last_name}
                  </p>
                  <p className="mb-0 details_order">
                    Phone : {data?.user_id?.phone}
                  </p>
                  <p className="mb-0 details_order"> Special Request :</p>
                  <p className="mb-0 details_order">
                    Instruction For Delivery : {data?.dilivery_instruction}
                  </p>
                  <p className="mb-0 details_order">
                    Address : Vaishali Nagar, Jaipur Division, 302021
                  </p>
                </>
              </div>

              <form onSubmit={hendleUpdateOrders}>
                <div className="mt-2">
                  <div className="form_div_add_user p-0">
                    <div className="input_fields_labels w-100">
                      <label>Order Status *</label>
                      <select
                        className="form-control order_status form-select valid"
                        placeholder="discount type"
                        name="order_status"
                        onChange={onInputChange}
                        value={values?.order_status}
                      >
                        <option value="" hidden>
                          Select Status
                        </option>
                        <option value="pending">Pending</option>
                        <option value="order-placed">Order Placed</option>
                        <option value="in-progress">In Progress</option>
                        <option value="shipped">Shipped</option>
                        <option value="delivered">Delivered</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="form_div_add_user p-0">
                    <div className="input_fields_labels w-100">
                      <label>Note *</label>
                      <textarea
                        name="note"
                        className=" w-100 text_area"
                        rows="5"
                        cols="50"
                        maxLength="250"
                        placeholder="Maximun 250 Character's"
                        required
                        onChange={onInputChange}
                        value={values?.note}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <button type="submit" style={{ width: "70px" }}>
                    save
                  </button>
                </div>
              </form>

              <div className="table-responsive mt-2">
                <table
                  id="example"
                  className="table table-striped  text-nowrap w-100 no-action   "
                >
                  <thead>
                    <tr className="tabel_titles">
                      <th>#</th>
                      <th>Product Name</th>
                      <th>Cost</th>
                      <th>Quantity</th>
                      <th>status</th>
                      <th>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.items?.map((item, key) => {
                      return (
                        <tr className="tabel_titles" key={key}>
                          <td>{key}</td>

                          <td>{item?.product_id?.product_name || "--"}</td>
                          <td>₹{item?.product_id?.market_price || "00.00"}</td>
                          <td>{item?.quantity || "--"}</td>
                          <td className="">
                            <div>{data?.order_status || "--"}</div>
                          </td>
                          <td>
                            ₹
                            {item?.product_id?.market_price * item?.quantity ||
                              "00.00"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="row float-end w-50">
                  <table className="table order-detail">
                    <tbody>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ paddingRight: "-1px" }}>TOTAL: </th>
                        <td
                          style={{ paddingTop: "8px" }}
                          className="border-bottom"
                        >
                          ₹{data?.totalCost || "--"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* <Link to={"/email-template"}>
                <button>Back to list</button>
              </Link> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewOrderDetails;
