import React, { useState } from "react";
import "../updateuser/updateuser.css";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateUsers,
  getAllUserData,
  getSubCategoryData,
  getUserCategory,
} from "../../../redux/homeAction";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";

const UpdateUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token1 = localStorage.getItem("adminToken");
  const [isVerify, setIsVerify] = useState(null);
  const [productImage, setProductImage] = useState({});
  const getAllUserCategoryDetailsdata = useSelector(
    (state) => state.home.getAllUserCategoryDetailsdata?.data
  );
  const getSubCategorydata = useSelector(
    (state) => state.home.getSubCategorydata?.data
  );
  const getUsersList = useSelector(
    (state) => state.home.getAllusersListData?.data
  );
  const [values, setValues] = useState({
    address: [
      {
        street_address: "",
        apt_suite_unit: "",
        city: "",
        state: "",
        postal_code: "",
        address_phone: "",
        delivery_instruction: "",
        address_type: "",
      },
    ],
  });

  const [subcategoryList, setSubCategoryList] = useState([]);

  useEffect(() => {
    dispatch(getAllUserData(token1));
  }, [id, token1]);

  useEffect(() => {
    dispatch(getUserCategory());
    dispatch(getSubCategoryData());
  }, []);

  useEffect(() => {
    if (getSubCategorydata) {
      setSubCategoryList(
        getSubCategorydata?.filter(
          (item) => item?.user_category_id?._id === values.category
        )
      );
    }
  }, [getSubCategorydata, values?.category]);

  useEffect(() => {
    if (getUsersList) {
      for (let i = 0; i < getUsersList?.length; i++) {
        if (getUsersList[i]?._id === id) {
          setValues(getUsersList[i]);
        }
      }
    }
  }, [getUsersList]);

  const onInputChange = (e) => {
    let newValue = e.target.value;
    setValues({ ...values, [e.target.name]: newValue });
    if (e.target.name === "phone") {
      newValue = newValue?.replace(/\D/g, "");
    }

    if (newValue === "Admin" || newValue === "Customer") {
      setValues({
        ...values,
        [e.target.name]: newValue,
        category: null,
        sub_category: null,
      });
    }
  };

  const deleteItem = (index) => {
    const addressList = values?.address;
    let list = [...addressList];
    list.splice(index, 1);
    setValues({ ...values, address: list });
  };

  const addItem = () => {
    setValues({
      ...values,
      address: [
        ...values.address,
        {
          street_address: "",
          apt_suite_unit: "",
          city: "",
          state: "",
          postal_code: "",
          address_phone: "",
          delivery_instruction: "",
          address_type: "",
        },
      ],
    });
  };

  const handleonInputChange = (e, index) => {
    const { name, value } = e.target;
    const addressList = values?.address;
    let list = [...addressList];
    list[index][name] = value;
    setValues({ ...values, address: list });
  };

  const handleImageChange = (e) => {
    const fname = e.target.name;

    let fieldName;
    let fileName;
    let file;
    if (fname === "profile_pic") {
      file = e.target.files[0];
      fieldName = e.target.name;
      fileName = `${fieldName}.${file.name.split(".").pop()}`;
    } else if (fname === "uploaded_new_documents") {
      file = e.target.files;
      fieldName = e.target.name;
      const galleryImages = Array.from(file).map((image, index) => ({
        file: image,
        name: `${fieldName}.${image.name.split(".").pop()}`,
      }));

      setProductImage((prevImages) => ({
        ...prevImages,
        [fieldName]: galleryImages,
      }));
      return;
    }

    setProductImage((prevImages) => ({
      ...prevImages,
      [fieldName]: {
        file,
        name: fileName,
      },
    }));
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(productImage).forEach((fieldName) => {
      const fileObject = productImage[fieldName];
      if (Array.isArray(fileObject)) {
        fileObject.forEach((image) => {
          formData.append("files", image.file, image.name);
        });
      } else {
        formData.append("files", fileObject.file, fileObject.name);
      }
    });

    formData.append("last_name", values?.last_name);
    formData.append("first_name", values?.first_name);
    formData.append("email", values?.email);
    formData.append("password", values?.password);
    formData.append("phone", values?.phone);
    formData.append("location", values?.location);
    formData.append("latitude", values?.latitude);
    formData.append("longitude", values?.longitude);
    formData.append("verify", values?.verify);
    formData.append("role", values?.role);
    if (values?.role === "HealthExpert") {
      formData.append("category", values?.category);
      formData.append("sub_category", values?.sub_category);
    } else {
      formData.append("category", null);
      formData.append("sub_category", null);
    }
    formData.append("status", values?.status);

    formData.append("address", JSON.stringify(values?.address));

    const rawData = {};
    formData.forEach((value, key) => {
      // Check if the value is a File object
      if (value instanceof File) {
        rawData[key] = { file: value, name: value.name };
      } else {
        rawData[key] = value;
      }
    });

    dispatch(UpdateUsers(id, rawData, navigate, { type: "user" }));
  };

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <div className="form_wrapper_adduser">
          <div className="header_from_user">Update User</div>
          <div className="">
            <h4 className="form_basicInfo">Basic Information</h4>
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser">
                <div className="input_fields_labels_adduser">
                  <label>First Name*</label>
                  <input
                    name="first_name"
                    type="text"
                    placeholder="first name"
                    onChange={onInputChange}
                    value={values?.first_name}
                  />
                </div>
                <div className="input_fields_labels">
                  <label>Last Name*</label>
                  <input
                    name="last_name"
                    type="text"
                    placeholder="last name"
                    onChange={onInputChange}
                    value={values?.last_name}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>email*</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="abc@gmail.com"
                    onChange={onInputChange}
                    value={values?.email}
                  />
                </div>
                <div className="input_fields_labels">
                  <label>Password*</label>
                  <input
                    name="password"
                    type="password"
                    placeholder="*********"
                    onChange={onInputChange}
                    value={values?.password}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>Phone*</label>
                  <input
                    name="phone"
                    type="text"
                    maxLength="10"
                    placeholder="9999999999"
                    onChange={onInputChange}
                    value={values?.phone}
                  />
                </div>
                <div className="input_fields_labels">
                  <label htmlFor="formFileLg">Profile Image*</label>
                  <input
                    accept="image/*"
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                    name="ProfilePic"
                    onChange={onInputChange}
                    value={values?.ProfilePic}
                  />
                </div>
              </div>

              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>Location*</label>
                  <input
                    name="location"
                    type="text"
                    placeholder="location"
                    onChange={onInputChange}
                    value={values?.location}
                  />
                </div>
                <div className="input_fields_labels">
                  <label>Latitude*</label>
                  <input
                    type="text"
                    placeholder="latitude"
                    name="latitude"
                    onChange={onInputChange}
                    value={values?.latitude}
                  />
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>Longitude*</label>
                  <input
                    name="longitude"
                    type="text"
                    onChange={onInputChange}
                    value={values?.longitude}
                    placeholder="longitude"
                  />
                </div>
                <div className="input_fields_labels">
                  <label>role*</label>
                  <select
                    placeholder="role"
                    name="role"
                    onChange={onInputChange}
                    value={values?.role}
                  >
                    <option hidden>select role</option>
                    <option value="Customer">Customer</option>
                    <option value="Admin">admin</option>
                    <option value="HealthExpert">Health Expert</option>
                  </select>
                </div>
              </div>
              <div className="input_fields_wrapper">
                <div className="input_fields_labels">
                  <label>status*</label>
                  <select
                    placeholder="status"
                    name="status"
                    onChange={onInputChange}
                    value={values?.status}
                  >
                    <option value="" hidden>
                      select status
                    </option>
                    <option value="Active">Active</option>
                    <option value="In-Active">In-Active</option>
                  </select>
                </div>{" "}
                <div
                  className="input_fields_labels"
                  style={{ display: "inline" }}
                >
                  <label htmlFor="isVerify">is Verified</label>

                  <div className="d-flex align-items-center gap-5 ">
                    <div className="form-check d-flex align-items-center ">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="verify"
                        name="verify"
                        value="1"
                        checked={values?.verify === "1"}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label" htmlFor="verify">
                        verify
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="non-verify"
                        name="verify"
                        value="0"
                        checked={values?.verify === "0"}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label" htmlFor="non-verify">
                        non-verify
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* category */}

              {values?.role === "HealthExpert" ? (
                <>
                  <div className="input_fields_wrapper">
                    <div className="input_fields_labels">
                      <label>category*</label>
                      <select
                        name="category"
                        onChange={onInputChange}
                        value={values?.category}
                      >
                        <option value="" hidden>
                          select category
                        </option>

                        {getAllUserCategoryDetailsdata?.map((item, key) => (
                          <option key={key} value={item?._id}>
                            {item?.title}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                    <div className="input_fields_labels">
                      <label>sub category*</label>
                      <select
                        placeholder="sub category"
                        name="sub_category"
                        onChange={onInputChange}
                        value={values?.sub_category}
                      >
                        <option value="" hidden>
                          select sub category
                        </option>
                        {subcategoryList?.map((item, key) => (
                          <option key={key} value={item?._id}>
                            {item?.title}
                          </option>
                        ))}
                      </select>
                    </div>{" "}
                  </div>

                  <div className="input_fields_wrapper flex-column ">
                    <div className="input_fields_labels">
                      <label htmlFor="formFileLg">Upload New Documents*</label>
                      <input
                        className="form-control form-control"
                        id="formFileLg"
                        type="file"
                        name="uploaded_new_documents"
                        onChange={handleImageChange}
                        multiple
                      />
                    </div>
                    <div className="form-group mb-3 w-100">
                      <div className="mt-3 image_position ui-sortable">
                        {values?.documents?.map((item, key) => (
                          <span
                            className="pip mx-2 gallery_images ui-sortable-handle "
                            style={{
                              border: "1px gray solid",
                              padding: "15px",
                              borderRadius: "6px",
                            }}
                          >
                            <img
                              src={item}
                              alt="doc img"
                              width="100"
                              height="100"
                            />
                            <a
                              className="btn btn-sm btn-icon p-1 remove_btn gallery_remove_icon"
                              style={{ position: "absolute" }}
                            >
                              <MdDeleteForever />
                            </a>
                            <input
                              type="hidden"
                              value="1701346933-Rectangle 25.png"
                              nameName="old_gallery_image[]"
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <hr className="mt-5" />
            <div className="bunddle_section ">
              <h4 className="form_basicInfo">Address</h4>
            </div>{" "}
            <div
              className=" bunddle_section btn_wrapper d-flex justify-content-end"
              style={{ padding: "0px 15px" }}
            >
              <button onClick={addItem} className="increase">
                Add address
              </button>
            </div>
            {values?.address?.map((valueItem, index) => {
              return (
                <>
                  <div className="form_div_add_user">
                    <div className="input_fields_wrapper_adduser">
                      <div className="input_fields_labels">
                        <label>Street Address*</label>
                        <input
                          name="street_address"
                          id={`street_address${index}`}
                          type="text"
                          placeholder="Street Address"
                          onChange={(e) => handleonInputChange(e, index)}
                          value={valueItem?.street_address}
                        />
                      </div>
                      <div className="input_fields_labels">
                        <label>Apt Suit Unit*</label>
                        <input
                          name="apt_suite_unit"
                          id={`apt_suite_unit${index}`}
                          type="text"
                          placeholder="apt Suit Unit"
                          onChange={(e) => handleonInputChange(e, index)}
                          value={valueItem?.apt_suite_unit}
                        />
                      </div>
                      <div className="input_fields_labels">
                        <label>city*</label>
                        <input
                          name="city"
                          id={`city${index}`}
                          type="text"
                          placeholder="jaipur"
                          onChange={(e) => handleonInputChange(e, index)}
                          value={valueItem?.city}
                        />
                      </div>
                      <div className="input_fields_labels">
                        <label>State*</label>
                        <input
                          name="state"
                          id={`state${index}`}
                          type="text"
                          placeholder="state"
                          onChange={(e) => handleonInputChange(e, index)}
                          value={valueItem?.state}
                        />
                      </div>
                      <div className="input_fields_labels">
                        <label>Postal Code*</label>
                        <input
                          name="postal_code"
                          id={`postal_code${index}`}
                          type="Number"
                          placeholder="Postal Code"
                          onChange={(e) => handleonInputChange(e, index)}
                          value={valueItem?.postal_code}
                        />
                      </div>
                      <div className="input_fields_labels">
                        <label>Phone No.*</label>
                        <input
                          name="address_phone"
                          id={`address_phone${index}`}
                          type="Number"
                          placeholder="Phone Number"
                          onChange={(e) => handleonInputChange(e, index)}
                          value={valueItem?.address_phone}
                        />
                      </div>

                      <div className="input_fields_labels">
                        <label>Delivery Instruction*</label>
                        <input
                          name="delivery_instruction"
                          id={`delivery_instruction${index}`}
                          type="text"
                          placeholder="Delivery Instructions"
                          onChange={(e) => handleonInputChange(e, index)}
                          value={valueItem?.delivery_instruction}
                        />
                      </div>
                      <div className="input_fields_labels">
                        <label>Address type*</label>
                        <select
                          name="address_type"
                          onChange={(e) => handleonInputChange(e, index)}
                          value={valueItem?.address_type}
                        >
                          <option value="" hidden>
                            Select Address Type
                          </option>
                          <option value="Home">Home</option>
                          <option value="Billing">Billing</option>
                        </select>
                      </div>

                      {/* <div className="input_fields_labels w-25">
                        <label>Address type*</label>
                        <div className="d-flex align-items-center gap-5 ">
                          <div className="form-check d-flex align-items-center ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`address_type`}
                              id={`address_type${index}`}
                              value="Home"
                              checked={
                                valueItem?.address_type === "Home"
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleonInputChange(e, index)}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Home
                            </label>
                          </div>
                          <div className="form-check d-flex align-items-center w-25 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`address_type`}
                              id={`address_type${index}`}
                              value="Billing"
                              checked={
                                valueItem?.address_type === "Billing"
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleonInputChange(e, index)}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault2"
                            >
                              Billing
                            </label>
                          </div>
                        </div>
                      </div> */}
                      {values?.address?.length > 1 && (
                        <div className="d-flex justify-content-end">
                          <button
                            className="fail_btnnn"
                            onClick={() => deleteItem(index)}
                            style={{ width: "30px" }}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
            <button className="add_userBtn" onClick={handleAddUser}>
              Update
            </button>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default UpdateUser;
