import "./App.css";
import SideBar from "./components/Sidebar/SideBar";
import { Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Order from "./pages/Order";
import Setting from "./pages/Setting";
import Login from "./auth/Login";
import Registration from "./auth/Registration";
import NotFound from "./pages/NotFound/NotFound";
import PublicRoutes from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import UserData from "./pages/UserData";
import AddUser from "./form/createForms/addUser/AddUser";
import Header from "./components/header/Header";
import SingelUserGet from "./pages/SingelUserGet";
import UpdateUser from "./form/updatesForms/updateuser/UpdateUser";
import Customer from "./pages/customer/Customer";
import HealthExpert from "./pages/healthExpert/HealthExpert";
import EmailTemplate from "./pages/emailTemplate/EmailTemplate";
import Products from "./pages/products/Products";
import Blog from "./pages/blog/Blog";
import Coupon from "./pages/coupon/Coupon";
import Attributes from "./pages/products/Attributes";
import AttributeValue from "./pages/products/AttributeValue";
import Tags from "./pages/products/Tags";
import ProductCategories from "./pages/products/ProductCategories";
import ProductSubCategories from "./pages/products/ProductSubCategories";
// import AddProducts from "./form/createForms/addproduct/AddProducts";
import AddAttribute from "./form/createForms/attribute/AddAttribute";
import AddAttriValue from "./form/createForms/attribute/AddAttriValue";
import AddTags from "./form/createForms/attribute/AddTags";
import AddCreateCategory from "./form/createForms/category/AddCreateCategory";
import AddEmailTemplate from "./form/createForms/emailTemplate/AddEmailTemplate";
import AddCoupons from "./form/createForms/addCoupons/AddCoupons";
import ShopAndCategory from "./pages/Shop/ShopAndCategory";
import AddBlog from "./form/createForms/addBlog/AddBlog";
import AddShopCategory from "./form/createForms/addShop/AddShopCategory";
import UpdateCategoryProduct from "./form/createForms/addCategoryProduct/UpdateCategoryProduct";
import ViewSingelProductCategory from "./pages/singleViewPages/ViewSingelProductCategory";
import UpdateBlog from "./form/updatesForms/UpdateBlog";
import Feedback from "./pages/Feedback";
import UpdateCoupon from "./form/updatesForms/UpdateCoupon";
import ViewCouponDetails from "./pages/singleViewPages/ViewCouponDetails";
import ViewBlogsDetails from "./pages/singleViewPages/ViewBlogsDetails";
import EmailTemplateUpdate from "./form/updatesForms/EmailTemplateUpdate";
import ViewEmailTemplate from "./pages/singleViewPages/ViewEmailTemplate";
import DietPlan from "./pages/dietPlan/DietPlan";
import AddDiet from "./form/createForms/addDiet/AddDiet";
import DietPlanUpdate from "./form/updatesForms/DietPlanUpdate";
import ViewDietPlanDetails from "./pages/singleViewPages/ViewDietPlanDetails";
import ViewProduct from "./pages/singleViewPages/ViewProduct";
import ProductUpdate from "./form/updatesForms/ProductUpdate";
import ViewFeedback from "./pages/singleViewPages/ViewFeedback";
import ViewShopbycategory from "./pages/singleViewPages/ViewShopbycategory";
import ShopBycategoryUpdate from "./form/updatesForms/ShopBycategoryUpdate";
import ViewOrderDetails from "./pages/singleViewPages/ViewOrderDetails";

import AddBesureToFit from "./form/createForms/addShop/AddBesureToFit";
import BeSuretoFit from "./pages/Shop/BeSuretoFit";
import AboutUs from "./pages/aboutUs/AboutUs";
import UpdateTags from "./form/updatesForms/UpdateTags";
import AttributeUpdate from "./form/updatesForms/AttributeUpdate";
import AttriValueUpdate from "./form/updatesForms/AttriValueUpdate";
import OurLatestEvent from "./pages/Shop/OurLatestEvent";
import AddOurLatestEvent from "./form/createForms/addShop/AddOurLatestEvent";
import OurLatestEventUpdate from "./form/updatesForms/OurLatestEventUpdate";
import QuestionAndAnswers from "./pages/QuesandAnswer/QuestionAndAnswers";
import Page from "./pages/page/Page";
import AddPages from "./form/createForms/page/AddPages";
import PageUpdate from "./form/updatesForms/PageUpdate";
import ProfilePage from "./pages/singleViewPages/ProfilePage";
import Doctor from "./pages/doctor/Doctor";
import AddDocCategory from "./form/createForms/addDoctorcategory/AddDocCategory";
import Category from "./pages/products/Category";
import SubCategory from "./pages/products/SubCategory";
import AddCategory from "./form/createForms/addcategory/AddCategory";
import AddSubCategory from "./form/createForms/addcategory/AddSubCategory";
import UpdateUserCategory from "./form/updatesForms/updateuser/UpdateUserCategory";
import UserCategorysinglePage from "./pages/singleViewPages/UserCategorysinglePage";
import UpdateuserSubcategory from "./form/updatesForms/updateuser/UpdateuserSubcategory";
import SubcategoryView from "./pages/singleViewPages/SubcategoryView";
import AddUpdateProducts from "./pages/addUpdateProducts/AddUpdateProducts";
import AddProducts from "./pages/addUpdateProducts/AddProducts";

function App() {
  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="/registration" element={<Registration />} />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route element={<Header />} />
      <Route element={<PrivateRoute />}>
        <Route
          path="/dashboard"
          element={
            <SideBar>
              <Dashboard />
            </SideBar>
          }
        />
        <Route
          path="/users"
          element={
            <SideBar>
              <Users />
            </SideBar>
          }
        />
        <Route
          path="/customer"
          element={
            <SideBar>
              <Customer />
            </SideBar>
          }
        />
        <Route
          path="/health-expert"
          element={
            <SideBar>
              <HealthExpert />
            </SideBar>
          }
        />

        <Route
          path="/order"
          element={
            <SideBar>
              <Order />
            </SideBar>
          }
        />
        <Route
          path="/add-category"
          element={
            <SideBar>
              <AddCreateCategory />
            </SideBar>
          }
        />

        <Route
          path="/fit"
          element={
            <SideBar>
              <BeSuretoFit />
            </SideBar>
          }
        />
        <Route
          path="/add-tags"
          element={
            <SideBar>
              <AddTags />
            </SideBar>
          }
        />
        <Route
          path="/doctor"
          element={
            <SideBar>
              <Doctor />
            </SideBar>
          }
        />
        <Route
          path="/view-sub-category/:id"
          element={
            <SideBar>
              <SubcategoryView />
            </SideBar>
          }
        />
        <Route
          path="/add-doctor-category"
          element={
            <SideBar>
              <AddDocCategory />
            </SideBar>
          }
        />
        <Route
          path="/category"
          element={
            <SideBar>
              <Category />
            </SideBar>
          }
        />
        <Route
          path="/add-user-category"
          element={
            <SideBar>
              <AddCategory />
            </SideBar>
          }
        />
        <Route
          path="/sub-category"
          element={
            <SideBar>
              <SubCategory />
            </SideBar>
          }
        />
        <Route
          path="/add-sub-category"
          element={
            <SideBar>
              <AddSubCategory />
            </SideBar>
          }
        />
        <Route
          path="/update-sub-category/:id"
          element={
            <SideBar>
              <UpdateuserSubcategory />
            </SideBar>
          }
        />
        <Route
          path="/profile"
          element={
            <SideBar>
              <ProfilePage />
            </SideBar>
          }
        />
        <Route
          path="/about-us"
          element={
            <SideBar>
              <AboutUs />
            </SideBar>
          }
        />
        <Route
          path="/add-blog"
          element={
            <SideBar>
              <AddBlog />
            </SideBar>
          }
        />
        <Route
          path="/update-product-category/:id"
          element={
            <SideBar>
              <UpdateCategoryProduct />
            </SideBar>
          }
        />
        <Route
          path="/shop"
          element={
            <SideBar>
              <ShopAndCategory />
            </SideBar>
          }
        />
        <Route
          path="/shop-and-category"
          element={
            <SideBar>
              <ShopAndCategory />
            </SideBar>
          }
        />
        <Route
          path="/add-email_template"
          element={
            <SideBar>
              <AddEmailTemplate />
            </SideBar>
          }
        />
        <Route
          path="/add-coupons"
          element={
            <SideBar>
              <AddCoupons />
            </SideBar>
          }
        />
        <Route
          path="/qna"
          element={
            <SideBar>
              <QuestionAndAnswers />
            </SideBar>
          }
        />
        <Route
          path="/add-atribute-values"
          element={
            <SideBar>
              <AddAttriValue />
            </SideBar>
          }
        />

        <Route
          path="/our-latest-event"
          element={
            <SideBar>
              <OurLatestEvent />
            </SideBar>
          }
        />
        <Route
          path="/pages"
          element={
            <SideBar>
              <Page />
            </SideBar>
          }
        />
        <Route
          path="/pages/create"
          element={
            <SideBar>
              <AddPages />
            </SideBar>
          }
        />
        <Route
          path="/pages/update/:id"
          element={
            <SideBar>
              <PageUpdate />
            </SideBar>
          }
        />
        <Route
          path="/update-our-latest-event"
          element={
            <SideBar>
              <OurLatestEventUpdate />
            </SideBar>
          }
        />
        <Route
          path="/add-our-latest-event"
          element={
            <SideBar>
              <AddOurLatestEvent />
            </SideBar>
          }
        />
        <Route
          path="/setting"
          element={
            <SideBar>
              <Setting />
            </SideBar>
          }
        />
        <Route
          path="/site-setting"
          element={
            <SideBar>
              <Setting />
            </SideBar>
          }
        />
        <Route
          path="/file-manager/profile"
          element={
            <SideBar>
              <UserData />
            </SideBar>
          }
        />
        <Route
          path="/email-template"
          element={
            <SideBar>
              <EmailTemplate />
            </SideBar>
          }
        />
        <Route
          path="/products"
          element={
            <SideBar>
              <Products />
            </SideBar>
          }
        />
        <Route
          path="/product"
          element={
            <SideBar>
              <Products />
            </SideBar>
          }
        />
        <Route
          path="/products/attributes"
          element={
            <SideBar>
              <Attributes />
            </SideBar>
          }
        />
        <Route
          path="/products/attribute-values"
          element={
            <SideBar>
              <AttributeValue />
            </SideBar>
          }
        />
        <Route
          path="/products/tags"
          element={
            <SideBar>
              <Tags />
            </SideBar>
          }
        />
        <Route
          path="/products/product-categories"
          element={
            <SideBar>
              <ProductCategories />
            </SideBar>
          }
        />
        <Route
          path="/products/product-sub-categories"
          element={
            <SideBar>
              <ProductSubCategories />
            </SideBar>
          }
        />
        <Route
          path="/addUser"
          element={
            <SideBar>
              <AddUser />
            </SideBar>
          }
        />
        <Route
          path="/add-product"
          element={
            <SideBar>
              <AddProducts />
            </SideBar>
          }
        />
        <Route
          path="/add-products"
          element={
            <SideBar>
              <AddProducts />
            </SideBar>
          }
        />
        <Route
          path="/add-products/:id"
          element={
            <SideBar>
              <AddUpdateProducts />
            </SideBar>
          }
        />
        <Route
          path="/add-atribute"
          element={
            <SideBar>
              <AddAttribute />
            </SideBar>
          }
        />
        <Route
          path="/add-shop-category"
          element={
            <SideBar>
              <AddShopCategory />
            </SideBar>
          }
        />
        <Route
          path="/diet/addDiet"
          element={
            <SideBar>
              <AddDiet />
            </SideBar>
          }
        />
        <Route
          path="/blog"
          element={
            <SideBar>
              <Blog />
            </SideBar>
          }
        />
        <Route
          path="/coupon"
          element={
            <SideBar>
              <Coupon />
            </SideBar>
          }
        />
        <Route
          path="/feedback"
          element={
            <SideBar>
              <Feedback />
            </SideBar>
          }
        />
        <Route
          path="/add-fit"
          element={
            <SideBar>
              <AddBesureToFit />
            </SideBar>
          }
        />
        <Route
          path="/diet-plan"
          element={
            <SideBar>
              <DietPlan />
            </SideBar>
          }
        />
        <Route
          path="/users/userinfo/:id"
          element={
            <SideBar>
              <SingelUserGet />
            </SideBar>
          }
        />
        <Route
          path="/view-product-category/:id"
          element={
            <SideBar>
              <ViewSingelProductCategory />
            </SideBar>
          }
        />
        <Route
          path="/blog/update-blog/:id"
          element={
            <SideBar>
              <UpdateBlog />
            </SideBar>
          }
        />
        <Route
          path="/users/update-users/:id"
          element={
            <SideBar>
              <UpdateUser />
            </SideBar>
          }
        />
        <Route
          path="/update-coupon/:id"
          element={
            <SideBar>
              <UpdateCoupon />
            </SideBar>
          }
        />
        <Route
          path="/view-coupon-details/:id"
          element={
            <SideBar>
              <ViewCouponDetails />
            </SideBar>
          }
        />
        <Route
          path="/blog/details/:id"
          element={
            <SideBar>
              <ViewBlogsDetails />
            </SideBar>
          }
        />
        <Route
          path="/update-emailTemplate/:id"
          element={
            <SideBar>
              <EmailTemplateUpdate />
            </SideBar>
          }
        />
        <Route
          path="/view-emailTemplate/:id"
          element={
            <SideBar>
              <ViewEmailTemplate />
            </SideBar>
          }
        />
        <Route
          path="/view-user-category/:id"
          element={
            <SideBar>
              <UserCategorysinglePage />
            </SideBar>
          }
        />
        <Route
          path="/diet/diet-plan-update/:id"
          element={
            <SideBar>
              <DietPlanUpdate />
            </SideBar>
          }
        />
        <Route
          path="/diet/diet-plan-details/:id"
          element={
            <SideBar>
              <ViewDietPlanDetails />
            </SideBar>
          }
        />
        <Route
          path="/product/product-view/:id"
          element={
            <SideBar>
              <ViewProduct />
            </SideBar>
          }
        />
        <Route
          path="/product/product-update/:id"
          element={
            <SideBar>
              <ProductUpdate />
            </SideBar>
          }
        />
        <Route
          path="/feedback/view-feedback/:id"
          element={
            <SideBar>
              <ViewFeedback />
            </SideBar>
          }
        />
        <Route
          path="/shopbycategory-details/:id"
          element={
            <SideBar>
              <ViewShopbycategory />
            </SideBar>
          }
        />
        <Route
          path="/shopbycategory-update/:id"
          element={
            <SideBar>
              <ShopBycategoryUpdate />
            </SideBar>
          }
        />
        <Route
          path="/order/order-detail/:id"
          element={
            <SideBar>
              <ViewOrderDetails />
            </SideBar>
          }
        />
        <Route
          path="/update-tags/:id"
          element={
            <SideBar>
              <UpdateTags />
            </SideBar>
          }
        />
        <Route
          path="/update-attribute/:id"
          element={
            <SideBar>
              <AttributeUpdate />
            </SideBar>
          }
        />
        <Route
          path="/update-user-category/:id"
          element={
            <SideBar>
              <UpdateUserCategory />
            </SideBar>
          }
        />
        <Route
          path="/update-attribute-value/:id"
          element={
            <SideBar>
              <AttriValueUpdate />
            </SideBar>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
