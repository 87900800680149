import React, { useState, useEffect } from "react";
import Header from "../../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  getSubCategoryData,
  getUserCategory,
  updateuserSubcategory,
} from "../../../redux/homeAction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UpdateuserSubcategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const getAllUserCategoryDetailsdata = useSelector(
    (state) => state.home.getAllUserCategoryDetailsdata?.data
  );
  const getSubCategorydata = useSelector(
    (state) => state.home.getSubCategorydata?.data
  );

  useEffect(() => {
    dispatch(getSubCategoryData());
    dispatch(getUserCategory());
  }, []);

  useEffect(() => {
    if (getSubCategorydata) {
      for (let i = 0; i < getSubCategorydata.length; i++) {
        if (getSubCategorydata[i]?._id === id) {
          setValues({
            ...getSubCategorydata[i],
            user_category_id: getSubCategorydata[i].user_category_id._id,
          });
        }
      }
    }
  }, [getSubCategorydata]);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handelAddAttributeVAlue = async (e) => {
    e.preventDefault();
    const data = {
      title: values?.title,
      user_category_id: values?.user_category_id,
      description: values?.description,
    };
    dispatch(updateuserSubcategory(id, data, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handelAddAttributeVAlue}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Update Sub Category</div>
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels">
                  <label>Category *</label>
                  <select
                    placeholder="Attribute"
                    name="user_category_id"
                    onChange={onInputChange}
                    value={values?.user_category_id}
                    required
                  >
                    <option value="" selected="">
                      Select Category
                    </option>
                    {getAllUserCategoryDetailsdata?.map((item, key) => (
                      <option key={key} value={item?._id}>
                        {item?.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input_fields_labels_adduser ">
                  <label> title *</label>
                  <input
                    name="title"
                    type="text"
                    placeholder="Attribute value"
                    onChange={onInputChange}
                    value={values?.title}
                    required
                  />
                </div>
              </div>
              <div className="input_fields_wrapper_adduser  ">
                <div className="w-100 mb-4">
                  <div className="input_fields_labels w-100 mb-5">
                    <label>Description *</label>
                    <ReactQuill
                      name="description"
                      style={{ height: "200px" }}
                      modules={modules}
                      theme="snow"
                      value={values?.description}
                      onChange={(value) =>
                        setValues({ ...values, description: value })
                      }
                      placeholder="The content starts here..."
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <button className="save_btn" type="submit">
              save
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default UpdateuserSubcategory;
