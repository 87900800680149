import {
  SET_POPUP_ACTIONS,
  SET_BTN_LOADING,
  SET_GET_ADMIN_DATA,
  SET_GET_USERS_LIST,
  SET_CREATE_USER,
  SET_GET_SINGEL_USER_DETAILS,
  GET_HEALTHEXPERT_DETAILS,
  SET_UPDATE_USER,
  USER_DELETE,
  GET_SHOPBY_CATEGORY_DATA,
  SET_CREATE_CATEGORY,
  GET_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  GET_SINGEL_PRODUCT_CATEGORY_DETAILS,
  GET_BLOG_DATA,
  ADD_BLOG_DATA,
  DELETE_BLOG_DATA,
  GET_CUSTUMER_DETAILS,
  SET_UPDATE_BOLG,
  GET_FEEDBACK,
  GET_COUPON,
  CREATE_COUPONS,
  UPDATE_COUPONS,
  VIEW_COUPONS,
  DELETE_COUPONS,
  VIEW_BOLG,
  GET_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  VIEW_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  GET_DIET_PLAN,
  VIEW_DIET_PLAN,
  DELETE_DIET_PLAN,
  GET_SETTINGS_DATA,
  ADD_SETTINGS_DATA,
  ADD_DIET_PLAN,
  UPDATE_DIET_PLAN,
  GET_PRODUCTS,
  CREATE_PRODUCTS,
  VIEW_PRODUCTS,
  DELETE_PRODUCTS,
  CREATE_SHOPBY_CATEGORY_DATA,
  VIEW_FEEDBACK,
  GET_ORDER_LIST,
  VIEW_SHOPBY_CATEGORY_DATA,
  SET_DELETE_SHOPBY_CATEGORY_DATA,
  SET_UPDATE_SHOPBY_CATEGORY_DATA,
  SET_VIEW_ORDER_DETAILS,
  SET_DELETE_ORDER_DETAILS,
  UPDATE_PRODUCTS,
  SET_GET_TEGS,
  SET_CREATE_TEGS,
  SET_DELETE_TEGS,
  SET_GET_ATTRIBUTE,
  SET_ADD_ATTRIBUTE,
  SET_UPDATE_ATTRIBUTE,
  SET_DELETE_ATTRIBUTE,
  SET_GET_ATTRIBUTE_VALUE,
  SET_ADD_ATTRIBUTE_VALUE,
  SET_DELETE_ATTRIBUTE_VALUE,
  SET_UPDATE_ATTRIBUTE_VALUE,
  SET_BESURETOFIT_TEGS,
  SET_UPDATE_ORDER_DETAILS,
  SET_ADD_PAGE,
  GET_ADD_PAGE,
  SET_UPDATE_PAGE,
  SET_DELETE_PAGE,
  ADD_ABOUT_DATA,
  SET_UPDATE_FEEDBACK,
  VIEW_USER_CATEGORY,
  ADD_USER_CATEGORY,
  UPDATE_USER_CATEGORY,
  SINGLE_USER_CATEGORY,
  DELETE_USER_CATEGORY,
  SET_GET_SUBCATEGORY,
  GET_USER_CATEGORY,
  SET_ADD_SUBCATEGORY,
  SET_UPDATE_SUBCATEGORY,
  SET_VIEW_SUBCATEGORY,
  SET_DELETE_SUBCATEGORY,
} from "./types";

import { adminApi, api, apis, productApi } from "./axios";
import { toast } from "react-toastify";
import axios from "axios";

let token;
try {
  token1 = localStorage.getItem("adminToken");
} catch (err) {
  token = "default value";
}
const token1 = localStorage.getItem("adminToken");
const isAuthenticated = localStorage.getItem("adminToken");

const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};

export const setBtnLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_BTN_LOADING,
    payload: data,
  });
};

adminApi.interceptors.request.use(
  (config) => {
    const adminToken = localStorage.getItem("adminToken");
    if (adminToken) {
      config.headers["Authorization"] = `Bearer ${adminToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

productApi.interceptors.request.use(
  (config) => {
    const adminToken = localStorage.getItem("adminToken");
    if (adminToken) {
      config.headers["Authorization"] = `Bearer ${adminToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apis.interceptors.request.use(
  (config) => {
    const adminToken = localStorage.getItem("adminToken");
    if (adminToken) {
      config.headers["Authorization"] = `Bearer ${adminToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const onLogout = (navigate) => async (dispatch) => {
  await adminApi
    .get("/admin-logout", config)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/");
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

const bases = axios.create({
  baseURL: `https://backend.healwithscience.ai/api/admin`,
  // withCredentials: true,
  // Authorization: `Bearer ${token1}`,
});

export const onAdminLogin = (data, navigate) => async (dispatch) => {
  await bases
    .post("/admin-login", data, {
      withCredentials: true,
    })
    .then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: SET_GET_ADMIN_DATA,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.setItem("adminToken", res?.data?.token);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getAllUserData = (searchParams) => async (dispatch) => {
  if (token1) {
    await apis
      .get(`/getAllUserList?${searchParams ? searchParams : ""}`, {
        headers: {
          Authorization: `Bearer ${token1}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: SET_GET_USERS_LIST,
            payload: res?.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }
};
export const getHealthExpertDetails = (searchParams) => async (dispatch) => {
  await apis
    .get(`/getAllHealthExpertList?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: GET_HEALTHEXPERT_DETAILS,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getCustumerData = (searchParams) => async (dispatch) => {
  await apis
    .get(`/getAllCustomerExpertList?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: GET_CUSTUMER_DETAILS,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const AddUsers = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/create-user", data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: SET_CREATE_USER,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/users");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const UpdateUsers = (id, data, navigate, type) => async (dispatch) => {
  await adminApi
    .patch(`/updateUser/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: SET_UPDATE_USER,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          if (type.type == "customer") {
            navigate("/Customer");
          } else if (type.type == "user") {
            navigate("/users");
          } else if (type.type == "HealthExpert") {
            navigate("/health-expert");
          } else {
            navigate("/Customer");
          }
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};
export const updatefeedback =
  (id, data, navigate, type) => async (dispatch) => {
    await productApi.patch(`/updateFeedback/${id}`, data).then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: SET_UPDATE_FEEDBACK,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

export const getSingleUser = (id) => async (dispatch) => {
  await apis
    .get(`/getSingleUserData/${id}`, {
      Authorization: `Bearer ${token1}`,
    })
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_GET_SINGEL_USER_DETAILS,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteUser = (id, close) => async (dispatch) => {
  await adminApi.delete(`/deleteUser/${id}`).then((res) => {
    if (res.status === 201) {
      dispatch({
        type: USER_DELETE,
        payload: res?.data,
      });
      toast.info(res?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      close(true);
    }
  });
};

export const GetShopByCategory = (searchParams) => async (dispatch) => {
  await adminApi
    .get(`/getShopByCategory?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: GET_SHOPBY_CATEGORY_DATA,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const CreateCategory = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/addProductCategory", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_CREATE_CATEGORY,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/products/product-categories");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getProductCategory = (searchParams) => async (dispatch) => {
  await adminApi
    .get(`/getProductCategory?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: GET_PRODUCT_CATEGORY,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteCategory = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteProductCategory/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: DELETE_PRODUCT_CATEGORY,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const updateCategory = (id, data, navigate) => async (dispatch) => {
  await adminApi
    .patch(`/updateProductCategory/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: SET_UPDATE_USER,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/products/product-categories");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getSingleProductCategoryDetails = (id) => async (dispatch) => {
  await adminApi
    .get(`/getSingleProductCategory/${id}`)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: GET_SINGEL_PRODUCT_CATEGORY_DETAILS,
          payload: res.data.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getBlogData = (searchParams) => async (dispatch) => {
  await adminApi
    .get(`/getBlog?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: GET_BLOG_DATA,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getUserCategory = (searchParams) => async (dispatch) => {
  await apis
    .get(`/getUserCategory?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: GET_USER_CATEGORY,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const CreateBlog = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/addBlog", data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: ADD_BLOG_DATA,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/blog");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteBlogDetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteBlog/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: DELETE_BLOG_DATA,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const UpdateBlogData = (id, data, navigate) => async (dispatch) => {
  await adminApi
    .patch(`/updateBlog/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: SET_UPDATE_BOLG,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/blog");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const updateUserCategory = (id, data, navigate) => async (dispatch) => {
  await apis
    .patch(`/updateUserCategory/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: UPDATE_USER_CATEGORY,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/category");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getFeedback = () => async (dispatch) => {
  await productApi
    .get(`/getFeedback`)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: GET_FEEDBACK,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const GetCouponList = (searchParams) => async (dispatch) => {
  await adminApi
    .get(`/getCoupon?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: GET_COUPON,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const CreateCoupons = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/createCoupon", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: CREATE_COUPONS,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/coupon");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const UpdateCoupons = (id, data, navigate) => async (dispatch) => {
  await adminApi
    .patch(`/updateCoupon/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: UPDATE_COUPONS,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/coupon");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const ViewCouponDetailsList = (id) => async (dispatch) => {
  await adminApi
    .get(`/getCouponById/${id}`, {})
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: VIEW_COUPONS,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteCouponDetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteCoupon/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: DELETE_COUPONS,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const ViewBlogDetailsList = (id) => async (dispatch) => {
  await adminApi
    .get(`/getBlogById/${id}`, {})
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: VIEW_BOLG,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const viewUserCategory = (id) => async (dispatch) => {
  await apis
    .get(`/getUserCategoryById/${id}`, {})
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SINGLE_USER_CATEGORY,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const viewUserSubCateory = (id) => async (dispatch) => {
  await apis
    .get(`/getUserSubCategoryById/${id}`, {})
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_VIEW_SUBCATEGORY,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const GetEmailTemplateList = (searchParams) => async (dispatch) => {
  await adminApi
    .get(`/getEmailTemplates?${searchParams}`)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: GET_EMAIL_TEMPLATE,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const AddEmailTemplateData = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/addEmailTemplates", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: CREATE_EMAIL_TEMPLATE,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/email-template");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const UpdateEmailTemplateData =
  (id, data, navigate) => async (dispatch) => {
    await adminApi
      .patch(`/updateEmailTemplate/${id}`, data)
      .then((res) => {
        if (res?.status === 201) {
          dispatch({
            type: UPDATE_EMAIL_TEMPLATE,
            payload: res.data,
          });
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            navigate("/email-template");
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

export const ViewEmailTemplateDetailsList = (id) => async (dispatch) => {
  await adminApi
    .get(`/getEmailTemplatesById/${id}`, {})
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: VIEW_EMAIL_TEMPLATE,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteEmailTemplateDetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteEmailTemplate/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: DELETE_EMAIL_TEMPLATE,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getDietPlandata = (searchParams) => async (dispatch) => {
  await adminApi
    .get(`/getDeit?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: GET_DIET_PLAN,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const ViewSingelDietPlanDetailsList = (id) => async (dispatch) => {
  await adminApi
    .get(`/getDeitById/${id}`, {})
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: VIEW_DIET_PLAN,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const UpdateDietPlanData = (id, data, navigate) => async (dispatch) => {
  await adminApi
    .patch(`/updateDeit/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: UPDATE_DIET_PLAN,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/diet-plan");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteDietPlanDetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteDeit/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: DELETE_DIET_PLAN,
          payload: res?.data.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const AddDietPlanData = (data, navigate) => async (dispatch) => {
  await adminApi
    .post(`/addDeit`, data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: ADD_DIET_PLAN,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/diet-plan");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const getSettingsdetails = () => async (dispatch) => {
  await adminApi
    .get(`/getSetting`)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: GET_SETTINGS_DATA,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const addSettings = (data) => async (dispatch) => {
  await adminApi
    .post("/setSetting", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: ADD_SETTINGS_DATA,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const GetProductsList = (searchParams) => async (dispatch) => {
  await productApi
    .get(`/getProductList?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: GET_PRODUCTS,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const CreateProducts = (data, navigate) => async (dispatch) => {
  //.log(data);
  await productApi
    .post("/add-product", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: CREATE_PRODUCTS,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/product");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const viewProductsbyid = (id) => (dispatch) => {
  productApi
    .get(`/getProduct/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: VIEW_PRODUCTS,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteProductDetails = (id, close) => async (dispatch) => {
  await productApi
    .delete(`/deleteProduct/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: DELETE_PRODUCTS,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const deleteUserSubCategory = (id, close) => async (dispatch) => {
  await apis
    .delete(`/deleteUserSubCategory/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_DELETE_SUBCATEGORY,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const UpdateProduct = (id, data, navigate) => async (dispatch) => {
  await productApi
    .patch(`/updateProductByCtegory/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: UPDATE_PRODUCTS,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/product");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getSingleFeedback = (id) => async (dispatch) => {
  await productApi
    .get(`/getFeedbackById/${id}`, {})
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: VIEW_FEEDBACK,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getOrderList = (searchParams) => async (dispatch) => {
  await productApi
    .get(`/getAllOrderList?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: GET_ORDER_LIST,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const viewShopbyCategory = (id) => async (dispatch) => {
  await adminApi
    .get(`/getShopByCategoryById/${id}`, {})
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: VIEW_SHOPBY_CATEGORY_DATA,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteShopbycategorydetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteShopByCategory/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_DELETE_SHOPBY_CATEGORY_DATA,
          payload: res?.data.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const CreateAddShopbycategory = (data, navigate) => async (dispatch) => {
  await adminApi
    .post(`/addShopByCategory`, data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: CREATE_SHOPBY_CATEGORY_DATA,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });

        navigate("/shop-and-category");
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const UpdateShopbycategorydetailsdata =
  (id, data, navigate) => async (dispatch) => {
    await adminApi
      .patch(`/updateShopByCategory/${id}`, data)
      .then((res) => {
        if (res?.status === 201) {
          dispatch({
            type: SET_UPDATE_SHOPBY_CATEGORY_DATA,
            payload: res.data,
          });
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });

          navigate("/shop-and-category");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

export const ViewViewOrderDetailsdata = (id) => async (dispatch) => {
  await productApi
    .get(`/getOrderById/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_VIEW_ORDER_DETAILS,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteOrderDetails = (id, close) => async (dispatch) => {
  await productApi
    .delete(`/deleteOrder/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_DELETE_ORDER_DETAILS,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const GettagsList = (searchParams) => async (dispatch) => {
  await adminApi
    .get(`/getTag?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_GET_TEGS,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const CreateTags = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/addTag", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_CREATE_TEGS,
          payload: res?.data,
        });

        setTimeout(() => {
          navigate("/products/tags");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const UpdateTagsdata = (id, data, navigate) => async (dispatch) => {
  await adminApi
    .patch(`/updateTag/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
          type: SET_UPDATE_SHOPBY_CATEGORY_DATA,
          payload: res.data,
        });

        setTimeout(() => {
          navigate("/products/tags");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteTagsDetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteTag/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_DELETE_TEGS,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const deleteUserCategory = (id, close) => async (dispatch) => {
  await apis
    .delete(`/deleteUserCategory/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: DELETE_USER_CATEGORY,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const GetAttribtuteListData = (searchParams) => async (dispatch) => {
  await adminApi
    .get(`/getAtributte?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_GET_ATTRIBUTE,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const CreateAttribute = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/addAtributte", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_ADD_ATTRIBUTE,
          payload: res?.data,
        });

        setTimeout(() => {
          navigate("/products/attributes");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const addUserCategory = (data, navigate) => async (dispatch) => {
  await apis
    .post("/createUserCategory", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: ADD_USER_CATEGORY,
          payload: res?.data,
        });

        setTimeout(() => {
          navigate("/category");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const Updateattributedata = (id, data, navigate) => async (dispatch) => {
  await adminApi
    .patch(`/updateAtributte/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: SET_UPDATE_ATTRIBUTE,
          payload: res.data,
        });

        setTimeout(() => {
          navigate("/products/attributes");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteAttributeDetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteAtributte/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_DELETE_ATTRIBUTE,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const CreateAttributeValue = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/addAtributteValue", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_ADD_ATTRIBUTE_VALUE,
          payload: res?.data,
        });

        setTimeout(() => {
          navigate("/products/attribute-values");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const createSubcategory = (data, navigate) => async (dispatch) => {
  await apis
    .post("/createUserSubCategory", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_ADD_SUBCATEGORY,
          payload: res?.data,
        });

        setTimeout(() => {
          navigate("/sub-category");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const updateuserSubcategory =
  (id, data, navigate) => async (dispatch) => {
    await apis
      .patch(`/updateUserSubCategory/${id}`, data)
      .then((res) => {
        if (res?.status === 201) {
          dispatch({
            type: SET_UPDATE_SUBCATEGORY,
            payload: res.data,
          });
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });

          setTimeout(() => {
            navigate("/sub-category");
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

export const GetAttribtuteValueListData =
  (searchParams) => async (dispatch) => {
    await adminApi
      .get(`/getAtributteValue?${searchParams ? searchParams : ""}`)
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: SET_GET_ATTRIBUTE_VALUE,
            payload: res?.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

export const getSubCategoryData = (searchParams) => async (dispatch) => {
  await apis
    .get(`/getUserSubCategory?${searchParams ? searchParams : ""}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_GET_SUBCATEGORY,
          payload: res?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const DeleteAttributevalueDetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deleteAtributteValue/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_DELETE_ATTRIBUTE_VALUE,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          theme: "colored",
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const UpdateAttributeValuesList =
  (id, data, navigate) => async (dispatch) => {
    await adminApi
      .patch(`/updateAtributteValue/${id}`, data)
      .then((res) => {
        if (res?.status === 201) {
          dispatch({
            type: SET_UPDATE_ATTRIBUTE_VALUE,
            payload: res.data,
          });
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            navigate("/products/attribute-values");
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
        });
      });
  };

export const createBesureTofit = (data, navigate) => async (dispatch) => {
  await adminApi
    .post("/addFit", data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_BESURETOFIT_TEGS,
          payload: res?.data,
        });

        setTimeout(() => {
          navigate("/fit");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const updateOrder = (id, data, navigate) => async (dispatch) => {
  await productApi
    .patch(`updateOrder/${id}`, data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_UPDATE_ORDER_DETAILS,
          payload: res.data,
        });
        setTimeout(() => {
          navigate("/order");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const createPage = (data, navigate) => async (dispatch) => {
  await adminApi
    .post(`/addPages`, data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_ADD_PAGE,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/pages");
        }, 1000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const getpageList = () => async (dispatch) => {
  await adminApi
    .get(`/getPages`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: GET_ADD_PAGE,
          payload: res?.data?.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const updatePageData = (id, data, navigate) => async (dispatch) => {
  await adminApi
    .patch(`/updatePages/${id}`, data)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: SET_UPDATE_PAGE,
          payload: res.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });

        setTimeout(() => {
          navigate("/pages");
        }, 2000);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const DeletePageDetails = (id, close) => async (dispatch) => {
  await adminApi
    .delete(`/deletePages/${id}`)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: SET_DELETE_PAGE,
          payload: res?.data,
        });
        toast.info(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          theme: "colored",
        });
        close(true);
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const addAboutUs = (data) => async (dispatch) => {
  await adminApi
    .post(`/addAbout`, data)
    .then((res) => {
      if (res.status === 201) {
        dispatch({
          type: ADD_ABOUT_DATA,
          payload: res?.data,
        });
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000,
        });
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
      });
    });
};

export const getDashboardDetails = () => async (dispatch) => {
  await adminApi.get("/admin-dashboard").then((res) => {
    if (res.status === 200) {
    }
  });
};
