import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ViewBlogDetailsList, viewUserCategory } from "../../redux/homeAction";

const UserCategorysinglePage = () => {
  const singleUserCategoryDetails = useSelector(
    (state) => state.home.singleUserCategoryDetails
  );
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewUserCategory(id));
  }, []);

  return (
    <>
      <Header />

      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">User category Details</div>

            <div className="userinfodiv">
              {/* <h4>basic information</h4> */}

              <div className="infobox_wrapper w-100">
                <div className="infobox w-100">
                  <h3>title</h3>
                  <p>{singleUserCategoryDetails?.title || "--"}</p>
                </div>

                <div className="infobox w-100 overflow-y-auto">
                  <h3>Description</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${singleUserCategoryDetails?.description}`,
                    }}
                  ></p>
                </div>
              </div>

              <Link to={"/category"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCategorysinglePage;
