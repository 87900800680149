// Bioveda admin apis types

// admin login typs

export const SET_LOADING = "SET_LOADING";
export const SET_BTN_LOADING = "SET_BTN_LOADING";

export const SET_GET_ADMIN_DATA = "SET_GET_ADMIN_DATA";

// logout
export const SET_LOGOUT = "SET_LOGOUT";

// users--------------------------------------
export const SET_GET_USERS_LIST = "SET_GET_USERS_LIST";
export const SET_CREATE_USER = "SET_CREATE_USER";
export const SET_GET_SINGEL_USER_DETAILS = "SET_GET_SINGEL_USER_DETAILS";

export const SET_UPDATE_USER = "SET_UPDATE_USER";
export const USER_DELETE = "USER_DELETE";
export const GET_HEALTHEXPERT_DETAILS = "GET_HEALTHEXPERT_DETAILS";
export const GET_CUSTUMER_DETAILS = "GET_CUSTUMER_DETAILS";

// shopp---------------------------------
export const GET_SHOPBY_CATEGORY_DATA = "GET_SHOPBY_CATEGORY_DATA";
export const CREATE_SHOPBY_CATEGORY_DATA = "CREATE_SHOPBY_CATEGORY_DATA";
export const VIEW_SHOPBY_CATEGORY_DATA = "VIEW_SHOPBY_CATEGORY_DATA";
export const SET_DELETE_SHOPBY_CATEGORY_DATA =
  "SET_DELETE_SHOPBY_CATEGORY_DATA";
export const SET_UPDATE_SHOPBY_CATEGORY_DATA =
  "SET_UPDATE_SHOPBY_CATEGORY_DATA";

// Products
export const GET_PRODUCTS = "GET_PRODUCTS";
export const CREATE_PRODUCTS = "CREATE_PRODUCTS";
export const VIEW_PRODUCTS = "VIEW_PRODUCTS";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";

// tags
export const SET_GET_TEGS = "SET_GET_TEGS";
export const SET_CREATE_TEGS = "SET_CREATE_TEGS";
export const SET_UPDATE_TEGS = "SET_UPDATE_TEGS";
export const SET_DELETE_TEGS = "SET_DELETE_TEGS";

// be sure to fit
export const SET_BESURETOFIT_TEGS = "SET_BESURETOFIT_TEGS";

// attribute
export const SET_GET_ATTRIBUTE = "SET_GET_ATTRIBUTE";
export const SET_ADD_ATTRIBUTE = "SET_ADD_ATTRIBUTE";
export const SET_UPDATE_ATTRIBUTE = "SET_UPDATE_ATTRIBUTE";
export const SET_DELETE_ATTRIBUTE = "SET_DELETE_ATTRIBUTE";

// attribute vallues
export const SET_GET_ATTRIBUTE_VALUE = "SET_GET_ATTRIBUTE_VALUE";
export const SET_ADD_ATTRIBUTE_VALUE = "SET_ADD_ATTRIBUTE_VALUE";
export const SET_DELETE_ATTRIBUTE_VALUE = "SET_DELETE_ATTRIBUTE_VALUE";
export const SET_UPDATE_ATTRIBUTE_VALUE = "SET_UPDATE_ATTRIBUTE_VALUE";

// category
export const SET_CREATE_CATEGORY = "SET_CREATE_CATEGORY";
export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";
export const GET_SINGEL_PRODUCT_CATEGORY_DETAILS =
  "GET_SINGEL_PRODUCT_CATEGORY_DETAILS";



  // Sub-category 
  export const SET_GET_SUBCATEGORY = "SET_GET_SUBCATEGORY"
  export const SET_ADD_SUBCATEGORY = "SET_ADD_SUBCATEGORY"
  export const SET_UPDATE_SUBCATEGORY = "SET_UPDATE_SUBCATEGORY"
  export const SET_VIEW_SUBCATEGORY = "SET_VIEW_SUBCATEGORY"
  export const SET_DELETE_SUBCATEGORY = "SET_DELETE_SUBCATEGORY"

// blog

export const GET_BLOG_DATA = "GET_BLOG_DATA";
export const ADD_BLOG_DATA = "ADD_BLOG_DATA";
export const DELETE_BLOG_DATA = "DELETE_BLOG_DATA";
export const SET_UPDATE_BOLG = "SET_UPDATE_BOLG";
export const VIEW_BOLG = "VIEW_BOLG";

// user category
export const VIEW_USER_CATEGORY = "VIEW_USER_CATEGORY";
export const GET_USER_CATEGORY = "GET_USER_CATEGORY";
export const ADD_USER_CATEGORY = "ADD_USER_CATEGORY";
export const UPDATE_USER_CATEGORY = "UPDATE_USER_CATEGORY";
export const SINGLE_USER_CATEGORY = "SINGLE_USER_CATEGORY";
export const DELETE_USER_CATEGORY = "DELETE_USER_CATEGORY";


// orders
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const SET_VIEW_ORDER_DETAILS = "SET_VIEW_ORDER_DETAILS";
export const SET_DELETE_ORDER_DETAILS = "SET_DELETE_ORDER_DETAILS";
export const SET_UPDATE_ORDER_DETAILS = "SET_UPDATE_ORDER_DETAILS";

// feedback

export const GET_FEEDBACK = "GET_FEEDBACK";
export const VIEW_FEEDBACK = "VIEW_FEEDBACK";
export const SET_UPDATE_FEEDBACK = "SET_UPDATE_FEEDBACK";

// coupon
export const GET_COUPON = "GET_COUPON";
export const CREATE_COUPONS = "CREATE_COUPONS";
export const UPDATE_COUPONS = "UPDATE_COUPONS";
export const VIEW_COUPONS = "VIEW_COUPONS";
export const DELETE_COUPONS = "DELETE_COUPONS";

// email Template
export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const CREATE_EMAIL_TEMPLATE = "CREATE_EMAIL_TEMPLATE";
export const VIEW_EMAIL_TEMPLATE = "VIEW_EMAIL_TEMPLATE";
export const UPDATE_EMAIL_TEMPLATE = "UPDATE_EMAIL_TEMPLATE";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";

// Diet plan
export const ADD_DIET_PLAN = "ADD_DIET_PLAN";
export const GET_DIET_PLAN = "GET_DIET_PLAN";
export const VIEW_DIET_PLAN = "VIEW_DIET_PLAN";
export const DELETE_DIET_PLAN = "DELETE_DIET_PLAN";
export const UPDATE_DIET_PLAN = "UPDATE_DIET_PLAN";

// settings
export const GET_SETTINGS_DATA = "GET_SETTINGS_DATA";
export const ADD_SETTINGS_DATA = "ADD_SETTINGS_DATA";

// about
export const ADD_ABOUT_DATA = "ADD_ABOUT_DATA";

// pages
export const SET_ADD_PAGE = "SET_ADD_PAGE";
export const GET_ADD_PAGE = "GET_ADD_PAGE";
export const SET_UPDATE_PAGE = "SET_UPDATE_PAGE";
export const SET_DELETE_PAGE = "SET_DELETE_PAGE";

//
export const SET_POPUP_ACTIONS = "SET_POPUP_ACTIONS";
// -----------------------------------------------------//

export const SET_SIGNED_UP_MAIL = "SET_SIGNED_UP_MAIL";

export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_GET_LOGGED_DATA = "SET_GET_LOGGED_DATA";

export const SET_GET_USER_DATA = "SET_GET_USER_DATA";

export const SET_ALL_MY_EVENTS = "SET_ALL_MY_EVENTS";
export const SET_COUPONS_PRICEING = "SET_COUPONS_PRICEING";

// userProfile
export const SET_GET_PROFILE_DETAILS = "SET_GET_PROFILE_DETAILS";

export const SET_GET_DEVICES_LIST = "SET_GET_DEVICES_LIST";

export const SET_GET_BILLING_INFO = "SET_GET_BILLING_INFO";
export const SET_PREV_CARD_DETAILS = "SET_PREV_CARD_DETAILS";
export const SET_DELETE_CARD = "SET_DELETE_CARD";
export const SET_GET_NEW_CARD_ADD = "SET_GET_NEW_CARD_ADD";
export const SET_GET_UPDATED_CARD_INFO = "SET_GET_UPDATED_CARD_INFO";
export const SET_GET_CREATED_USER = "SET_GET_CREATED_USER";
export const SET_GET_DELETE_USER = "SET_GET_DELETE_USER";
