import React, { useState } from "react";
import Header from "../../../components/header/Header";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { addUserCategory } from "../../../redux/homeAction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handelAddCategory = async (e) => {
    e.preventDefault();
    const data = {
      title: values.title,
      description: values?.description,
    };
    dispatch(addUserCategory(data, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handelAddCategory}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Add Category</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser w-100 ">
                  <div className="input_fields_labels_adduser w-100 ">
                    <label> Title *</label>
                    <input
                      name="title"
                      type="text"
                      placeholder="title"
                      onChange={onInputChange}
                      value={values?.title}
                      required
                    />
                  </div>
                </div>
                <div className="w-100 mt-3">
                  <div className="input_fields_labels w-100 mb-5">
                    <label>Description *</label>
                    <ReactQuill
                      style={{ height: "200px" }}
                      modules={modules}
                      theme="snow"
                      value={values?.description}
                      onChange={(value) =>
                        setValues({ ...values, description: value })
                      }
                      placeholder="The content starts here..."
                      required
                    />
                  </div>
                </div>
              </div>
              <button className="save_btn mt-3" type="submit">
                save
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddCategory;
