import React, { useState } from "react";
import Header from "../../../components/header/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CreateBlog } from "../../../redux/homeAction";

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    if (e.target.name === "blog_image") {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
        blogImageShow: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const HandelCreateBlog = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("title", values?.title);
    formData.append("blog_image", values?.blog_image);
    formData.append("blog", values?.description);

    dispatch(CreateBlog(formData, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <div className="form_wrapper_adduser">
          <form action="" onSubmit={HandelCreateBlog}>
            <div className="header_from_user">Create Blog</div>
            <div className="">
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Title *</label>
                    <input
                      className="input_2"
                      name="title"
                      type="text"
                      placeholder="Title"
                      onChange={onInputChange}
                      value={values?.title}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>
                    <input
                      accept="image/*"
                      name="blog_image"
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      onChange={onInputChange}
                    />
                    <div className="w-25 mt-2">
                      <img
                        src={values?.blogImageShow}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_div_add_user mb-4">
              <div className="input_fields_labels w-100 mb-5">
                <label>Description *</label>
                <ReactQuill
                  style={{ height: "200px" }}
                  modules={modules}
                  theme="snow"
                  value={values?.description}
                  onChange={(value) =>
                    setValues({ ...values, description: value })
                  }
                  placeholder="The content starts here..."
                />
              </div>
            </div>

            <button className="save_btn mt-3" type="submit">
              save
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
