import React from "react";
import Header from "../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSingleFeedback } from "../../redux/homeAction";

const ViewFeedback = () => {
  const data = useSelector((state) => state.home.getviewfeedback);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleFeedback(id));
  }, []);

  return (
    <>
      <Header />
      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">Feedback Details</div>

            <div className="userinfodiv">
              {/* <h4>basic information</h4> */}

              <div className="infobox_wrapper">
                <div className="infobox">
                  <h3>customer id</h3>
                  <p>
                    {data?.customer_id?.first_name || "--"}{" "}
                    {data?.customer_id?.last_name || "--"}
                  </p>
                </div>
                <div className="infobox">
                  <h3>health Expert Name</h3>
                  <p>
                    {`${data?.healthExpertName?.first_name}`}{" "}
                    {`${data?.healthExpertName?.last_name}`}
                  </p>
                </div>

                <div className="infobox overflow-auto">
                  <h3>message</h3>
                  <p>{data?.message || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>rating</h3>
                  <p>{data?.rating || "--"}</p>
                </div>

                <div className="infobox  ">
                  <h3>approved</h3>
                  <p>{data?.approved === true ? "true" : "false" || "--"}</p>
                </div>
              </div>

              <Link to={"/feedback"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFeedback;
