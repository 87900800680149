import React, { useEffect, useState } from "react";
import "./notfound.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  const [check, setCheck] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (token) {
      setCheck(true);
    }
  });
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
          <h2>Page not found</h2>
        </div>
        {!check ? (
          <Link to={"/"}>Login</Link>
        ) : (
          <Link to={"/dashboard"}>Dashboard</Link>
        )}
      </div>
    </div>
  );
};

export default NotFound;
