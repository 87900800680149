import React from "react";
import Header from "../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { viewProductsbyid } from "../../redux/homeAction";

const ViewProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home.getProductsDetails);

  useEffect(() => {
    dispatch(viewProductsbyid(id));
  }, []);

  return (
    <>
      <Header />
      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">Product Details</div>

            <div className="userinfodiv">
              <div className="infobox_wrapper">
                <div className="infobox">
                  <h3>Title</h3>
                  <p>{data?.product_name || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Market price</h3>
                  <p>₹{`${data?.market_price}`}</p>
                </div>
                <div className="infobox">
                  <h3>Selling price</h3>
                  <p>₹{`${data?.selling_price}`}</p>
                </div>
                <div className="infobox">
                  <h3>Product image</h3>
                  <div className="w-25">
                    <img
                      src={data?.product_image}
                      alt="product img"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="infobox">
                  <h3>Gallery image</h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {data?.gallery_image?.map((image, key) => (
                      <div className="w-25 p-1">
                        <img
                          key={key}
                          src={image}
                          alt="gallery img"
                          className="img-fluid"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="infobox">
                  <h3>sku code</h3>
                  <p>{data?.sku_code || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>category name</h3>
                  <p>{data?.category_id?.category_name || "--"}</p>
                </div>

                <div className="infobox w-100 overflow-auto">
                  <h3>product description</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data?.product_description || "--",
                    }}
                  ></p>
                </div>
              </div>

              <Link to={"/product"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProduct;
