import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ViewBlogDetailsList } from "../../redux/homeAction";

const ProfilePage = () => {
  const viewBlogDetails = useSelector((state) => state.home.viewBlogDetails);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(ViewBlogDetailsList(id));
  }, []);

  return (
    <>
      <Header />

      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">Profile Details</div>

            <div className="userinfodiv">
              {/* <h4>basic information</h4> */}

              <div className="infobox_wrapper">
                <div className="infobox">
                  <h3>title</h3>
                  {/* <p>{viewBlogDetails?.title || "--"}</p> */}
                </div>

                <div className="infobox w-50">
                  <h3>Image</h3>
                  {/* <img src={viewBlogDetails?.blog_image} alt="blog img" /> */}
                </div>

                <div className="infobox w-100">
                  <h3>Description</h3>
                  {/* <p
                  dangerouslySetInnerHTML={{
                    __html: `${viewBlogDetails?.blog}`,
                  }}
                ></p> */}
                </div>
              </div>

              <Link to={"/blog"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
