import React, { useRef, useState } from "react";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  CreateAttributeValue,
  CreateTags,
  GetAttribtuteListData,
  createBesureTofit,
} from "../../redux/homeAction";
import { useEffect } from "react";

const BeSuretoFit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [inputList, setInputList] = useState([{ topics: "" }]);

  const onInputChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    if (name == "amount") {
      if (value.length <= 5) {
        list[index][name] = value ? Number(value) : "";
        if (Number(value) < 3) {
          // setErrors({
          //   ...errors,
          //   [e.target.name]: "Minmum bid amount is $3",
          // });
        } else if (index == inputList.length - 1) {
          // setErrors({
          //   ...errors,
          //   [e.target.name]: false,
          // });
        }
      }
    } else {
      list[index][name] = value;
    }
    setInputList(list);
    // setErrors({
    //   ...errors,
    //   [`${e.target.name}${index}`]: false,
    // });
  };

  const deleteItem = (index) => {
    let updatedInputList = [...inputList];
    updatedInputList.splice(index, 1);
    setInputList(updatedInputList);
  };

  const addItem = () => {
    setInputList([
      ...inputList,
      {
        topics: "",
      },
    ]);
  };
  const topicstring = inputList.map((item) => item?.topics);

  const handelAddAttributeVAlue = async (e) => {
    e.preventDefault();
    const data = {
      title: values.title,
      topic: Object.values(topicstring)
        .map((item) => item)
        .toString(),
    };
    dispatch(createBesureTofit(data, navigate));
  };
  useEffect(() => {
    dispatch(GetAttribtuteListData());
  }, []);

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handelAddAttributeVAlue}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Be sure to fit</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser w-100 ">
                  <div className="input_fields_labels_adduser w-100">
                    <label>title *</label>
                    <input
                      name={`title`}
                      type="text"
                      onChange={onInputChange}
                      value={values.title}
                    />
                  </div>
                </div>

                {inputList?.map((item, index) => (
                  <div className="input_fields_wrapper_adduser  ">
                    <div className="input_fields_labels_adduser ">
                      <label>topic *</label>
                      <input
                        name={`topics`}
                        id={`topics${index}`}
                        type="text"
                        placeholder="topics"
                        onChange={(e) => handleInputChange(e, index)}
                        value={item?.topics}
                      />
                    </div>

                    <div className="d-flex gap-2">
                      <button onClick={addItem} className="success_btnnn">
                        Add
                      </button>
                      <button
                        className="fail_btnnn"
                        onClick={() => deleteItem(index)}
                      >
                        remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <button className="save_btn" type="submit">
                save
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default BeSuretoFit;
