import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { DeleteShopbycategorydetails } from "../../redux/homeAction";

const DeleteShopBCate = (props) => {
  const dispatch = useDispatch();

  const handelDeleteShopbyCate = () => {
    dispatch(DeleteShopbycategorydetails(props.userId, props.onHide));
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontSize: "22px", fontWeight: "600" }}
            id="contained-modal-title-vcenter"
            className="text-black"
          >
            Delete Shop By category?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-black d-flex flex-column justify-content-center align-items-center ">
          {/* <h4>Centered Modal</h4> */}
          <p className="text-center">You want to delete?</p>
          <div className="d-flex justify-content-end gap-3">
            <Button onClick={handelDeleteShopbyCate} variant="danger">
              Delete
            </Button>
            <Button variant="secondary" onClick={props.onHide}>
              Close
            </Button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
</Modal.Footer> */}
      </Modal>
    </>
  );
};

export default DeleteShopBCate;
