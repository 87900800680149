import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";

import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  CreateTags,
  GetAttribtuteListData,
  UpdateTagsdata,
  Updateattributedata,
} from "../../redux/homeAction";

import { useDispatch, useSelector } from "react-redux";
import { GettagsList } from "../../redux/homeAction";

const AttributeUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const getattributelist = useSelector(
    (state) => state.home.getattributelist?.data
  );

  useEffect(() => {
    if (getattributelist) {
      for (let i = 0; i < getattributelist.length; i++) {
        if (getattributelist[i]._id === id) {
          setValues(getattributelist[i]);
        }
      }
    }
  }, [getattributelist]);

  const onInputChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleupdateTags = async (e) => {
    e.preventDefault();
    const data = {
      title: values.title,
      status: values.status,
    };
    dispatch(Updateattributedata(id, data, navigate));
  };

  useEffect(() => {
    dispatch(GetAttribtuteListData());
  }, []);
  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handleupdateTags}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Update Tag</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser w-100 ">
                  <div className="input_fields_labels_adduser w-100 ">
                    <label> Title *</label>
                    <input
                      name="title"
                      type="text"
                      placeholder="title"
                      onChange={onInputChange}
                      value={values?.title}
                    />
                  </div>
                </div>
              </div>
              <button className="save_btn" type="submit">
                save
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default AttributeUpdate;
