import React from "react";
import "./pagination.css";

const Pagination = ({ setFilterValues, productList, filterValues }) => {
  const paginationData = productList?.pagination;
  const totalPages = [];

  const handlePrev = () => {
    if (filterValues?.page > 1) {
      setFilterValues({ ...filterValues, page: filterValues?.page - 1 });
    }
  };

  const handleNext = () => {
    if (filterValues?.page < paginationData?.next?.page) {
      setFilterValues({ ...filterValues, page: filterValues?.page + 1 });
    }
  };

  const handleSelectPage = (number) => {
    setFilterValues({ ...filterValues, page: number });
  };

  for (let i = 1; i < paginationData?.total_pages + 1; i++) {
    totalPages.push(i);
  }

  return paginationData?.total_pages > 1 ? (
    <div className="pagination">
      <svg
        width="9"
        height="14"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="prev_btn"
        onClick={handlePrev}
        disabled={paginationData?.current_page == 1}
      >
        <path
          d="M-0.000558376 5.63528C-0.00142574 5.48697 0.0270157 5.33994 0.0831323 5.20265C0.139249 5.06537 0.221935 4.94052 0.326442 4.83528L4.82644 0.335277C5.03861 0.123104 5.32638 0.00390625 5.62644 0.00390625C5.9265 0.00390625 6.21427 0.123104 6.42644 0.335277C6.63861 0.54745 6.75781 0.835219 6.75781 1.13528C6.75781 1.43534 6.63861 1.7231 6.42644 1.93528L2.71044 5.63528L6.41044 9.33528C6.59149 9.55042 6.68549 9.82552 6.67395 10.1065C6.66242 10.3874 6.54618 10.6539 6.34811 10.8535C6.15003 11.053 5.88445 11.1713 5.6036 11.1849C5.32275 11.1986 5.04695 11.1067 4.83044 10.9273L0.330442 6.42728C0.119862 6.21734 0.000871658 5.93263 -0.000558376 5.63528Z"
          fill="#606e7a"
        />
      </svg>

      <ul>
        {totalPages?.map((number) => (
          <li
            className={
              number == paginationData?.current_page ? "active_list" : ""
            }
            onClick={() => handleSelectPage(number)}
          >
            <span className="page_number">{number}</span>
          </li>
        ))}
      </ul>

      <svg
        width="9"
        height="14"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="next_btn"
        onClick={handleNext}
        disabled={paginationData?.current_page == paginationData?.total_pages}
      >
        <path
          d="M7.64061 5.63528C7.64147 5.48697 7.61303 5.33994 7.55692 5.20265C7.5008 5.06537 7.41811 4.94052 7.31361 4.83528L2.81361 0.335277C2.60143 0.123104 2.31366 0.00390625 2.01361 0.00390625C1.86503 0.00390625 1.71791 0.03317 1.58065 0.0900267C1.44338 0.146883 1.31866 0.23022 1.21361 0.335277C1.10855 0.440335 1.02521 0.565056 0.968356 0.70232C0.911499 0.839584 0.882235 0.986703 0.882235 1.13528C0.882235 1.43534 1.00143 1.7231 1.21361 1.93528L4.92961 5.63528L1.22961 9.33528C1.04417 9.55058 0.946996 9.82802 0.957562 10.112C0.968129 10.3959 1.08566 10.6654 1.28658 10.8663C1.4875 11.0672 1.75696 11.1848 2.04091 11.1953C2.32486 11.2059 2.60231 11.1087 2.81761 10.9233L7.31761 6.42328C7.52428 6.21303 7.64025 5.9301 7.64061 5.63528Z"
          fill="#606e7a"
        />
      </svg>
    </div>
  ) : null;
};

export default Pagination;
