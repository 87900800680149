import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  UpdateCoupons,
  ViewCouponDetailsList,
  getProductCategory,
} from "../../redux/homeAction";
import { ToastContainer } from "react-toastify";
import Header from "../../components/header/Header";
import { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const UpdateCoupon = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({ category_id: [] });
  const getProductCategorydata = useSelector(
    (state) => state.home.getProductCategorydata?.data
  );
  const viewCouponsDetails = useSelector(
    (state) => state.home.viewCouponsDetails
  );
  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (id) {
      dispatch(ViewCouponDetailsList(id));
    }
  }, [id]);

  useEffect(() => {
    if (viewCouponsDetails) {
      setValues({
        ...viewCouponsDetails,
        selectKey: Date.now(),
        category_id: viewCouponsDetails?.category_id?.map((item, key) => ({
          value: item,
          label: item?.category_name,
        })),
      });
    }
  }, [viewCouponsDetails]);

  useEffect(() => {
    dispatch(getProductCategory());
  }, []);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    if (e.target.name === "isActive") {
      if (e.target.checked) {
        setValues({ ...values, [e.target.name]: "Active" });
      } else {
        setValues({ ...values, [e.target.name]: "In-Active" });
      }
    }
  };

  const handleSelectCategory = (value) => {
    setValues({ ...values, category_id: value });
  };

  const handelUpdateCoupons = () => {
    const data = {
      code: values?.code,
      description: values?.description,
      discountType: values?.discountType,
      discountValue: values?.discountValue,
      minPurchase: values?.minPurchase,
      maxUses: values?.maxUses,
      startDate: values?.startDate,
      endDate: values?.endDate,
      isActive: values?.isActive,
      usageCount: values?.usageCount,
      category_id: values?.category_id?.map((item) => item?.value),
    };
    dispatch(UpdateCoupons(id, data, navigate));
  };

  const optionList = getProductCategorydata?.map((item, key) => ({
    value: item?._id,
    label: item?.category_name,
  }));

  return (
    <div>
      <Header />

      <div className="adduser_main_wrapper">
        <div className="form_wrapper_adduser">
          <div className="header_from_user">Update Coupons</div>
          <div className="">
            {/* <h4 className="form_basicInfo">Basic Information</h4> */}
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels_adduser  ">
                  <label>Code*</label>
                  <input
                    name="code"
                    type="text"
                    placeholder="Enter coupon code"
                    onChange={onInputChange}
                    value={values?.code}
                  />
                </div>
                <div className="input_fields_labels_adduser ">
                  <label>Discount Type *</label>
                  <select
                    placeholder="discount type"
                    name="discountType"
                    onChange={onInputChange}
                    value={values?.discountType}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="percentage">percentage</option>
                    <option value="fixed">fixed</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form_div_add_user ">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels_adduser  ">
                  <label>Discount Value *</label>
                  <input
                    name="discountValue"
                    type="number"
                    placeholder="Discount Value"
                    onChange={onInputChange}
                    value={values?.discountValue}
                  />
                </div>
                <div className="input_fields_labels_adduser ">
                  <label>Min Purchase *</label>
                  <input
                    name="minPurchase"
                    type="text"
                    placeholder="Min Purchase"
                    onChange={onInputChange}
                    value={values?.minPurchase}
                  />
                </div>
              </div>
            </div>
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels_adduser  ">
                  <label>Valid From *</label>
                  <input
                    name="startDate"
                    type="date"
                    placeholder="Start Date"
                    onChange={onInputChange}
                    value={values?.startDate}
                  />
                </div>
                <div className="input_fields_labels_adduser ">
                  <label>Valid Upto *</label>
                  <input
                    name="endDate"
                    type="date"
                    placeholder="End Date"
                    onChange={onInputChange}
                    value={values?.istEndTime}
                  />
                </div>
              </div>
            </div>
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels_adduser  ">
                  <label>Max Count*</label>
                  <input
                    name="maxUses"
                    type="number"
                    placeholder="maxUses"
                    onChange={onInputChange}
                    value={values.maxUses}
                  />
                </div>
                <div className="input_fields_labels_adduser ">
                  <label>Usage Count*</label>
                  <input
                    name="usageCount"
                    type="number"
                    placeholder="usageCount"
                    onChange={onInputChange}
                    value={values.usageCount}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form_div_add_user">
            <div className="input_fields_labels w-100 ">
              <label>Active *</label>
              <div className="form-check d-flex align-items-center">
                <input
                  id="flexCheckChecked"
                  type="checkbox"
                  className="form-check-input"
                  name="isActive"
                  checked={values?.isActive === "Active" ? true : false}
                  onChange={onInputChange}
                />
                <label className="form-check-label" for="flexCheckChecked">
                  is Coupon active
                </label>
              </div>

              <div className="input_fields_labels_adduser  w-100">
                <label>Products Category*</label>

                <Select
                  key={values?.selectKey}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={values?.category_id}
                  onChange={(value) => handleSelectCategory(value)}
                  isMulti
                  options={optionList}
                />
              </div>
            </div>
          </div>
          <div className="form_div_add_user ">
            <div className="input_fields_labels w-100 ">
              <label>Description *</label>
              <textarea
                className=" w-100 text_area"
                rows="5"
                cols="50"
                maxlength="250"
                placeholder="Maximun 250 Character's"
                name="description"
                onChange={onInputChange}
                value={values?.description}
              />
            </div>
          </div>

          <button className="save_btn mt-3" onClick={handelUpdateCoupons}>
            save
          </button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default UpdateCoupon;
