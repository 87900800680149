import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUserCategory, updateUserCategory } from "../../../redux/homeAction";
import Header from "../../../components/header/Header";
import { ToastContainer } from "react-toastify";

const UpdateUserCategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [logErrors, setLogErrors] = useState({});
  const getAllUserCategoryDetailsdata = useSelector(
    (state) => state.home.getAllUserCategoryDetailsdata?.data
  );

  useEffect(() => {
    if (getAllUserCategoryDetailsdata) {
      for (let i = 0; i < getAllUserCategoryDetailsdata.length; i++) {
        if (getAllUserCategoryDetailsdata[i]?._id === id) {
          setValues(getAllUserCategoryDetailsdata[i]);
        }
      }
    }
  }, [getAllUserCategoryDetailsdata]);

  useEffect(() => {
    dispatch(getUserCategory());
  }, []);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setLogErrors({ ...logErrors, [e.target.name]: false });
  };

  const HandelUpdateBlog = async (e) => {
    e.preventDefault();

    const data = {
      title: values?.title,
      description: values?.description,
    };

    dispatch(updateUserCategory(id, data, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={HandelUpdateBlog}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Update User Category</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user w-100">
                <div className="input_fields_wrapper_adduser w-100  ">
                  <div className="input_fields_labels_adduser  w-100 ">
                    <label>Title *</label>
                    <input
                      className={` ${
                        logErrors.title ? "errorinput_2" : "input_2"
                      }`}
                      name="title"
                      type="text"
                      placeholder={` ${
                        logErrors.title ? "Enter Your Title" : "Title"
                      }`}
                      onChange={onInputChange}
                      value={values?.title}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form_div_add_user mb-4">
              <div className="input_fields_labels w-100 mb-5">
                <label>Description *</label>
                <ReactQuill
                  name="description"
                  className={` ${
                    logErrors.title ? "errorinput_3" : "input_text_editor"
                  }`}
                  style={{ height: "600px" }}
                  modules={modules}
                  theme="snow"
                  value={values?.description}
                  onChange={(value) =>
                    setValues({ ...values, description: value })
                  }
                />
              </div>
            </div>

            <button className="save_btn mt-3" type="submit">
              save
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default UpdateUserCategory;
