import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateCategory, getProductCategory } from "../../../redux/homeAction";

const UpdateCategoryProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const getProductCategorydata = useSelector(
    (state) => state.home.getProductCategorydata?.data
  );

  useEffect(() => {
    if (id) {
      dispatch(getProductCategory(id));
    }
  }, [id]);

  useEffect(() => {
    if (getProductCategorydata) {
      for (let i = 0; i < getProductCategorydata.length; i++) {
        if (getProductCategorydata[i]._id === id) {
          setValues(getProductCategorydata[i]);
        }
      }
    }
  }, [getProductCategorydata]);

  const onInputChange = (e) => {
    let newValue = e.target.value;
    setValues({ ...values, [e.target.name]: newValue });

    if (e.target.name === "categoryImage") {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
        show_image: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const HandelCreateCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("category_name", values?.category_name);
    if (values?.categoryImage) {
      formData.append("category_image", values?.categoryImage);
    }
    formData.append("category_description", values?.category_description);

    dispatch(updateCategory(id, formData, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
      [{ indent: "-1" }, { indent: "+1" }],
    ],
  };

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <div className="form_wrapper_adduser">
          <div className="header_from_user">Update Category</div>
          <div className="">
            {/* <h4 className="form_basicInfo">Basic Information</h4> */}
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser  w-100">
                <div className="input_fields_labels_adduser  w-100">
                  <label>Category Name *</label>
                  <input
                    name="category_name"
                    type="text"
                    placeholder="Title"
                    onChange={onInputChange}
                    value={values?.category_name}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form_div_add_user mt-3">
              <div className="input_fields_wrapper_adduser">
                <div className="input_fields_labels w-100">
                  <label htmlFor="formFileLg">
                    Image{" "}
                    <span className="text-danger">
                      (Only jpeg, png, jpg files allowed){" "}
                    </span>{" "}
                  </label>
                  <input
                    name="categoryImage"
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                    multiple
                    onChange={onInputChange}
                  />
                </div>
                <div className="w-25">
                  <img
                    src={
                      values?.show_image
                        ? values?.show_image
                        : values?.category_image
                    }
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form_div_add_user mb-4">
            <div className="input_fields_labels w-100 mb-5">
              <label>Category Description *</label>
              <ReactQuill
                name="category_description"
                style={{ height: "200px" }}
                modules={modules}
                theme="snow"
                value={values?.category_description}
                onChange={(value) =>
                  setValues({ ...values, category_description: value })
                }
                placeholder="The content starts here..."
              />
            </div>
          </div>

          <button className="save_btn mt-3" onClick={HandelCreateCategory}>
            save
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateCategoryProduct;
