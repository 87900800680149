import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import "../../form/form.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  UpdateDietPlanData,
  getCustumerData,
  getDietPlandata,
  getHealthExpertDetails,
} from "../../redux/homeAction";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const DietPlanUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [values, setValues] = useState({ selectKey: Date.now() });
  const HealthExpertLists = useSelector(
    (state) => state.home.getHealthExpertDetailsList
  );
  const getCustumersInfo = useSelector((state) => state.home.getCustumers);
  const getDietPlansdetailslist = useSelector(
    (state) => state.home.getDietPlansdetailslist?.data
  );

  useEffect(() => {
    if (getDietPlansdetailslist) {
      for (let i = 0; i < getDietPlansdetailslist.length; i++) {
        if (getDietPlansdetailslist[i]._id === id) {
          const getDietPlans = getDietPlansdetailslist[i].customer_id?.map(
            (item) => ({
              value: item?._id,
              label: item?.first_name,
            })
          );

          setValues({
            ...getDietPlansdetailslist[i],
            selectKey: Date.now(),
            customer_id: getDietPlans,
            health_expert_id: getDietPlansdetailslist[i]?.health_expert_id?._id,
          });
        }
      }
    }
  }, [getDietPlansdetailslist]);

  useEffect(() => {
    dispatch(getCustumerData());
    dispatch(getHealthExpertDetails());
    dispatch(getDietPlandata());
  }, []);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSelectCustomer = (value) => {
    setValues({ ...values, customer_id: value });
  };

  const handelCreateDietPlans = async (e) => {
    e.preventDefault();

    const data = {
      customer_id: values?.customer_id?.map((item) => item?.value),
      early_morning_meds: values?.early_morning_meds,
      breakfast: values?.breakfast,
      snack: values?.snack,
      lunch: values?.lunch,
      after_lunch_meds: values?.after_lunch_meds,
      evening: values?.evening,
      before_dinner_meds: values?.before_dinner_meds,
      dinner: values?.dinner,
      after_meal_meds: values?.after_meal_meds,
      select_day: values?.select_day,
      health_expert_id: values?.health_expert_id,
    };

    dispatch(UpdateDietPlanData(id, data, navigate));
  };

  useEffect(() => {
    dispatch(getHealthExpertDetails());
    dispatch(getCustumerData());
  }, []);

  const optionList = getCustumersInfo?.data?.map((item, key) => ({
    value: item._id,
    label: item.first_name,
  }));

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handelCreateDietPlans}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Update Diet Plan</div>
            <div className="">
              <div className="form_div_add_user mt-2">
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels_adduser">
                    <label>user*</label>

                    <Select
                      key={values?.selectKey}
                      name="customer_id"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={values?.customer_id}
                      onChange={(value) => handleSelectCustomer(value)}
                      isMulti
                      options={optionList}
                    />
                  </div>

                  <div className="input_fields_labels_adduser  ">
                    <label>Health Expert *</label>
                    <select
                      className="input_2"
                      placeholder="discount type"
                      name="health_expert_id"
                      onChange={onInputChange}
                      value={values?.health_expert_id}
                    >
                      {HealthExpertLists?.data?.map((item, key) => (
                        <option key={key} value={item?._id}>
                          {item?.first_name} {item?.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_div_add_user mt-2">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Select Day *</label>
                    <select
                      className="input_2"
                      placeholder="discount type"
                      name="select_day"
                      onChange={onInputChange}
                      value={values?.select_day}
                    >
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_div_add_user mt-2">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Early morning meds *</label>
                    <input
                      className="input_2"
                      name="early_morning_meds"
                      type="text"
                      placeholder="Enter Early morning meds"
                      onChange={onInputChange}
                      value={values?.early_morning_meds}
                    />
                  </div>
                  <div className="input_fields_labels_adduser ">
                    <label>Breakfast *</label>
                    <input
                      name="breakfast"
                      className="input_2"
                      type="text"
                      placeholder="Breakfast"
                      onChange={onInputChange}
                      value={values?.breakfast}
                    />
                  </div>
                </div>
              </div>

              <div className="form_div_add_user ">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Snack *</label>
                    <input
                      name="snack"
                      type="text"
                      className="input_2"
                      placeholder="snack"
                      onChange={onInputChange}
                      value={values?.snack}
                    />
                  </div>
                  <div className="input_fields_labels_adduser ">
                    <label>lunch *</label>
                    <input
                      name="lunch"
                      type="text"
                      className="input_2"
                      placeholder="masala oats"
                      onChange={onInputChange}
                      value={values?.lunch}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form_div_add_user ">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels_adduser  ">
                  <label>after lunch meds *</label>
                  <input
                    name="after_lunch_meds"
                    type="text"
                    className="input_2"
                    placeholder="Rice & Curry"
                    onChange={onInputChange}
                    value={values?.after_lunch_meds}
                  />
                </div>
                <div className="input_fields_labels_adduser ">
                  <label>evening *</label>
                  <input
                    name="evening"
                    type="text"
                    className="input_2"
                    placeholder="masala oats"
                    onChange={onInputChange}
                    value={values?.evening}
                  />
                </div>
              </div>
            </div>
            <div className="form_div_add_user ">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels_adduser  ">
                  <label>before dinner meds *</label>
                  <input
                    name="before_dinner_meds"
                    type="text"
                    className="input_2"
                    placeholder="Rice & Curry"
                    onChange={onInputChange}
                    value={values?.before_dinner_meds}
                  />
                </div>
                <div className="input_fields_labels_adduser ">
                  <label>dinner *</label>
                  <input
                    name="dinner"
                    type="text"
                    className="input_2"
                    placeholder="masala oats"
                    onChange={onInputChange}
                    value={values?.dinner}
                  />
                </div>
              </div>
            </div>
            <div className="form_div_add_user ">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels_adduser  ">
                  <label>After meal meds *</label>
                  <input
                    name="after_meal_meds"
                    type="text"
                    className="input_2"
                    placeholder="Rice & Curry"
                    onChange={onInputChange}
                    value={values?.after_meal_meds}
                  />
                </div>
              </div>
            </div>

            <button className="save_btn mt-3" type="submit">
              save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DietPlanUpdate;
