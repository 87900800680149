import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  CreateBlog,
  getpageList,
  updatePageData,
} from "../../redux/homeAction";
import { ToastContainer } from "react-toastify";
import AboutUs from "../../pages/aboutUs/AboutUs";

const PageUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [pageDescription, setPageDescription] = useState({});
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [logErrors, setLogErrors] = useState({});
  const getpagesList = useSelector((state) => state.home.getpagesList);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (getpagesList) {
      for (let i = 0; i < getpagesList.length; i++) {
        if (getpagesList[i]._id === id) {
          setValues(getpagesList[i]);
          setPageDescription(getpagesList[i]);
        }
      }
    }
    setUserId(id);
  }, [getpagesList]);

  const onInputChange = (e) => {
    // let newValue = e.target.value;
    setValues({ ...values, [e.target.name]: e.target.value });
    setLogErrors({ ...logErrors, [e.target.name]: false });

    if (e.target.name === "blog_img") {
      setValues({ ...values, [e.target.name]: e.target.files[0] });
    }
  };

  const HandelCreateBlog = async (e) => {
    e.preventDefault();

    const data = {
      title: values.title,
      description: pageDescription.description,
    };
    dispatch(updatePageData(id, data, navigate));
  };
  useEffect(() => {
    dispatch(getpageList());
  }, []);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: ["red", "blue"] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  return (
    <>
      <Header />

      {id === "65685acad9da59bb37dad2ed" ? (
        <AboutUs userId={userId} />
      ) : (
        <div className="adduser_main_wrapper">
          <div className="form_wrapper_adduser">
            <form action="" onSubmit={HandelCreateBlog}>
              <div className="header_from_user">Update Pages</div>
              <div className="">
                {/* <h4 className="form_basicInfo">Basic Information</h4> */}
                <div className="form_div_add_user">
                  <div className="input_fields_wrapper_adduser  ">
                    <div className="input_fields_labels_adduser  w-100">
                      <label>Title *</label>
                      <input
                        className={` ${
                          logErrors.title ? "errorinput_2" : "input_2"
                        }`}
                        name="title"
                        type="text"
                        placeholder={` ${
                          logErrors.title ? "Enter Your Title" : "Title"
                        }`}
                        onChange={onInputChange}
                        value={values?.title}
                        required
                      />
                    </div>
                    {/* <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>
                    <input
                      name="blog_img"
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      onChange={onInputChange}
                      required
                    />
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="form_div_add_user mb-4">
                <div className="input_fields_labels w-100 mb-5">
                  <label>Description *</label>
                  <ReactQuill
                    name="description"
                    style={{ height: "400px" }}
                    modules={modules}
                    theme="snow"
                    value={pageDescription?.description}
                    onChange={(value) =>
                      setPageDescription({
                        ...pageDescription,
                        description: value,
                      })
                    }
                    placeholder="The content starts here..."
                    required
                  />
                </div>
              </div>

              <button className="save_btn mt-3" type="submit">
                save
              </button>
            </form>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default PageUpdate;
