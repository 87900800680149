import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ViewEmailTemplateDetailsList,
  ViewSingelDietPlanDetailsList,
} from "../../redux/homeAction";

const ViewDietPlanDetails = () => {
  const data = useSelector((state) => state.home.viewDietSingelDetails);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ViewSingelDietPlanDetailsList(id));
  }, []);

  return (
    <>
      <Header />
      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">Diet Plan Details</div>

            <div className="userinfodiv">
              <div className="infobox_wrapper">
                <div className="infobox">
                  <h3>Health Expert</h3>
                  <p>
                    {`${data?.health_expert_id?.first_name} ${data?.health_expert_id?.last_name}` ||
                      "--"}
                  </p>
                </div>
                <div className="infobox">
                  <h3>Customer</h3>
                  <p>
                    {data?.customer_id?.map(
                      (Uinfo, key) => `${Uinfo?.first_name},`
                    )}
                  </p>
                </div>
                <div className="infobox">
                  <h3>Day</h3>
                  <p>{data?.select_day || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>Early morning meds</h3>
                  <p>{data?.early_morning_meds || "--"}</p>
                </div>
                <div className="infobox">
                  <h3>breakfast</h3>
                  <p>{data?.breakfast || "--"}</p>
                </div>

                <div className="infobox ">
                  <h3>snack</h3>
                  <p>{data?.snack || "--"}</p>
                </div>
                <div className="infobox ">
                  <h3>lunch</h3>
                  <p>{data?.lunch || "--"}</p>
                </div>
                <div className="infobox ">
                  <h3>After lunch meds</h3>
                  <p>{data?.after_lunch_meds || "--"}</p>
                </div>
                <div className="infobox ">
                  <h3>evening </h3>
                  <p>{data?.evening || "--"}</p>
                </div>
                <div className="infobox ">
                  <h3>before dinner meds</h3>
                  <p>{data?.before_dinner_meds || "--"}</p>
                </div>
                <div className="infobox ">
                  <h3>dinner</h3>
                  <p>{data?.dinner || "--"}</p>
                </div>
                <div className="infobox ">
                  <h3>After meal meds *</h3>
                  <p>{data?.after_meal_meds || "--"}</p>
                </div>
              </div>

              <Link to={"/diet-plan"}>
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDietPlanDetails;
