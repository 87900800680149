import React from "react";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import ReactQuill from "react-quill";
import { UpdateEmailTemplateData } from "../../redux/homeAction";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

const EmailTemplateUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});

  const [contant, setContant] = useState({});
  const [logErrors, setLogErrors] = useState({});

  const getEmailTemplateDetails = useSelector(
    (state) => state.home.getEmailTemplateDetails
  );

  useEffect(() => {
    if (getEmailTemplateDetails) {
      for (let i = 0; i < getEmailTemplateDetails.length; i++) {
        if (getEmailTemplateDetails[i]?._id === id) {
          setValues(getEmailTemplateDetails[i]);
          setContant(getEmailTemplateDetails[i]);
        }
      }
    }
  }, [getEmailTemplateDetails]);

  const onInputChange = (e) => {
    // let newValue = e.target.value;
    setValues({ ...values, [e.target.name]: e.target.value });

    // if (e.target.name === "blog_img") {
    //   setValues({ ...values, [e.target.name]: e.target.files[0] });
    // }
  };

  const HandelUpdateEmailTemplate = async (e) => {
    e.preventDefault();

    const data = {
      from_name: values.from_name,
      from_email: values.from_email,
      email_category: values.email_category,
      email_subject: values.email_subject,
      email_content: contant.email_content,
      status: values.SType,
    };
    dispatch(UpdateEmailTemplateData(id, data, navigate));
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={HandelUpdateEmailTemplate}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Update Email Template</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Form Name *</label>
                    <input
                      name="from_name"
                      type="text"
                      placeholder="Enter Your Form Name"
                      onChange={onInputChange}
                      value={values?.from_name}
                    />
                  </div>
                  <div className="input_fields_labels_adduser ">
                    <label>Form Email *</label>
                    <input
                      name="from_email"
                      type="email"
                      placeholder="Enter Your Form Email"
                      onChange={onInputChange}
                      value={values?.from_email}
                    />
                  </div>
                </div>
              </div>

              <div className="form_div_add_user mt-3">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels_adduser  ">
                    <label>Email Category *</label>
                    <input
                      name="email_category"
                      type="text"
                      placeholder="Email Key"
                      onChange={onInputChange}
                      value={values?.email_category}
                    />
                  </div>
                  <div className="input_fields_labels_adduser ">
                    <label>Email Subject *</label>
                    <input
                      name="email_subject"
                      type="text"
                      placeholder="Email Subject"
                      onChange={onInputChange}
                      value={values?.email_subject}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form_div_add_user mb-4">
              <div className="input_fields_labels w-100 mb-5">
                <label>Email Content *</label>
                <ReactQuill
                  name="email_content"
                  style={{ height: "200px" }}
                  modules={modules}
                  theme="snow"
                  value={contant?.email_content}
                  onChange={(value) =>
                    setContant({ ...contant, email_content: value })
                  }
                  placeholder="The content starts here..."
                />
              </div>
            </div>
            <div className="form_div_add_user">
              <div className="input_fields_labels">
                <label>Status *</label>
                <select
                  placeholder="discount type"
                  name="SType"
                  onChange={onInputChange}
                  value={values?.SType}
                >
                  <option value="Active">Active</option>
                  <option value="In-Active">In-Active</option>
                </select>
                {/* <label htmlFor="formFileLg">Profile Image*</label>
                  <input
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                  /> */}
              </div>
            </div>

            <button className="save_btn mt-3" type="submit">
              save
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default EmailTemplateUpdate;
