import React, { useState } from "react";
import "./css/login.css";
import { useNavigate } from "react-router-dom";
import { onAdminLogin } from "../redux/homeAction";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});

  const onInputChangeHandler = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(onAdminLogin(values, navigate));
  };

  return (
    <div className="login-box-wrapper">
      <div className="login-box">
        <div className="logo_div">
          <img src="/assets/logo.svg" alt="" />
        </div>
        <h2>Welcome to Bioveda</h2>
        <form onSubmit={handleLogin}>
          <div className="user-box">
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={onInputChangeHandler}
              required
            />
            <label>Email</label>
          </div>
          <div className="user-box">
            <input
              type="password"
              name="password"
              value={values.password}
              onChange={onInputChangeHandler}
              required
            />
            <label>Password</label>
          </div>
          <a href="#" onClick={handleLogin}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </a>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
