import React from "react";
import Header from "../components/header/Header";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getFeedback, updatefeedback } from "../redux/homeAction";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { useMemo } from "react";
import { Pagination } from "react-bootstrap";

const Feedback = () => {
  const [modalShow, setModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeItems, setPageSizeItems] = useState(10);
  const [userId, setUserId] = useState();
  const [role, setRole] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const getFeedbackDetails = useSelector(
    (state) => state.home.getFeedbackDetails
  );

  const feedbackUpdated = useSelector((state) => state.home.feedbackUpdated);
  // const feedbackUpdated = useSelector((state) => state.home.feedbackUpdated);

  const currentTableData = useMemo(() => {
    if (getFeedbackDetails) {
      const firstPageIndex = (currentPage - 1) * pageSizeItems;
      const lastPageIndex = firstPageIndex + pageSizeItems;
      return getFeedbackDetails?.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, getFeedbackDetails]);

  const handleSetPageLimit = (e) => {
    const val = e.target.value;
    setPageSizeItems(parseInt(val));
  };

  // const filteredData = currentTableData?.filter((feedback) => {
  //   const product_name = feedback?.product_id?.product_name?.toLowerCase();

  //   return product_name?.includes(searchTerm?.toLowerCase());
  // });

  const handleStatusChange = (data) => {
    const status = data?.status;
    const id = data?._id;
    dispatch(updatefeedback(id, { approved: status }, navigate));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFeedback());
  }, [feedbackUpdated]);

  return (
    <>
      <Header />

      <div className="main-panel master-pages">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
              <div className="col-lg-12">
                <div className="row tabelhed d-flex justify-content-between">
                  <div className="col-lg-2 col-md-2 col-sm-2 d-flex">
                    {/* <a
                      className="ad-btn btn text-center"
                      href="/add-coupons"
                      style={{
                        backgroundColor: "#000",
                        color: "#fff",
                        textSize: "14px",
                      }}
                    >
                      {" "}
                      Add
                    </a> */}
                  </div>

                  <div className="col-lg-10 col-md-10">
                    <div className="right-item d-flex justify-content-end">
                      <form action="" method="" className="d-flex">
                        <input
                          style={{ width: "101px;" }}
                          type="text"
                          name="keyword"
                          id="keyword"
                          className="form-control w-30"
                          placeholder="🔎 search ... "
                          // value={searchTerm}
                          // onChange={handleSearch}
                        />

                        {/* <button
                          style={{ width: "50px;" }}
                          className="w-10 btn-sm btn search-btn keyword-btn"
                          type="submit"
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button> */}

                        <button
                          className="w-40px btn-sm btn search-btn keyword-btn"
                          onClick={refreshPage}
                        >
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </button>

                        {/* <a
                        href="https://homemady.eoxyslive.com/admin/users"
                        className="btn-sm reload-btn"
                      >
                        
                      </a> */}
                      </form>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header ">
                    <div className="row">
                      <div className="col-xl-6 col-md-6 mt-auto ">
                        <h5 className="tabel_title"> Feedback List</h5>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <div className="row float-end">
                          <div className="col-xl-12 d-flex float-end justify-content-end">
                            <div className="items paginatee">
                              <select
                                className="form-select m-0 items"
                                name="items"
                                id="items"
                                aria-label="Default select example"
                                onChange={handleSetPageLimit}
                                value={pageSizeItems}
                              >
                                <option value="10" selected="">
                                  10
                                </option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table">
                      <table
                        id="example"
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr className="tabel_titles">
                            <th>S No.</th>
                            <th>Ratings</th>
                            <th>Message </th>
                            <th className="text-center">Approve </th>
                            <th>Role </th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentTableData?.map((item, key) => (
                            <tr className="tabel_titles" key={key}>
                              <td>{key + 1}</td>
                              <td>{item?.rating || "--"}</td>
                              <td>{item?.message || "--"}</td>
                              <td className="text-center d-flex justify-content-around align-items-center">
                                {item?.approved === true ? (
                                  <div
                                    className="success_btnnn "
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleStatusChange({
                                        status: false,
                                        _id: item?._id,
                                      })
                                    }
                                  >
                                    Approved
                                  </div>
                                ) : (
                                  (
                                    <div
                                      className="fail_btnnn"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleStatusChange({
                                          status: true,
                                          _id: item?._id,
                                        })
                                      }
                                    >
                                      Not-Approved
                                    </div>
                                  ) || "--"
                                )}
                              </td>
                              <td>{item?.role || "--"}</td>

                              <td className="text-left d-flex align-items-center justify-content-around">
                                <Link
                                  to={`/feedback/view-feedback/${item?._id}`}
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                    style={{ color: "blue" }}
                                  ></i>
                                </Link>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={getFeedbackDetails?.length}
                        pageSize={pageSizeItems}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Feedback;
