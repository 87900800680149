import { useDispatch } from "react-redux";
import Header from "../components/header/Header";
import "../pages/css/dashboard.css";
import { getDashboardDetails } from "../redux/homeAction";

const Dashboard = () => {
  const dispatch = useDispatch()


  dispatch(getDashboardDetails())


  return (
    <>
      <Header />

      <div className="dashboard_main">
        <div className="top_dashboard_wrapper">
          <div className="Total_div_box">
            <div className="image_div">
              <img src="/assets/totl_shooping.svg" alt="" />
            </div>
            <div className="right_credits">
              <span className="right_credits_heading">Total Orders</span>
              <h5>1024</h5>
            </div>
          </div>
          <div className="Total_div_box1">
            <div className="image_div1">
              <img src="/assets/totl_shooping.svg" alt="" />
            </div>
            <div className="right_credits">
              <span className="right_credits_heading">Total Customers</span>
              <h5>64</h5>
            </div>
          </div>
          <div className="Total_div_box2">
            <div className="image_div2">
              <img src="/assets/totl_shooping.svg" alt="" />
            </div>
            <div className="right_credits">
              <span className="right_credits_heading">Total Stores</span>
              <h5>64</h5>
            </div>
          </div>
          <div className="Total_div_box3">
            <div className="image_div3">
              <img src="/assets/totl_shooping.svg" alt="" />
            </div>
            <div className="right_credits">
              <span className="right_credits_heading">Orders Completed</span>
              <h5>64</h5>
            </div>
          </div>
        </div>

        <div className="middele_div_wrapper">
          <div className="left_graph_div">
            <h4>Orders</h4>
          </div>

          <div className="right_box_div">
            <div className="order_placed">
              <div className="image_div2">
                <i
                  className="fa fa-check-circle "
                  id="circle_check"
                  style={{ width: "30px", height: "30px" }}
                ></i>
              </div>
              <div className="right_credits">
                <span className="right_credits_heading">Order Placed</span>
                <h5>1024</h5>
              </div>
            </div>
            <div className="order_placed">
              <div className="image_div4">A+</div>
              <div className="right_credits">
                <span className="right_credits_heading">Orders Accept</span>
                <h5>1024</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="botton_div_wrapper">
          <div className="left_bottom_wrapper">
            <h5>Recent Order's</h5>
            <hr />
            <table className="order_tabel">
              <tr>
                <th className="o_n">Order Number</th>
                <th className="o_n">Order Date</th>
              </tr>
              <tr>
                <td className="order_numb_details">1093</td>
                <td className="order_numb_details">2023-11-02</td>
              </tr>
              <tr>
                <td className="order_numb_details">1093</td>
                <td className="order_numb_details">2023-11-02</td>
              </tr>
              <tr>
                <td className="order_numb_details">1093</td>
                <td className="order_numb_details">2023-11-02</td>
              </tr>
              <tr>
                <td className="order_numb_details">1093</td>
                <td className="order_numb_details">2023-11-02</td>
              </tr>
            </table>
          </div>
          <div className="left_bottom_wrapper">
            <h5>Recent Store's</h5>
            <hr />
            <table className="order_tabel">
              <tr>
                <th className="o_n">Customer Phone No.</th>
                <th className="o_n"> Date</th>
              </tr>
              <tr>
                <td className="order_numb_details">1093</td>
                <td className="order_numb_details">2023-11-02</td>
              </tr>
              <tr>
                <td className="order_numb_details">1093</td>
                <td className="order_numb_details">2023-11-02</td>
              </tr>
              <tr>
                <td className="order_numb_details">1093</td>
                <td className="order_numb_details">2023-11-02</td>
              </tr>
              <tr>
                <td className="order_numb_details">1093</td>
                <td className="order_numb_details">2023-11-02</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
