import React, { useEffect } from "react";
import Header from "../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { addSettings, getSettingsdetails } from "../redux/homeAction";
import { ToastContainer } from "react-toastify";

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const getSettingsDeta = useSelector((state) => state.home.getSettingsDeta);

  useEffect(() => {
    if (getSettingsDeta) {
      setValues(getSettingsDeta);
    }
  }, [getSettingsDeta]);

  useEffect(() => {
    dispatch(getSettingsdetails());
  }, []);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    if (
      e.target.name === "logo_first" ||
      e.target.name === "logo_second" ||
      e.target.name === "banner_image" ||
      e.target.name === "banner_video"
    ) {
      setValues({ ...values, [e.target.name]: e.target.files[0] });
    }
  };

  const handleSetSettings = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // selectedImages.forEach((image) => {
    //   formData.append("files", image);
    // });
    formData.append("logo_first", values?.logo_first);
    formData.append("logo_second", values?.logo_second);
    formData.append("banner_image", values?.banner_image);
    formData.append("banner_video", values?.banner_video);
    formData.append("admin_commission_type", values?.admin_commission_type);
    formData.append("admin_commission", values?.admin_commission);
    formData.append("admin_email", values?.admin_email);
    formData.append("admin_referal_amount", values?.admin_referal_amount);
    formData.append("promotion_charge", values?.promotion_charge);
    formData.append("packing_charge", values?.packing_charge);
    formData.append("service_charge_minimum", values?.service_charge_minimum);
    formData.append("service_charge_maximum", values?.service_charge_maximum);
    formData.append("dilivery_charge_2km", values?.dilivery_charge_2km);
    formData.append("dilivery_charge_4km", values?.dilivery_charge_4km);
    formData.append("dilivery_charge_per_km", values?.dilivery_charge_per_km);
    formData.append("min_order_amount", values?.min_order_amount);
    formData.append("tax_free_tip", isChecked);
    formData.append("mobile_number", values?.mobile_number);
    formData.append("landline_number", values?.landline_number);
    formData.append("whatsapp_number", values?.whatsapp_number);
    formData.append("support_email", values?.support_email);
    formData.append("instagram_url", values?.instagram_url);
    formData.append("linkdin_url", values?.linkdin_url);
    formData.append("youtube_url", values?.youtube_url);
    formData.append("facebook_url", values?.facebook_url);

    dispatch(addSettings(formData));
  };
  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handleSetSettings}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Site Settings</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels_adduser w-50 flex-row align-items-center gap-5">
                    <div>
                      <label htmlFor="formFileLg">
                        Logo 1{" "}
                        <span className="text-danger">
                          (Only jpeg, png, jpg files allowed){" "}
                        </span>{" "}
                      </label>
                      <input
                        name="logo_first"
                        className="form-control form-control"
                        id="formFileLg"
                        type="file"
                        onChange={onInputChange}
                      />
                    </div>
                    <div>
                      <img src={getSettingsDeta?.getSettingsDeta} alt="logo1" />
                    </div>
                  </div>
                  <div className="input_fields_labels_adduser w-50 flex-row align-items-center gap-5">
                    <div>
                      <label htmlFor="formFileLg">
                        Logo 2{" "}
                        <span className="text-danger">
                          (Only jpeg, png, jpg files allowed){" "}
                        </span>{" "}
                      </label>
                      <input
                        name="logo_second"
                        className="form-control form-control"
                        id="formFileLg"
                        type="file"
                        onChange={onInputChange}
                      />
                    </div>

                    <div>
                      <img src={getSettingsDeta?.logo_second} alt="logo2" />
                    </div>
                  </div>
                </div>
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels_adduser w-50 flex-row align-items-center gap-5">
                    <div>
                      <label htmlFor="formFileLg">
                        Banner Image{" "}
                        <span className="text-danger">
                          (Only jpeg, png, jpg files allowed){" "}
                        </span>{" "}
                      </label>
                      <input
                        name="banner_image"
                        className="form-control form-control"
                        id="formFileLg"
                        type="file"
                        onChange={onInputChange}
                      />
                    </div>
                    <div>
                      <img src={getSettingsDeta?.getSettingsDeta} alt="logo1" />
                    </div>
                  </div>
                  <div className="input_fields_labels_adduser w-50 flex-row align-items-center gap-5">
                    <div>
                      <label htmlFor="formFileLg">
                        Banner Video{" "}
                        <span className="text-danger">
                          (Only video files allowed){" "}
                        </span>{" "}
                      </label>
                      <input
                        name="banner_video"
                        className="form-control form-control"
                        id="formFileLg"
                        type="file"
                        onChange={onInputChange}
                      />
                    </div>

                    <div>
                      <img src={getSettingsDeta?.logo_second} alt="logo2" />
                    </div>
                  </div>
                </div>
                <div className="input_fields_wrapper">
                  <div className="input_fields_labels">
                    <label>Admin Commission Type*</label>
                    <select
                      placeholder="Admin Commission Type"
                      name="admin_commission_type"
                      onChange={onInputChange}
                      value={values?.admin_commission_type}
                      required
                    >
                      <option>select role</option>
                      <option value="Fixed">Fixed</option>
                      <option value="Percentage">Percentage</option>
                    </select>
                  </div>
                  <div className="input_fields_labels">
                    <label>Admin Commission*</label>
                    <input
                      name="admin_commission"
                      type="number"
                      placeholder="Admin Commission"
                      onChange={onInputChange}
                      value={values?.admin_commission}
                      required
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper">
                  <div className="input_fields_labels">
                    <label>Admin Email*</label>
                    <input
                      name="admin_email"
                      type="email"
                      placeholder="admin@admin.com"
                      onChange={onInputChange}
                      value={values?.admin_email}
                      required
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">Admin Referal Amount*</label>
                    <input
                      className="form-control form-control"
                      id="formFileLg"
                      type="number"
                      name="admin_referal_amount"
                      placeholder="1"
                      onChange={onInputChange}
                      value={values?.admin_referal_amount}
                      required
                    />
                  </div>
                </div>

                <div className="input_fields_wrapper">
                  <div className="input_fields_labels">
                    <label>Promotion Charge*</label>
                    <input
                      name="promotion_charge"
                      type="number"
                      placeholder="Promotion Charge"
                      onChange={onInputChange}
                      value={values?.promotion_charge}
                      required
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>Packing Charge*</label>
                    <input
                      type="packing_charge"
                      placeholder="Packing Charge"
                      name="packing_charge"
                      onChange={onInputChange}
                      value={values?.packing_charge}
                      required
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper">
                  <div className="input_fields_labels">
                    <label>Service charge Minimum*</label>
                    <input
                      name="service_charge_minimum"
                      type="number"
                      onChange={onInputChange}
                      value={values?.service_charge_minimum}
                      placeholder="Service charge Minimum"
                      required
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>Service charge Maximum*</label>
                    <input
                      name="service_charge_maximum"
                      type="number"
                      onChange={onInputChange}
                      value={values?.service_charge_maximum}
                      placeholder="Service charge Maximum"
                      required
                    />
                  </div>
                </div>
              </div>
              {/* <h4 className="form_basicInfo">Address</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label>Delivery charge for 2km *</label>
                    <input
                      name="dilivery_charge_2km"
                      type="number"
                      placeholder="Delivery charge for 2km"
                      onChange={onInputChange}
                      value={values?.dilivery_charge_2km}
                      required
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>Delivery charge after 2km (per km charge) *</label>
                    <input
                      name="dilivery_charge_per_km"
                      type="number"
                      placeholder="99.99"
                      onChange={onInputChange}
                      value={values?.dilivery_charge_per_km}
                      required
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label>Delivery charge for 4km *</label>
                    <input
                      name="dilivery_charge_4km"
                      type="number"
                      placeholder="Delivery charge for 2km"
                      onChange={onInputChange}
                      value={values?.dilivery_charge_4km}
                      required
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label>Min Order Amount*</label>
                    <input
                      name="min_order_amount"
                      type="text"
                      placeholder="10"
                      onChange={onInputChange}
                      value={values?.min_order_amount}
                      required
                    />
                  </div>
                  <div className="input_fields_labels flex-row align-items-center gap-2">
                    {/* <label>Tax Free Tip *</label>
                  <input
                    name="State"
                    type="checkbox"
                    placeholder="State"
                    onChange={onInputChange}
                    value={values?.state}
                  /> */}
                    <label>Tax Free Tip *</label>
                    <div className="form-check d-flex align-items-center">
                      <input
                        className="form-check-input"
                        name="tax_free_tip"
                        type="checkbox"
                        value={values?.setIsChecked}
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        id="flexCheckChecked"
                      />
                    </div>
                  </div>
                </div>
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label>Mobile number*</label>
                    <input
                      name="mobile_number"
                      type="number"
                      placeholder="dilivery charge 2km"
                      onChange={onInputChange}
                      value={values?.mobile_number}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>Landline Number*</label>
                    <input
                      name="landline_number"
                      type="number"
                      placeholder="Landline Number"
                      onChange={onInputChange}
                      value={values?.landline_number}
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label>whatsapp number*</label>
                    <input
                      name="whatsapp_number"
                      type="number"
                      placeholder="whatsapp number"
                      onChange={onInputChange}
                      value={values?.whatsapp_number}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>support email*</label>
                    <input
                      name="support_email"
                      type="email"
                      placeholder="support email"
                      onChange={onInputChange}
                      value={values?.support_email}
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label>Instagram URL</label>
                    <input
                      name="instagram_url"
                      type="text"
                      onChange={onInputChange}
                      value={values?.instagram_url}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>Linkedin URL</label>
                    <input
                      name="linkdin_url"
                      type="text"
                      onChange={onInputChange}
                      value={values?.linkdin_url}
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label>Youtube URL</label>
                    <input
                      name="youtube_url"
                      type="text"
                      onChange={onInputChange}
                      value={values?.youtube_url}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>Facebook URL</label>
                    <input
                      name="facebook_url"
                      type="text"
                      onChange={onInputChange}
                      value={values?.facebook_url}
                    />
                  </div>
                </div>
              </div>
              <button className="add_userBtn" type="submit">
                Add
              </button>
            </div>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default Setting;
