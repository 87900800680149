import {
  SET_BTN_LOADING,
  SET_ALL_MY_EVENTS,
  SET_COUPONS_PRICEING,
  SET_GET_USER_DATA,
  SET_LOGOUT,
  SET_GET_LOGGED_DATA,
  SET_GET_ADMIN_DATA,
  SET_GET_USERS_LIST,
  SET_CREATE_USER,
  SET_GET_SINGEL_USER_DETAILS,
  SET_UPDATE_USER,
  GET_HEALTHEXPERT_DETAILS,
  USER_DELETE,
  GET_SHOPBY_CATEGORY_DATA,
  SET_CREATE_CATEGORY,
  GET_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  GET_SINGEL_PRODUCT_CATEGORY_DETAILS,
  GET_BLOG_DATA,
  ADD_BLOG_DATA,
  DELETE_BLOG_DATA,
  GET_CUSTUMER_DETAILS,
  SET_UPDATE_BOLG,
  GET_FEEDBACK,
  GET_COUPON,
  CREATE_COUPONS,
  UPDATE_COUPONS,
  VIEW_COUPONS,
  DELETE_COUPONS,
  VIEW_BOLG,
  GET_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE,
  VIEW_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  ADD_DIET_PLAN,
  GET_DIET_PLAN,
  VIEW_DIET_PLAN,
  DELETE_DIET_PLAN,
  GET_SETTINGS_DATA,
  ADD_SETTINGS_DATA,
  UPDATE_DIET_PLAN,
  GET_PRODUCTS,
  CREATE_PRODUCTS,
  VIEW_PRODUCTS,
  DELETE_PRODUCTS,
  CREATE_SHOPBY_CATEGORY_DATA,
  ADD_FEEDBACK,
  VIEW_FEEDBACK,
  GET_ORDER_LIST,
  VIEW_SHOPBY_CATEGORY_DATA,
  SET_DELETE_SHOPBY_CATEGORY_DATA,
  SET_UPDATE_SHOPBY_CATEGORY_DATA,
  SET_VIEW_ORDER_DETAILS,
  SET_DELETE_ORDER_DETAILS,
  UPDATE_PRODUCTS,
  SET_GET_TEGS,
  SET_CREATE_TEGS,
  SET_UPDATE_TEGS,
  SET_DELETE_TEGS,
  SET_GET_ATTRIBUTE,
  SET_ADD_ATTRIBUTE,
  SET_UPDATE_ATTRIBUTE,
  SET_DELETE_ATTRIBUTE,
  SET_GET_ATTRIBUTE_VALUE,
  SET_ADD_ATTRIBUTE_VALUE,
  SET_DELETE_ATTRIBUTE_VALUE,
  SET_UPDATE_ATTRIBUTE_VALUE,
  SET_BESURETOFIT_TEGS,
  SET_UPDATE_ORDER_DETAILS,
  SET_ADD_PAGE,
  GET_ADD_PAGE,
  SET_UPDATE_PAGE,
  SET_DELETE_PAGE,
  ADD_ABOUT_DATA,
  SET_UPDATE_FEEDBACK,
  VIEW_USER_CATEGORY,
  GET_USER_CATEGORY,
  UPDATE_USER_CATEGORY,
  SINGLE_USER_CATEGORY,
  DELETE_USER_CATEGORY,
  SET_GET_SUBCATEGORY,
  SET_ADD_SUBCATEGORY,
  ADD_USER_CATEGORY,
  SET_UPDATE_SUBCATEGORY,
  SET_VIEW_SUBCATEGORY,
  SET_DELETE_SUBCATEGORY,
} from "./types";

const initialState = {};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BTN_LOADING:
      return {
        ...state,
        btnLoading: action.payload,
      };
    case SET_GET_ADMIN_DATA:
      return {
        ...state,
        getAdminLoggedDAta: action.payload,
      };
    case SET_GET_USERS_LIST:
      return {
        ...state,
        getAllusersListData: action.payload,
      };
    case SET_CREATE_USER:
      return {
        ...state,
        UserCreated: action.payload,
      };
    case SET_UPDATE_USER:
      return {
        ...state,
        UserUpdated: action.payload,
      };
    case SET_UPDATE_FEEDBACK:
      return {
        ...state,
        feedbackUpdated: action.payload,
      };
    case USER_DELETE:
      return {
        ...state,
        deleteUser: action.payload,
      };
    case DELETE_PRODUCT_CATEGORY:
      return {
        ...state,
        deleteProductCategoryDetails: action.payload,
      };
    case GET_SINGEL_PRODUCT_CATEGORY_DETAILS:
      return {
        ...state,
        getsingeleProductCategorydataList: action.payload,
      };
    case GET_BLOG_DATA:
      return {
        ...state,
        getBlogDataDetails: action.payload,
      };
    case VIEW_USER_CATEGORY:
      return {
        ...state,
        getUserCategoryDetails: action.payload,
      };
    case GET_USER_CATEGORY:
      return {
        ...state,
        getAllUserCategoryDetailsdata: action.payload,
      };
    case SET_UPDATE_SUBCATEGORY:
      return {
        ...state,
        UpdateUserCategoryDetailsdata: action.payload,
      };
    case ADD_USER_CATEGORY:
      return {
        ...state,
        addAllUserCategorsdata: action.payload,
      };
    case UPDATE_USER_CATEGORY:
      return {
        ...state,
        updateUserCategoryDetails: action.payload,
      };
    case SINGLE_USER_CATEGORY:
      return {
        ...state,
        singleUserCategoryDetails: action.payload,
      };
    case SET_VIEW_SUBCATEGORY:
      return {
        ...state,
        ViewUserCategoryDetailsdata: action.payload,
      };
    case SET_DELETE_SUBCATEGORY:
      return {
        ...state,
        deleteSubCategory: action.payload,
      };
    case DELETE_USER_CATEGORY:
      return {
        ...state,
        DeleteUserCategoryDetails: action.payload,
      };
    case ADD_BLOG_DATA:
      return {
        ...state,
        addBlogsData: action.payload,
      };
    case DELETE_BLOG_DATA:
      return {
        ...state,
        deletBlogData: action.payload,
      };
    case GET_CUSTUMER_DETAILS:
      return {
        ...state,
        getCustumers: action.payload,
      };
    case SET_UPDATE_BOLG:
      return {
        ...state,
        updatedBlogData: action.payload,
      };
    case ADD_ABOUT_DATA:
      return {
        ...state,
        addaboutUS: action.payload,
      };
    case GET_FEEDBACK:
      return {
        ...state,
        getFeedbackDetails: action.payload,
      };
    case GET_COUPON:
      return {
        ...state,
        getCouponDetails: action.payload,
      };
    case CREATE_COUPONS:
      return {
        ...state,
        createCoupons: action.payload,
      };
    case UPDATE_COUPONS:
      return {
        ...state,
        updatecouponsDetails: action.payload,
      };
    case VIEW_COUPONS:
      return {
        ...state,
        viewCouponsDetails: action.payload,
      };
    case DELETE_COUPONS:
      return {
        ...state,
        deleteCouponsDetails: action.payload,
      };
    case VIEW_BOLG:
      return {
        ...state,
        viewBlogDetails: action.payload,
      };
    case GET_EMAIL_TEMPLATE:
      return {
        ...state,
        getEmailTemplateDetails: action.payload,
      };
    case CREATE_EMAIL_TEMPLATE:
      return {
        ...state,
        createEmailTemplate: action.payload,
      };
    case UPDATE_EMAIL_TEMPLATE:
      return {
        ...state,
        updateEmailTemplatedata: action.payload,
      };
    case VIEW_EMAIL_TEMPLATE:
      return {
        ...state,
        viewEmailTemplateSingeldetails: action.payload,
      };
    case DELETE_EMAIL_TEMPLATE:
      return {
        ...state,
        deleteEmailTemplateSingeldetails: action.payload,
      };
    case ADD_DIET_PLAN:
      return {
        ...state,
        createDietPlans: action.payload,
      };
    case GET_DIET_PLAN:
      return {
        ...state,
        getDietPlansdetailslist: action.payload,
      };
    case VIEW_DIET_PLAN:
      return {
        ...state,
        viewDietSingelDetails: action.payload,
      };
    case DELETE_DIET_PLAN:
      return {
        ...state,
        delteDietPlanSingelDetails: action.payload,
      };
    case GET_SETTINGS_DATA:
      return {
        ...state,
        getSettingsDeta: action.payload,
      };
    case ADD_SETTINGS_DATA:
      return {
        ...state,
        CreateSettingsData: action.payload,
      };
    case UPDATE_DIET_PLAN:
      return {
        ...state,
        UpdateSettingsData: action.payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        getProductsData: action.payload,
      };
    case CREATE_PRODUCTS:
      return {
        ...state,
        createProductsData: action.payload,
      };
    case VIEW_PRODUCTS:
      return {
        ...state,
        getProductsDetails: action.payload,
      };
    case DELETE_PRODUCTS:
      return {
        ...state,
        getDeleteProductsDetails: action.payload,
      };
    case CREATE_SHOPBY_CATEGORY_DATA:
      return {
        ...state,
        Createshopbycategory: action.payload,
      };
    case VIEW_FEEDBACK:
      return {
        ...state,
        getviewfeedback: action.payload,
      };
    case GET_ORDER_LIST:
      return {
        ...state,
        getorderList: action.payload,
      };
    case SET_UPDATE_ORDER_DETAILS:
      return {
        ...state,
        updateorders: action.payload,
      };
    case SET_ADD_PAGE:
      return {
        ...state,
        createpages: action.payload,
      };
    case GET_ADD_PAGE:
      return {
        ...state,
        getpagesList: action.payload,
      };
    case SET_UPDATE_PAGE:
      return {
        ...state,
        updatepagesList: action.payload,
      };
    case SET_DELETE_PAGE:
      return {
        ...state,
        deletepagesList: action.payload,
      };
    case GET_SHOPBY_CATEGORY_DATA:
      return {
        ...state,
        getShopByCategoryData: action.payload,
      };
    case VIEW_SHOPBY_CATEGORY_DATA:
      return {
        ...state,
        ViewShopByCategorydetails: action.payload,
      };
    case SET_DELETE_SHOPBY_CATEGORY_DATA:
      return {
        ...state,
        DeleteShopByCategorydetails: action.payload,
      };
    case SET_UPDATE_SHOPBY_CATEGORY_DATA:
      return {
        ...state,
        updateShopByCategorydetails: action.payload,
      };
    case SET_CREATE_CATEGORY:
      return {
        ...state,
        createCategory: action.payload,
      };
    case GET_PRODUCT_CATEGORY:
      return {
        ...state,
        getProductCategorydata: action.payload,
      };
    case SET_GET_LOGGED_DATA:
      return {
        ...state,
        getLoggedInData: action.payload,
      };
    case SET_VIEW_ORDER_DETAILS:
      return {
        ...state,
        ViewOrderDetailsdata: action.payload,
      };
    case SET_DELETE_ORDER_DETAILS:
      return {
        ...state,
        deleteOrderDetailsdata: action.payload,
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        updateProductsdetails: action.payload,
      };
    case SET_GET_TEGS:
      return {
        ...state,
        getegsDetails: action.payload,
      };
    case SET_CREATE_TEGS:
      return {
        ...state,
        createtegsDetails: action.payload,
      };
    case SET_UPDATE_TEGS:
      return {
        ...state,
        updatetagsdetails: action.payload,
      };
    case SET_DELETE_TEGS:
      return {
        ...state,
        deletetagsdatalist: action.payload,
      };
    case SET_GET_ATTRIBUTE:
      return {
        ...state,
        getattributelist: action.payload,
      };
    case SET_ADD_ATTRIBUTE:
      return {
        ...state,
        addattributelist: action.payload,
      };
    case SET_UPDATE_ATTRIBUTE:
      return {
        ...state,
        updateattributelist: action.payload,
      };
    case SET_DELETE_ATTRIBUTE:
      return {
        ...state,
        deleteattributelist: action.payload,
      };
    case SET_GET_ATTRIBUTE_VALUE:
      return {
        ...state,
        getattributeValueList: action.payload,
      };
    case SET_GET_SUBCATEGORY:
      return {
        ...state,
        getSubCategorydata: action.payload,
      };
    case SET_ADD_ATTRIBUTE_VALUE:
      return {
        ...state,
        addattributeValueList: action.payload,
      };
    case SET_ADD_SUBCATEGORY:
      return {
        ...state,
        addSubCategoryData: action.payload,
      };
    case SET_BESURETOFIT_TEGS:
      return {
        ...state,
        addfit: action.payload,
      };
    case GET_HEALTHEXPERT_DETAILS:
      return {
        ...state,
        getHealthExpertDetailsList: action.payload,
      };
    case SET_DELETE_ATTRIBUTE_VALUE:
      return {
        ...state,
        deleteattributeValueList: action.payload,
      };
    case SET_UPDATE_ATTRIBUTE_VALUE:
      return {
        ...state,
        updateattributeValueList: action.payload,
      };
    case SET_GET_LOGGED_DATA:
      return {
        ...state,
        getLoggedInData: action.payload,
      };
    case SET_GET_SINGEL_USER_DETAILS:
      return {
        ...state,
        getuserInfos: action.payload,
      };

    case SET_GET_USER_DATA:
      return {
        ...state,
        logedInData: action.payload,
      };
    case SET_ALL_MY_EVENTS:
      return {
        ...state,
        getMyEventsList: action.payload,
      };
    case SET_COUPONS_PRICEING:
      return {
        ...state,
        couponsPricingData: action.payload,
      };
    case SET_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default homeReducer;
