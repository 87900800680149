import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import Header from "../../components/header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAttribtuteListData,
  GetAttribtuteValueListData,
  GettagsList,
  UpdateProduct,
  getProductCategory,
  viewProductsbyid,
} from "../../redux/homeAction";
import "./addproducts.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddUpdateProducts = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [description, setDescription] = useState();
  const [selectedAttriValOption, setSelectedAttriValOption] = useState([]);
  const getProductsDetails = useSelector(
    (state) => state.home.getProductsDetails
  );
  const getProductCategorydata = useSelector(
    (state) => state.home.getProductCategorydata?.data
  );
  const getegsDetails = useSelector(
    (state) => state?.home?.getegsDetails?.data
  );
  const getattributelist = useSelector(
    (state) => state?.home?.getattributelist?.data
  );
  const getattributeValueList = useSelector(
    (state) => state?.home?.getattributeValueList?.data
  );

  useEffect(() => {
    if (id) {
      dispatch(viewProductsbyid(id));
    }
  }, [id]);

  useEffect(() => {
    if (getProductsDetails) {
      setValues({
        ...getProductsDetails,
        category_id: getProductsDetails?.category_id?._id,
        selectKey: Date.now(),
        tags: getProductsDetails?.tags?.map((item, key) => ({
          value: item?._id,
          label: item?.title,
        })),
      });
      setDescription(getProductsDetails?.product_description);
    }
  }, [getProductsDetails]);

  useEffect(() => {
    dispatch(GettagsList());
    dispatch(getProductCategory());
    dispatch(GetAttribtuteListData());
    dispatch(GetAttribtuteValueListData());
  }, []);

  const optionList = getegsDetails?.map((item, key) => ({
    value: item?._id,
    label: item?.title,
  }));

  const optionAttriList = getattributelist?.map((item, key) => ({
    value: item?._id,
    label: item?.title,
  }));
  const filteredAttbuteArray = optionAttriList?.filter((item) =>
    getProductsDetails?.attribute?.includes(item?.label)
  );
  const defaultOtionAttriList = filteredAttbuteArray?.map((item, key) => ({
    value: item?.value,
    label: item?.label,
  }));

  const optionAttriValList = getattributeValueList?.map((item, key) => ({
    value: item?._id,
    label: item?.atributte_value,
    name: item?.atributte_id?.title,
  }));

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleQuillChange = (value) => {
    setDescription(value);
  };

  console.log(description);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let variantList = values?.object;

    const list = [...variantList];
    list[index][name] = value;

    if (name === "variation_image") {
      list[index][name] = e.target.files[0];
      list[index]["variantImageShow"] = URL.createObjectURL(e.target.files[0]);
    }
    setValues({ ...values, object: list });
  };

  const deleteVariantItem = (index) => {
    let variantList = values?.object;
    let updatedVariantList = [...variantList];
    updatedVariantList.splice(index, 1);
    setValues({ ...values, object: updatedVariantList });
  };
  const handleQuestionChange = (e, index) => {
    const { name, value } = e.target;
    let qa = values?.qa;

    const list = [...qa];
    list[index][name] = value;
    setValues({ ...values, qa: list });
  };

  const handleAddQuestion = () => {
    setValues({ ...values, qa: [...values?.qa, { que: "", ans: "" }] });
  };

  const handleRemoveQuestion = (e, index) => {
    let qa = values?.qa;
    let updatedqa = [...qa];
    updatedqa.splice(index, 1);
    setValues({ ...values, qa: updatedqa });
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const handleSelectChange = (selectedOptions) => {
    setValues({ ...values, tags: selectedOptions });
  };

  const selectAttributeList = (value) => {
    const combinedArray = value?.map((attr) => attr.label);
    setValues({ ...values, attribute: combinedArray });
  };

  const handleSelectAttribute = (value) => {
    const uniqueValuesSet = new Set(
      selectedAttriValOption.map((ele) => ele.value)
    );

    selectedAttriValOption.forEach((item) => {
      if (!value.find((v) => v.value === item.value.value)) {
        if (value.find((v) => v.name === item.value.name)) {
          uniqueValuesSet.delete(item.value);
        }
      }
    });

    value.forEach((item) => {
      if (!uniqueValuesSet.has(item.value)) {
        uniqueValuesSet.add(item);
      }
    });

    const uniqueValuesArray = Array.from(uniqueValuesSet).map((value) => ({
      value,
    }));

    setSelectedAttriValOption(uniqueValuesArray);
  };

  const handleRemoveImage = (index) => {
    const galleryImage = values?.gallery_image;

    let updatedGalleryImage = [...galleryImage];
    updatedGalleryImage.splice(index, 1);
    setValues({ ...values, gallery_image: updatedGalleryImage });
  };

  const handleMakeVariantion = () => {
    const totals = selectedAttriValOption.map((_) => _.value);

    const groupedItems = totals.reduce((acc, item) => {
      acc[item.name] = acc[item.name] || [];
      acc[item.name].push(item);
      return acc;
    }, {});

    // Function to generate variations
    function generateVariations(data) {
      const result = [];
      const keys = Object.keys(data);

      // Recursive function to generate variations
      function generate(index, current) {
        if (index === keys.length) {
          result.push({ ...current });
          return;
        }

        const key = keys[index];
        const array = data[key];

        for (const option of array) {
          current[key] = option;
          generate(index + 1, { ...current });
        }
      }

      generate(0, {});
      return result;
    }

    // Generate variations
    const variations = generateVariations(groupedItems);
    const removeDuplicates = (variantList, attributes) => {
      const uniqueVariants = variantList.reduce((acc, variant) => {
        const isDuplicate = acc.some((v) =>
          attributes.every((attr) => v[attr].value === variant[attr].value)
        );

        if (!isDuplicate) {
          acc.push(variant);
        }

        return acc;
      }, []);

      return uniqueVariants;
    };

    const uniqueVariantList = removeDuplicates(variations, values?.attribute);

    setValues({ ...values, object: uniqueVariantList });
  };

  const handleImageChange = (e) => {
    const { name } = e.target;

    if (name === "product_image") {
      setValues({
        ...values,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
        productImageShow: e.target.files[0],
      });
    } else if (name === "gallery_image") {
      const fileArray = Array.from(e.target.files);
      const urls = fileArray.map((file) => URL.createObjectURL(file));

      setValues({
        ...values,
        [e.target.name]: [...values.gallery_image, ...urls],
        galleryImageShow: fileArray,
      });
    }
  };

  const handleAddProduct = () => {
    const formData = new FormData();

    formData.append("product_name", values?.product_name);
    formData.append("sku_code", values?.sku_code);
    formData.append("product_description", description);
    formData.append("product_type", values?.product_type);
    formData.append("category_id", values?.category_id);
    formData.append(
      "tags",
      JSON.stringify(values?.tags?.map((item) => item?.value))
    );
    formData.append("qa", JSON.stringify(values?.qa));

    if (values?.product_type === "Simple") {
      formData.append("selling_price", values?.selling_price);
      formData.append("market_price", values?.market_price);
    } else if (values?.product_type === "Variable") {
      formData?.append(
        "attribute",
        JSON.stringify(values?.attribute?.map((item) => item))
      );
      formData.append(
        "object",
        JSON.stringify(
          values?.object?.map((data) => ({
            color: data?.color,
            varient_maximum_quantity: data?.varient_maximum_quantity,
            varient_minimum_quantity: data?.varient_minimum_quantity,
            varient_price: data?.varient_price,
            weight: data?.weight,
          }))
        )
      );
    }

    values?.object?.map((_) => {
      if (_?.variantImageShow) {
        formData.append("variation_image", _.variation_image);
      }
    });

    if (values?.galleryImageShow) {
      values?.galleryImageShow?.forEach((image, index) => {
        formData.append("gallery_image", image);
      });
    }
    if (values?.productImageShow) {
      formData.append("product_image", values?.productImageShow);
    }

    dispatch(UpdateProduct(id, formData, navigate));
  };

  return (
    <>
      <Header />
      <div className="adduser_main_wrapper">
        <div>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Create Product</div>
            <div className="">
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels_adduser">
                    <label>Product Name *</label>
                    <input
                      name="product_name"
                      type="text"
                      placeholder="Product Name"
                      value={values?.product_name}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>SKU *</label>
                    <input
                      name="sku_code"
                      type="text"
                      placeholder="SKU"
                      value={values?.sku_code}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="input_fields_wrapper">
                  <div className="input_fields_labels">
                    <label>Category *</label>
                    <select
                      name="category_id"
                      value={values?.category_id}
                      onChange={onInputChange}
                    >
                      <option value="" hidden>
                        Select Category
                      </option>
                      {getProductCategorydata?.map((item, key) => (
                        <option key={key} value={item?._id}>
                          {item?.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="input_fields_wrapper">
                  <div className="input_fields_labels_adduser  ">
                    <label>Tags*</label>

                    <Select
                      key={values?.selectKey}
                      closeMenuOnSelect={false}
                      isMulti
                      onChange={handleSelectChange}
                      options={optionList}
                      defaultValue={values?.tags}
                    />
                  </div>
                  <div className="input_fields_labels">
                    <label>Product type*</label>
                    <select
                      name="product_type"
                      value={values?.product_type}
                      onChange={onInputChange}
                    >
                      <option value="" hidden>
                        product type
                      </option>
                      <option value="Simple">Simple</option>
                      <option value="Variable">Variable</option>
                    </select>
                  </div>
                </div>

                {values?.product_type === "Simple" ? (
                  <div className="input_fields_wrapper">
                    <div className="input_fields_labels">
                      <label>Market Price *</label>
                      <input
                        name="market_price"
                        type="number"
                        placeholder="market Price "
                        value={values?.market_price}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="input_fields_labels">
                      <label>Selling Price *</label>
                      <input
                        name="selling_price"
                        type="number"
                        placeholder="reguler Price "
                        value={values?.selling_price}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                ) : values?.product_type === "Variable" ? (
                  <>
                    <div className="input_fields_wrapper flex-wrap">
                      <div className="input_fields_labels_adduser  ">
                        <label>Attribute *</label>
                        <Select
                          key={values?.selectKey}
                          closeMenuOnSelect={false}
                          isMulti
                          options={optionAttriList}
                          defaultValue={defaultOtionAttriList}
                          onChange={(value) => selectAttributeList(value)}
                        />
                      </div>
                    </div>
                    {values?.attribute?.map((item, index) => {
                      const filteredOptions = optionAttriValList?.filter(
                        (items) => {
                          const itemLabel = items.name
                            ? items.name.toLowerCase()
                            : "";
                          const innerItemLabel = item ? item.toLowerCase() : "";
                          return itemLabel === innerItemLabel;
                        }
                      );

                      return (
                        <div className="input_fields_wrapper  align-items-center">
                          <div className="input_fields_labels_adduser  ">
                            <label htmlFor={`attributeValue${index}`}>
                              {item}
                            </label>
                            <Select
                              name="attributeValue"
                              id={`attributeValue${index}`}
                              closeMenuOnSelect={true}
                              onChange={(value) => handleSelectAttribute(value)}
                              isMulti
                              options={filteredOptions}
                            />
                          </div>
                        </div>
                      );
                    })}

                    {values?.attribute?.length === 0 ? null : (
                      <button
                        className="btn btn-success btn-sm make-variations-btn"
                        onClick={handleMakeVariantion}
                      >
                        Make Variations
                      </button>
                    )}
                    {values?.object?.length === 0 ? null : (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Price</th>
                            <th>Min Quantity</th>
                            <th>Max Quantity</th>
                            <th>Image</th>
                            {/* {/ <th>Status</th> /} */}
                            <th>action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values?.object?.map((data, i) => (
                            <tr id="variant_row_1" key={i}>
                              {values?.attribute?.map((field, index) => (
                                <div
                                  className="varaintList_wrapper"
                                  key={index}
                                >
                                  <div className="variant_item" key={index}>
                                    {field}:
                                  </div>
                                  <div key={index}>
                                    {data[field] && data[field].label}
                                  </div>
                                </div>
                              ))}

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="varient_price"
                                  id={`varient_price${i}`}
                                  value={data?.varient_price}
                                  onChange={(e) => handleInputChange(e, i)}
                                  onWheel={(e) => e.target.blur()}
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  id={`varient_minimum_quantity${i}`}
                                  name="varient_minimum_quantity"
                                  value={data?.varient_minimum_quantity}
                                  onChange={(e) => handleInputChange(e, i)}
                                  onWheel={(e) => e.target.blur()}
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  id={`varient_maximum_quantity${i}`}
                                  name="varient_maximum_quantity"
                                  value={data?.varient_maximum_quantity}
                                  onChange={(e) => handleInputChange(e, i)}
                                  onWheel={(e) => e.target.blur()}
                                  required
                                />
                              </td>
                              <td className="w-25">
                                <img
                                  src={
                                    data?.variantImageShow
                                      ? data?.variantImageShow
                                      : data?.image
                                  }
                                  alt="variant-image"
                                  className="w-25 img-fluid"
                                />
                                <input
                                  accept="image/*"
                                  type="file"
                                  className="form-control"
                                  required
                                  name="variation_image"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-icon delete_variant"
                                  onClick={() => deleteVariantItem(i)}
                                >
                                  <MdDelete />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                ) : null}

                <div className="mt-5 input_fields_wrapper_description">
                  <div className="input_fields_labels_desription">
                    <label>Description*</label>
                    <ReactQuill
                      style={{ height: "200px" }}
                      modules={modules}
                      theme="snow"
                      value={description}
                      onChange={handleQuillChange}
                      placeholder="The content starts here..."
                    />
                  </div>
                </div>
              </div>
              <div className="form_div_add_user mt-5">
                <div className="input_fields_wrapper_adduser">
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>
                    <input
                      accept="image/*"
                      name="product_image"
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      onChange={handleImageChange}
                    />
                    <div className="w-25 p-3">
                      <img
                        src={values?.product_image}
                        alt="product-image"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="input_fields_labels">
                    <label htmlFor="formFileLg">
                      Gallery Image{" "}
                      <span className="text-danger">
                        (Only jpeg, png, jpg files allowed){" "}
                      </span>{" "}
                    </label>
                    <input
                      accept="image/*"
                      name="gallery_image"
                      className="form-control form-control"
                      id="formFileLg"
                      type="file"
                      multiple
                      onChange={handleImageChange}
                    />
                    <div>
                      {values?.gallery_image?.map((img, index) => (
                        <React.Fragment key={index}>
                          <img
                            src={img}
                            className="w-25 p-2 img-fluid"
                            alt="product-image"
                          />
                          <i
                            className="fa fa-times me-3 cursor-pointer"
                            onClick={() => handleRemoveImage(index)}
                          ></i>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="bunddle_section">
                <h4 className="form_basicInfo">Question & Answer </h4>
              </div>{" "}
              <div
                className=" bunddle_section btn_wrapper d-flex justify-content-end"
                style={{ padding: "0px 15px" }}
              >
                <button className="increase" onClick={handleAddQuestion}>
                  Add Questions
                </button>
              </div>
              {values?.qa?.map((item, index) => (
                <div className="form_div_add_user">
                  <div className="input_fields_wrapper_adduser">
                    <div
                      className="input_fields_labels "
                      style={{ width: "90%" }}
                    >
                      <label>Question*</label>
                      <input
                        name="que"
                        type="text"
                        placeholder="question"
                        value={item?.que}
                        onChange={(e) => handleQuestionChange(e, index)}
                      />
                    </div>

                    <div
                      className="input_fields_labels "
                      style={{ width: "90%" }}
                    >
                      <label>Answer*</label>
                      <textarea
                        name="ans"
                        placeholder="answer"
                        value={item?.ans}
                        onChange={(e) => handleQuestionChange(e, index)}
                      />
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="fail_btnnn"
                        style={{ width: "30px" }}
                        onClick={(e) => handleRemoveQuestion(e, index)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <button className="add_userBtn" onClick={handleAddProduct}>
                Add Product
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddUpdateProducts;
