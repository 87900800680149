import React, { useRef, useState } from "react";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  UpdateAttributeValuesList,
  GetAttribtuteListData,
  GetAttribtuteValueListData,
} from "../../redux/homeAction";
import { useEffect } from "react";

const AttriValueUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const getattributelist = useSelector(
    (state) => state.home.getattributelist?.data
  );
  const getattributeValueList = useSelector(
    (state) => state.home.getattributeValueList?.data
  );

  useEffect(() => {
    if (getattributeValueList) {
      for (let i = 0; i < getattributeValueList?.length; i++) {
        if (getattributeValueList[i]._id === id) {
          setValues({
            ...getattributeValueList[i],
            atributte_id: getattributeValueList[i].atributte_id._id,
          });
        }
      }
    }
  }, [getattributeValueList]);

  const onInputChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handelAddAttributeVAlue = async (e) => {
    e.preventDefault();
    const data = {
      atributte_id: values.atributte_id,
      atributte_value: values.atributte_value,
    };

    dispatch(UpdateAttributeValuesList(id, data, navigate));
  };

  useEffect(() => {
    dispatch(GetAttribtuteListData());
  }, []);

  useEffect(() => {
    dispatch(GetAttribtuteValueListData());
  }, [id]);

  return (
    <>
      <Header />

      <div className="adduser_main_wrapper">
        <form action="" onSubmit={handelAddAttributeVAlue}>
          <div className="form_wrapper_adduser">
            <div className="header_from_user">Update Attribute Values</div>
            <div className="">
              {/* <h4 className="form_basicInfo">Basic Information</h4> */}
              <div className="form_div_add_user">
                <div className="input_fields_wrapper_adduser  ">
                  <div className="input_fields_labels">
                    <label>Attribute *</label>
                    <select
                      placeholder="Attribute"
                      name="atributte_id"
                      onChange={onInputChange}
                      value={values?.atributte_id}
                      required
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {getattributelist?.map((item, key) => (
                        <option key={key} value={item?._id}>
                          {item?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input_fields_labels_adduser ">
                    <label> Attribute value *</label>
                    <input
                      name="atributte_value"
                      type="text"
                      placeholder="Attribute value"
                      onChange={onInputChange}
                      value={values?.atributte_value}
                      required
                    />
                  </div>
                </div>
              </div>
              <button className="save_btn" type="submit">
                save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AttriValueUpdate;
