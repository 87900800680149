import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import ReactQuill from "react-quill";
import { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateAddShopbycategory,
  GetProductsList,
  addAboutUs,
  getpageList,
  updatePageData,
} from "../../redux/homeAction";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const AboutUs = (id) => {
  const [values, setValues] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeItems, setPageSizeItems] = useState(10);
  const [userId, setUserId] = useState();
  const [role, setRole] = useState();
  const navigate = useNavigate();

  const [banner, setBanner] = useState({});
  const [aboutImg, setAboutImg] = useState({});
  const [Pagedescription, setPageDescription] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fieldName = e.target.name;
    const fileName = `${fieldName}.${file.name.split(".").pop()}`;

    setBanner((prevImages) => ({
      ...prevImages,
      [fieldName]: {
        file,
        name: fileName,
      },
    }));
  };

  const handleAboutImgImageChange = (e) => {
    const file = e.target.files[0];
    const fieldName = e.target.name;
    const fileName = `${fieldName}.${file.name.split(".").pop()}`;

    setAboutImg((prevImages) => ({
      ...prevImages,
      [fieldName]: {
        file,
        name: fileName,
      },
    }));
  };

  const dispatch = useDispatch();
  const handleOpenDeletePopup = (id) => {
    setUserId(id);
    setModalShow(true);
  };

  const [inputListTopics, setInputListTopics] = useState([
    {
      topics1: "",
    },
  ]);

  const [inputList2, setInputList2] = useState([
    {
      topics2: "",
    },
  ]);

  const [inputList3, setInputList3] = useState([
    {
      topics3: "",
    },
  ]);

  //  first heading topics
  const deletetopicItem = (idx) => {
    let updatedInpuTopicsList = [...inputListTopics];
    updatedInpuTopicsList.splice(idx, 1);
    setInputListTopics(updatedInpuTopicsList);
  };

  const addItemTopics = () => {
    setInputListTopics([
      ...inputListTopics,
      {
        topics1: "",
      },
    ]);
  };
  const handleonInputChange = (e, idx) => {
    const { name, value } = e.target;
    const list = [...inputListTopics];
    if (name == "amount") {
      if (value.length <= 5) {
        list[idx][name] = value ? Number(value) : "";
        if (Number(value) < 3) {
          // setErrors({
          //   ...errors,
          //   [e.target.name]: "Minmum bid amount is $3",
          // });
        } else if (idx == inputListTopics.length - 1) {
          // setErrors({
          //   ...errors,
          //   [e.target.name]: false,
          // });
        }
      }
    } else {
      list[idx][name] = value;
    }
    setInputListTopics(list);
  };

  //  second heading topics
  const deleteItemList2 = (index) => {
    let updatedInputList = [...inputList2];
    updatedInputList.splice(index, 1);
    setInputList2(updatedInputList);
  };

  const addItemList2 = () => {
    setInputList2([
      ...inputList2,
      {
        topics2: "",
      },
    ]);
  };

  const handleonInputChangeTwo = (e, idx) => {
    const { name, value } = e.target;
    const list = [...inputList2];
    if (name == "amount") {
      if (value.length <= 5) {
        list[idx][name] = value ? Number(value) : "";
        if (Number(value) < 3) {
          // setErrors({
          //   ...errors,
          //   [e.target.name]: "Minmum bid amount is $3",
          // });
        } else if (idx == inputList2.length - 1) {
          // setErrors({
          //   ...errors,
          //   [e.target.name]: false,
          // });
        }
      }
    } else {
      list[idx][name] = value;
    }
    setInputList2(list);
  };

  //  second heading topics
  const deleteItemList3 = (index) => {
    let updatedInputList = [...inputList3];
    updatedInputList.splice(index, 1);
    setInputList3(updatedInputList);
  };

  const addItemList3 = () => {
    setInputList3([
      ...inputList3,
      {
        topics3: "",
      },
    ]);
  };

  const handleonInputChangeThree = (e, idx) => {
    const { name, value } = e.target;
    const list = [...inputList3];
    if (name == "amount") {
      if (value.length <= 5) {
        list[idx][name] = value ? Number(value) : "";
        if (Number(value) < 3) {
          // setErrors({
          //   ...errors,
          //   [e.target.name]: "Minmum bid amount is $3",
          // });
        } else if (idx == inputList3.length - 1) {
          // setErrors({
          //   ...errors,
          //   [e.target.name]: false,
          // });
        }
      }
    } else {
      list[idx][name] = value;
    }
    setInputList3(list);
  };

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const arr = [aboutImg, banner];

  const topicData = [
    {
      heading: values.heading,
      topics: inputListTopics?.map((item) => item.topics1),
    },

    {
      heading: values.second_heading,
      topics: inputList2?.map((items) => items.topics2),
    },

    {
      heading: values.third_heading,
      topics: inputList3?.map((itemss) => itemss.topics3),
    },
  ];

  const handleAddAboutSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(banner).forEach((fieldName) => {
      const file = banner[fieldName].file;
      const fileName = banner[fieldName].name;

      formData.append("files", file, fileName);
    });

    const data = {
      // banner: values.banner_image,
      // about_image: values?.about_image,
      // files: arr,
      // description: description,
      // text: values.text,
      our_data: [
        {
          heading: values.heading,
          topics: inputListTopics?.map((item) => item.topics1),
        },

        {
          heading: values.second_heading,
          topics: inputList2?.map((items) => items.topics2),
        },

        {
          heading: values.third_heading,
          topics: inputList3?.map((itemss) => itemss.topics3),
        },
      ],
    };
    formData.append("description", Pagedescription?.description);
    formData.append("text", values?.text);
    formData.append("our_data", topicData);
    data.our_data.forEach((item, index) => {
      formData.append(`our_data[${index}][heading]`, item.heading);

      // Assuming item.topics is an array
      item.topics.forEach((topic, topicIndex) => {
        formData.append(`our_data[${index}][topics][${topicIndex}]`, topic);
      });
    });

    dispatch(updatePageData(id?.userId, formData, navigate));
  };

  const getpagesList = useSelector((state) => state.home.getpagesList);

  useEffect(() => {
    if (getpagesList) {
      for (let i = 0; i < getpagesList.length; i++) {
        if (getpagesList[i]._id === id?.userId) {
          setValues(getpagesList[i]);
          setPageDescription(getpagesList[i]);
        }
      }
    }
    setUserId(id);
  }, [getpagesList]);

  useEffect(() => {
    dispatch(getpageList());
  }, []);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: ["red", "blue"] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ align: ["center"] }],
      ["clean"],
    ],
  };

  return (
    <>
      {/* <Header /> */}

      <div className="adduser_main_wrapper">
        <div className="form_wrapper_adduser">
          <div className="header_from_user">About Us</div>
          <div className="">
            {/* <h4 className="form_basicInfo">Basic Information</h4> */}

            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser">
                <div className="input_fields_labels">
                  <label htmlFor="formFileLg">
                    banner{" "}
                    <span className="text-danger">
                      (Only jpeg, png, jpg files allowed){" "}
                    </span>{" "}
                  </label>
                  <input
                    accept="image/*"
                    name="banner"
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="input_fields_labels">
                  <label htmlFor="formFileLg">
                    about image{" "}
                    <span className="text-danger">
                      (Only jpeg, png, jpg files allowed){" "}
                    </span>{" "}
                  </label>
                  <input
                    name="image"
                    className="form-control form-control"
                    id="formFileLg"
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser  ">
                <div className="input_fields_labels_adduser ">
                  <label> text *</label>
                  <input
                    name="text"
                    type="text"
                    placeholder="text"
                    onChange={onInputChange}
                    value={values?.text}
                  />
                </div>
              </div>
            </div>

            <div className="form_div_add_user mb-4">
              <div className="input_fields_labels w-100 mb-5">
                <label>Description *</label>
                <ReactQuill
                  name="description"
                  style={{ height: "350px" }}
                  modules={modules}
                  theme="snow"
                  value={Pagedescription?.description}
                  onChange={(value) =>
                    setPageDescription({
                      ...Pagedescription,
                      description: value,
                    })
                  }
                  placeholder="The content starts here..."
                />
              </div>
            </div>

            <hr />

            <div className="bunddle_section">
              <h4 className="form_basicInfo">Goals & Mission</h4>
            </div>

            <div className="form_div_add_user">
              <div className="input_fields_wrapper_adduser w-100 ">
                <div className="d-flex w-100 justify-content-between">
                  <div className="w-100">
                    <div className="input_fields_labels_adduser  w-100">
                      <label>first heading *</label>

                      <input
                        name="heading"
                        type="text"
                        placeholder="heading"
                        onChange={onInputChange}
                        value={values?.heading}
                      />
                    </div>
                    {inputListTopics?.map((lst, idx) => (
                      <div className="d-flex align-items-center gap-5">
                        <div className="input_fields_labels_adduser ">
                          <label> topics *</label>
                          <input
                            name="topics1"
                            type="text"
                            id={`topics1${idx}`}
                            placeholder="topics1"
                            onChange={(e) => handleonInputChange(e, idx)}
                            value={values?.topics1}
                          />
                        </div>

                        <button
                          onClick={addItemTopics}
                          className="success_btnnn "
                          style={{ width: "30px" }}
                        >
                          +
                        </button>
                        <button
                          className="fail_btnnn"
                          style={{ width: "30px" }}
                          onClick={() => deletetopicItem(idx)}
                        >
                          -
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between mt-5">
                  <div className="w-100">
                    <div className="input_fields_labels_adduser  w-100">
                      <label>Second heading *</label>

                      <input
                        name="second_heading"
                        type="text"
                        placeholder="heading"
                        onChange={onInputChange}
                        value={values?.second_heading}
                      />
                    </div>
                    {inputList2?.map((lst, idx) => (
                      <div className="d-flex align-items-center gap-5">
                        <div className="input_fields_labels_adduser ">
                          <label> topics *</label>
                          <input
                            name="topics2"
                            id={`topics2${idx}`}
                            type="text"
                            placeholder="topics"
                            onChange={(e) => handleonInputChangeTwo(e, idx)}
                            value={values?.topics2}
                          />
                        </div>

                        <button
                          onClick={addItemList2}
                          className="success_btnnn "
                          style={{ width: "30px" }}
                        >
                          +
                        </button>
                        <button
                          className="fail_btnnn"
                          style={{ width: "30px" }}
                          onClick={() => deleteItemList2(idx)}
                        >
                          -
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between mt-5">
                  <div className="w-100">
                    <div className="input_fields_labels_adduser  w-100">
                      <label>Third heading *</label>

                      <input
                        name="third_heading"
                        type="text"
                        placeholder="heading"
                        onChange={onInputChange}
                        value={values?.third_heading}
                      />
                    </div>
                    {inputList3?.map((lst, idx) => (
                      <div className="d-flex align-items-center gap-5">
                        <div className="input_fields_labels_adduser ">
                          <label> topics *</label>
                          <input
                            name="topics3"
                            id={`topics3${idx}`}
                            type="text"
                            placeholder="topics"
                            onChange={(e) => handleonInputChangeThree(e, idx)}
                            value={values?.topics3}
                          />
                        </div>

                        <button
                          onClick={addItemList3}
                          className="success_btnnn "
                          style={{ width: "30px" }}
                        >
                          +
                        </button>
                        <button
                          className="fail_btnnn"
                          style={{ width: "30px" }}
                          onClick={() => deleteItemList3(idx)}
                        >
                          -
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <button className="save_btn" onClick={handleAddAboutSubmit}>
              save
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default AboutUs;
