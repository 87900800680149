import React, { useEffect } from "react";
import Header from "../components/header/Header";
import "../pages/css/userinfo.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUser } from "../redux/homeAction";

const SingelUserGet = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.home.getuserInfos);

  useEffect(() => {
    if (id) {
      dispatch(getSingleUser(id));
    }
  }, [id]);

  return (
    <>
      <Header />

      <div className="userinfo_wrapper">
        <div className="container">
          <div className="userinfo_main_box">
            <div className="heading">User Details</div>

            <div className="userinfodiv">
              <h4>basic information</h4>

              <div className="infobox_wrapper">
                <div className="infobox">
                  <h3>Name</h3>
                  <h6>
                    {userInfo?.first_name} {userInfo?.last_name}
                  </h6>
                </div>
                <div className="infobox">
                  <h3>Phone</h3>
                  <h6>{userInfo?.phone}</h6>
                </div>
                <div className="infobox">
                  <h3>User Role</h3>
                  <h6>{userInfo?.role || "--"}</h6>
                </div>
                <div className="infobox">
                  <h3>Email</h3>
                  <h6>{userInfo?.email}</h6>
                </div>
                <div className="infobox">
                  <h3>Wallet Balance</h3>
                  <h6>---</h6>
                </div>
                <div className="infobox">
                  <h3>Earned Balance</h3>
                  <h6>---</h6>
                </div>
                <div className="infobox">
                  <h3>Location</h3>
                  <h6>{userInfo?.location || "--"}</h6>
                </div>
                <div className="infobox">
                  <h3>Latitude</h3>
                  <h6>{userInfo?.latitude || "--"}</h6>
                </div>
                <div className="infobox">
                  <h3>Longitude</h3>
                  <h6>{userInfo?.longitude || "--"}</h6>
                </div>
                <div className="infobox">
                  <h3>Status</h3>
                  <h6>---</h6>
                </div>
                <div className="infobox">
                  <h3>Referal Code</h3>
                  <h6>---</h6>
                </div>
              </div>

              <Link
                style={{ textDecoration: "none" }}
                className="text-decoration-none"
                to={"/users"}
              >
                <button>Back to list</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingelUserGet;
