import { NavLink } from "react-router-dom";
import { FaBars, FaHome, FaRegUserCircle } from "react-icons/fa";
import { MdMarkEmailUnread, MdOutlineFeedback } from "react-icons/md";
import { BiAnalyse, BiCalendarCheck, BiSolidCoupon } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
import { LiaPagerSolid } from "react-icons/lia";
import { BsCart4, BsCartCheck, BsShop } from "react-icons/bs";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/users",
    name: "User Management",
    icon: <FaRegUserCircle />,
    subRoutes: [
      {
        path: "/users",
        name: "All User ",
        // icon: <FaUser />,
      },
      {
        path: "/Customer",
        name: "Customer",
        // icon: <FaLock />,
      },
      {
        path: "/health-expert",
        name: "Health Expert",
        // icon: <FaMoneyBill />,
      },
      {
        path: "/category",
        name: "Category",
        // icon: <FaMoneyBill />,
      },
      {
        path: "/sub-category",
        name: "Sub Category",
        // icon: <FaMoneyBill />,
      },
    ],
  },
  {
    path: "/products",
    name: "Product",
    icon: <BsCart4 />,
    subRoutes: [
      { path: "/product", name: "Products" },
      { path: "/products/attributes", name: "Attributes" },
      { path: "/products/attribute-values", name: "Attribute Value" },
      { path: "/products/tags", name: "Tags" },
      { path: "/products/product-categories", name: "Product Categories" },
    ],
  },
  {
    path: "/shop",
    name: "Shop",
    icon: <BsShop />,
    subRoutes: [
      {
        path: "/shop-and-category",
        name: "Shop By Category",
        // icon: <BiAnalyse />,
      },
      // {
      //   path: "/fit",
      //   name: "Be sure to fit",
      //   // icon: <BiAnalyse />,
      // },
      // {
      //   path: "/our-latest-event",
      //   name: "Our Latest Events",
      //   // icon: <BiAnalyse />,
      // },
    ],
  },

  {
    path: "/email-template",
    name: "Email template",
    icon: <MdMarkEmailUnread />,
  },

  {
    path: "/coupon",
    name: "Coupon",
    icon: <BiSolidCoupon />,
  },
  {
    path: "/blog",
    name: "Blogs",
    icon: <BiAnalyse />,
  },

  {
    path: "/pages",
    name: "Pages",
    icon: <LiaPagerSolid />,
  },
  {
    path: "/feedback",
    name: "Feedback",
    icon: <MdOutlineFeedback />,
  },

  {
    path: "/order",
    name: "Order",
    icon: <BsCartCheck />,
  },
  {
    path: "/diet-plan",
    name: "Diet",
    icon: <BiCalendarCheck />,
  },
  {
    path: "/setting",
    name: "Settings",
    icon: <BiCog />,
    exact: true,
    subRoutes: [
      {
        path: "/site-setting",
        name: "Site Settings",
      },
    ],
  },
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,

      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,

      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "300px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  <img src="/assets/logo_header.svg" alt="" />
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          {/* <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div> */}
          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
